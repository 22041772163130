import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import SuccessImg from "../../assets/images/icons/Check.png";
import ErrorImg from "../../assets/images/icons/Error.png";
import { Box, Button, Divider, Typography, useTheme } from "@mui/material";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import "./KmdPaymentComplete.scss";
import { useDispatch, useSelector } from "react-redux";
import advService from "../../util/advService";
import {
    KMD_PAYMENT_RETRY_POST,
    KMD_POLICY_DOWNLOAD_GET,
    NEW_KMD_POLICY_DOWNLOAD_GET,
} from "../../util/newconfig";
import { AxiosResponse } from "axios";
import downloadBlobIphone from "../../util/function/downloadBlobIphone";
import alert from "../../util/alerts";
import service from "../../util/axiosConfig";
import { RootState } from "../../store";
import { HOME_EMPLOYEE } from "../../util/config";
import { setHomeDto } from "../../features/home/homeSlice";
import { JavaResponse } from "../../types";
import V2Container from "../../components/Layout/V2Container";
import Iconify from "../../components/iconify";

const iciciLogo =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/insurer_logos/icici.svg";

const careLogo = "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/insurer_logos/care.svg"

const KmdPaymentComplete: React.FC = () => {
    const { policyDto, homeDto } = useSelector(
        (state: RootState) => state.home
    );
    const searchParam = new URLSearchParams(window.location.search);
    const status = searchParam.get("status");
    const policyUuid =
        searchParam.get("policyUuid") || searchParam.get("policyuuid");
    const transactionId =
        searchParam.get("transactionId") || searchParam.get("transactionid");
    const policyType = searchParam.get("policyType");
    const policyNumber =
        searchParam.get("PolicyNumber") || searchParam.get("policyNumber");
    const uid = searchParam.get("uid");
    const navigate = useNavigate();
    const reducerDispatch = useDispatch();
    const theme = useTheme();

    useEffect(() => {
        document.body.style.backgroundColor = "white";
        return () => {
            document.body.style.backgroundColor = "#f6f6f6";
        };
    }, []);

    useEffect(() => {
        console.log("status", status);
        console.log("policyUuid", policyUuid);
        console.log("transactionId", transactionId);
    }, [status, policyUuid, transactionId]);

    useEffect(() => {
        let user = JSON.parse(sessionStorage.getItem("user") || "");

        if (user) {
            (async () => {
                const url = `${HOME_EMPLOYEE}${
                    user.actualUserType !== "EMPLOYEE"
                        ? sessionStorage.getItem("uuid")
                        : ""
                }`;
                await service.get(url).then((res) => {
                    if (res?.data?.success) {
                        reducerDispatch(
                            setHomeDto({
                                ...res.data.response,
                            })
                        );
                    }
                });
            })();
        }
    }, []);

    const retryPayment = (transactionId: string) => {
        let config = { ...KMD_PAYMENT_RETRY_POST };
        config.url = config.url.replace("{transactionId}", transactionId);
        debugger;
        advService(
            config,
            {},
            (
                res: AxiosResponse<
                    JavaResponse<{
                        link: string;
                        type: string;
                    }>
                >
            ) => {
                if (res.data.success) {
                    window.location.href = res.data.response.link;
                }
            }
        );
    };

    return (
        <V2Container
            onBack={() => {
                window.location.href = "/home";
            }}
            sx={{
                padding: {
                    xs: "0px",
                    sm: "24px",
                },
            }}
        >
            <Box>
                <Box
                    sx={{
                        maxWidth: "430px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "24px",
                    }}
                >
                    
                    <Iconify
                    icon={status === "true" ? "material-symbols:check-circle-rounded" : "material-symbols:cancel-rounded"}
                    sx={{
                        color: status === "true" ? "#4CAF50" : "#F44336",
                        width: "75px",
                        height: "75px",
                    }}
                    />
                    <Typography variant="h4">
                            {status === "true"
                                ? "Payment successful!"
                                : "Transaction failed!"}
                    </Typography>
                    <img
                        className="kmdPaymentComplete_iciciLogo mb8"
                        src={policyType?.toLowerCase() === "topup" ? careLogo :iciciLogo}
                        alt=""
                    />
                    <Typography variant="navbutton" sx={{ textAlign: "center", color: theme.palette.text.primary }}>
                        {status === "true"
                            ? policyType?.toUpperCase() === "GPA"
                                ? "You may download the certificate. Complete document will be issued in 3 working days."
                                : policyNumber
                                ? "Congratulations! You're now insured. Below is your Policy Number."
                                : "Policy is under process. Policy document will be sent to you within 48hrs on your registered email id."
                            : "Payment against product is failed. You can retry paying the amount."}
                    </Typography>
                    {status === "true" &&
                        policyType?.toUpperCase() !== "GPA" && (
                            <b>{policyNumber}</b>
                        )}
                    {!(policyType?.toUpperCase() === "PI" && !policyNumber) && (
                        <Button
                            size = "small"
                            color="primary"
                            sx={{
                                backgroundColor: theme.palette?.brand?.main,
                                color: "#fff",
                                "&:hover": {
                                    color: "#000",
                                },
                                minWidth: "200px",
                            }}
                            onClick={() => {
                                if (status === "true") {
                                    let policy = policyDto.d_home_policies.find(
                                        (policy: any) =>
                                            policy.uuid === policyUuid
                                    );
                                    if (policy?.selfPurchased) {
                                        service.getFile(
                                            NEW_KMD_POLICY_DOWNLOAD_GET.url
                                                .replace(
                                                    "{policyUuid}",
                                                    policyUuid || ""
                                                )
                                                .replace(
                                                    "{pid}",
                                                    policy?.pid || ""
                                                ),
                                            null,
                                            null,
                                            null,
                                            policyType?.toUpperCase() === "GPA"
                                                ? "Receipt.pdf"
                                                : "Policy.pdf"
                                        );
                                    } else {
                                        service.getFile(
                                            KMD_POLICY_DOWNLOAD_GET.url
                                                .replace(
                                                    "{policyUuid}",
                                                    policyUuid || ""
                                                )
                                                .replace(
                                                    "{policyType}",
                                                    policyType?.toUpperCase() ||
                                                        ""
                                                ),
                                            null,
                                            null,
                                            null,
                                            policyType?.toUpperCase() === "GPA"
                                                ? "Receipt.pdf"
                                                : "Policy.pdf"
                                        );
                                    }
                                } else {
                                    if (transactionId) {
                                        retryPayment(transactionId);
                                    } else {
                                        navigate(
                                            // @ts-ignore
                                            `/kmd/${policyType}/${homeDto?.kmd_associate_config_map?.[policyType]}?purchase=true`
                                        );
                                    }
                                }
                            }}
                        >
                            {status === "true" ? (
                                <div>
                                    {policyType?.toUpperCase() === "GPA"
                                        ? "Download Payment Receipt"
                                        : "Download policy"}
                                    &nbsp;
                                    <DownloadForOfflineIcon />
                                </div>
                            ) : (
                                "Retry "
                            )}
                        </Button>
                    )}

                    {policyType === "PI" &&
                        !policyNumber &&
                        status === "false" && (
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    backgroundColor: theme.palette?.brand?.main,
                                    color: "#fff",
                                    "&:hover": {
                                        color: "#000",
                                    },
                                    minWidth: "200px",
                                }}
                                onClick={() => {
                                    if (transactionId) {
                                        retryPayment(transactionId);
                                    } else {
                                        navigate(
                                            // @ts-ignore
                                            `/kmd/GPI/${homeDto?.kmd_associate_config_map?.[policyType]}`
                                        );
                                    }
                                }}
                                size="small"
                            >
                                Retry
                            </Button>
                        )}
                    <Button
                        color="primary"
                        onClick={() => {
                            navigate("/home");
                        }}
                        size="small"
                    >
                        Go to home
                    </Button>
                </Box>
            </Box>
        </V2Container>
    );
};

export default KmdPaymentComplete;
