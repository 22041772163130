import React, { useEffect } from "react";

type Props = {};

const CookiesComponent = (props: Props) => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://policy.cookiereports.com/e8d11e26_panel-en-gb.js";
        script.async = true;
        script.onload = () => {
            console.log("Script loaded successfully");
        };
        script.onerror = () => {
            console.error("Error loading script");
        };
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    return <></>;
};

export default CookiesComponent;
