import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import "./kmdInvoice.scss";
import { useFormikContext } from "formik";
import {
    Box,
    Button,
    Divider,
    IconButton,
    Stack,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Iconify from "../../../components/iconify";

const CARELOGO =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/insurer_logos/care.svg";
const ICICILOGO =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/insurer_logos/icici.svg";

interface InvoiceProps {
    className?: string;
    submitForm?: any;
    loader?: boolean;
    // insideForm?: boolean;
}

const policyTypeMap = {
    GMC: "Health Insurance",
    GPA: "Personal Accident Insurance",
    GPI: "Professional Indemnity Insurance",
    TOPUP: "Super Top Up",
}

const KmdInvoice: React.FC<InvoiceProps> = ({
    className,
    submitForm,
    loader,
    // insideForm = false,
}) => {
    const theme = useTheme();
    const {
        sumInsured,
        premium,
        plan,
        planDb,
        deductible,
        comment,
        policyType,
    } = useSelector((state: RootState) => state.kmd);

    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [collapsed, setCollapsed] = React.useState(true);

    // let submitForm: (() => Promise<void>) & (() => Promise<any>);
    // if (insideForm) {
    //     submitForm = useFormikContext().submitForm;
    // }

    return (
        <>
        {
            isMobile && !collapsed && (

        <Box
            sx={{
                height: "100vh",
                width: "100vw",
                position: "fixed",
                top: "0",
                left: "0",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 99,
            }}
            onClick={() => setCollapsed(!collapsed)}
        >
                
                </Box>
            )
        }
        <Box
            sx={{
                background: isMobile ? "white" : theme.palette.grey[200],
                borderRadius: isMobile ? "0" : "32px",
                padding: isMobile ? "20px" : "24px",
                height: (isMobile && collapsed) ? "86px" : "auto",
                width: "100%",
                position: isMobile ? "fixed" : "relative",
                bottom: "0",
                left: "0",
                boxShadow: isMobile ? "0 14px 42px 0 #14142b24" : "none",
                zIndex: 100,
            }}
        >
            
            {(!collapsed || !isMobile) && (
                <Stack gap={"24px"}>
                    <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Typography
                            variant="body3"
                            sx={{
                                color: theme.palette.grey[700],
                            }}
                        >
                            Insurance partner
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                            }}
                        >
                            <img
                                src={
                                    policyType === "TOPUP"
                                        ? CARELOGO
                                        : ICICILOGO
                                }
                                alt="logo"
                                height={
                                    policyType === "TOPUP" ? "40px" : "30px"
                                }
                            />
                        </Box>
                    </Stack>
                    <Stack>
                        <Typography variant="subtitle1">{
                            policyTypeMap[policyType || "GMC"]
                            }</Typography>
                    </Stack>
                    <Box>
                        <Divider
                            sx={{
                                border: "1px dashed rgb(217, 215, 224)",
                            }}
                        />
                    </Box>
                    <Stack gap={"16px"}>
                        {/* <h2 className="fontWeight_600">KMD</h2> */}
                        <Box
                            sx={{
                                backgroundColor: "#ffffff",
                                borderRadius: "12px",
                                padding: "16px",
                            }}
                        >
                            <Stack
                                direction={"row"}
                                justifyContent={"space-between"}
                            >
                                <Stack>
                                    <Typography variant="body3">
                                        Sum Insured
                                    </Typography>
                                    <Typography variant="overline">
                                        <span className="rupee">₹</span>{" "}
                                        {sumInsured?.toLocaleString("en-IN") ||
                                            planDb
                                                .find((p) => p.name === plan)
                                                ?.sumInsured.toLocaleString(
                                                    "en-IN"
                                                )}
                                    </Typography>
                                </Stack>
                                {(comment || deductible) && (
                                    <Stack>
                                        <Typography variant="body3">
                                            Deductible
                                        </Typography>
                                        <Typography
                                            variant="overline"
                                            sx={{
                                                maxWidth: "140px",
                                            }}
                                        >
                                            {comment || (
                                                <>
                                                    <span className="rupee">
                                                        ₹
                                                    </span>{" "}
                                                    {deductible?.toLocaleString(
                                                        "en-IN"
                                                    )}
                                                </>
                                            )}
                                        </Typography>
                                    </Stack>
                                )}
                            </Stack>
                        </Box>

                        <Box>
                            <Divider
                                sx={{
                                    border: "1px dashed rgb(217, 215, 224)",
                                }}
                            />
                        </Box>
                        <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                        >
                            <Typography variant="overline">
                                Net Premium
                            </Typography>
                            <Typography variant="body2">
                                <span className="rupee">₹</span>{" "}
                                {premium.toLocaleString("en-IN")}
                            </Typography>
                        </Stack>
                        <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                        >
                            <Typography variant="overline">
                                G.S.T. (18%)
                            </Typography>
                            <Typography variant="body2">
                                <span className="rupee">₹</span>{" "}
                                {Math.ceil(premium * 0.18).toLocaleString(
                                    "en-IN"
                                )}
                            </Typography>
                        </Stack>

                        <Box>
                            <Divider
                                sx={{
                                    border: "1px dashed rgb(217, 215, 224)",
                                }}
                            />
                        </Box>
                        <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                        >
                            <Typography variant="subtitle2">
                                Total Premium
                                <Typography variant="body3">
                                    {" "}
                                    (inc 18% G.S.T.)
                                </Typography>
                            </Typography>
                            <Typography variant="h6">
                                <span className="rupee">₹</span>{" "}
                                {Math.ceil(premium * 1.18).toLocaleString(
                                    "en-IN"
                                )}
                                &nbsp;
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            )}

            <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                {isMobile && (
                    <Box sx={{ width: "300px" }}>
                        <Stack direction={"row"} alignItems={"center"}>
                            <IconButton
                                aria-label="Example"
                                sx={{
                                    padding: "0px",
                                    border: `solid 1px ${theme.palette.grey[300]}`,
                                    marginRight: "8px",
                                }}
                                onClick={() => setCollapsed(!collapsed)}
                            >
                                <Iconify icon="iconamoon:arrow-up-2" />
                            </IconButton>
                            {
                                collapsed && (

                            <Typography variant="h6">
                                <span className="rupee">₹</span>{" "}
                                {Math.ceil(premium * 1.18).toLocaleString(
                                    "en-IN"
                                )}
                                &nbsp;
                            </Typography>
                                )
                            }
                        </Stack>
                    </Box>
                )}

                <Button
                    sx={{
                        backgroundColor: theme.palette.brand.main,
                        color: theme.palette.common.white,
                        width: "100%",
                        marginTop: isMobile ? "0" : "24px",
                    }}
                    className="KmdInvoice_nextButton buttonPrimary ml15 mr15 mb15 pt4"
                    onClick={() => {
                        console.log("yo");
                        setCollapsed(true);
                        submitForm();
                    }}
                    disabled={!submitForm || loader}
                >
                    {submitForm ? (
                        "Next"
                    ) : (
                        <>
                            Pay <span className="rupee">₹</span>
                            {Math.ceil(premium * 1.18).toLocaleString("en-IN")}
                        </>
                    )}
                </Button>
            </Stack>
        </Box>
        </>
    );
};

export default KmdInvoice;
