import { Link, useLocation } from "react-router-dom";
import { Badge, Box, Typography, useTheme } from "@mui/material";
import iconCreator from "../iconCreator";
import Iconify from "../../iconify/iconify";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
import { getVariantData } from "../../../util/functions";

const pathnames = ["/", "/home", "/plans", "/benefits", "/claims"];

const MobileNavbar: React.FC = () => {
    const location = useLocation();
    const healthCareBenefits = useSelector(
        (state: RootState) => state.home?.homeDtoV2?.healthCare
    );
    const { homeDtoV2 } = useSelector((state: RootState) => state.home);
    const theme = useTheme();

    if (!pathnames.includes(location.pathname)) return null;
    return (
        <Box
            sx={{
                width: "100%",
                height: "68px",
                display: "none",
                "@media (max-width: 1165px)": {
                    display: "flex",
                },
                position: "fixed",
                left: 0,
                bottom: 0,
                paddingBlock: "12px",
                backgroundColor: "white",
                zIndex: "100",
                border: "1px solid #E6E6E6",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    maxWidth: { xs: "100%", md: "770px" },
                    height: "68px",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "24px 20px",
                }}
            >
                <Box
                    position={"relative"}
                    alignSelf={"center"}
                    minHeight={"44px"}
                    padding={"0 8px"}
                >
                    <Link
                        to="/home"
                        className={
                            location.pathname === "/" ||
                            location.pathname === "/home"
                                ? "emp-nav-link active-link mobile-navbar"
                                : "emp-nav-link mobile-navbar"
                        }
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "4px",
                        }}
                    >
                        <Iconify
                            icon={
                                location.pathname === "/" ||
                                location.pathname === "/home"
                                    ? "solar:home-smile-bold"
                                    : "solar:home-smile-outline"
                            }
                            height={20}
                            width={20}
                            sx={{
                                ml: "5px",
                                mr: "5px",
                                color:
                                    location.pathname === "/" ||
                                    location.pathname === "/home"
                                        ? "$primary"
                                        : "#0C1230",
                            }}
                        />
                        <Typography
                            variant={
                                location.pathname === "/home"
                                    ? "label"
                                    : "body3"
                            }
                            color={
                                location.pathname === "/home"
                                    ? "$primary"
                                    : "#0C1230"
                            }
                        >
                            Home
                        </Typography>
                    </Link>
                </Box>
                <Box
                    position={"relative"}
                    alignSelf={"center"}
                    minHeight={"44px"}
                    padding={"0 8px"}
                >
                    <Link
                        to="/plans"
                        className={
                            location.pathname === "/plans"
                                ? "emp-nav-link active-link mobile-navbar"
                                : "emp-nav-link mobile-navbar"
                        }
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "4px",
                        }}
                    >
                        <Iconify
                            icon={`solar:shield-check-${
                                location.pathname === "/plans"
                                    ? "bold"
                                    : "outline"
                            }`}
                            height={20}
                            width={20}
                            sx={{
                                ml: "5px",
                                mr: "5px",
                                color:
                                    location.pathname === "/plans"
                                        ? "$filter_primary"
                                        : "#0C1230",
                            }}
                        />
                        <Typography
                            variant={
                                location.pathname === "/plans"
                                    ? "label"
                                    : "body3"
                            }
                            color={
                                location.pathname === "/plans"
                                    ? "$primary"
                                    : "#0C1230"
                            }
                        >
                            Policies
                        </Typography>
                    </Link>
                </Box>
                {getVariantData({
                    kmd: <></>,
                    imt: (
                        <Badge
                            badgeContent={
                                homeDtoV2?.d_wallet_balance
                                    ? "₹" +
                                      homeDtoV2?.d_wallet_balance?.toLocaleString(
                                          "en-IN"
                                      )
                                    : null
                            }
                            sx={{
                                "& .MuiBadge-badge": {
                                    color: "white",
                                    backgroundColor: theme.palette.brand.main,
                                    padding: "0 8px",
                                    height: "16px",
                                    top: "-4px",
                                },
                            }}
                        >
                            <Box
                                position={"relative"}
                                alignSelf={"center"}
                                minHeight={"44px"}
                                padding={"0 8px"}
                            >
                                <Link
                                    to="/benefits"
                                    className={
                                        location.pathname === "/benefits"
                                            ? "emp-nav-link active-link mobile-navbar"
                                            : "emp-nav-link mobile-navbar"
                                    }
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        gap: "4px",
                                    }}
                                >
                                    <Iconify
                                        icon={`solar:heart-pulse-${
                                            location.pathname === "/benefits"
                                                ? "bold"
                                                : "outline"
                                        }`}
                                        height={20}
                                        width={20}
                                        sx={{
                                            ml: "5px",
                                            mr: "5px",
                                            color:
                                                location.pathname ===
                                                "/benefits"
                                                    ? "$filter_primary"
                                                    : "#0C1230",
                                        }}
                                    />
                                    {}
                                    <Typography
                                        variant={
                                            location.pathname === "/benefits"
                                                ? "label"
                                                : "body3"
                                        }
                                        className="benefit_wallet"
                                        color={
                                            location.pathname === "/benefits"
                                                ? "$primary"
                                                : "#0C1230"
                                        }
                                    >
                                        Benefits
                                    </Typography>
                                </Link>
                            </Box>
                        </Badge>
                    ),
                })}

                <Box
                    position={"relative"}
                    alignSelf={"center"}
                    minHeight={"44px"}
                    padding={"0 8px"}
                >
                    <Link
                        to="/claims"
                        className={
                            location.pathname === "/claims"
                                ? "emp-nav-link active-link mobile-navbar"
                                : "emp-nav-link mobile-navbar"
                        }
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "4px",
                        }}
                    >
                        <Iconify
                            icon={`solar:document-${
                                location.pathname === "/claims"
                                    ? "bold"
                                    : "outline"
                            }`}
                            height={20}
                            width={20}
                            sx={{
                                ml: "5px",
                                mr: "5px",
                                color:
                                    location.pathname === "/claims"
                                        ? "$filter_primary"
                                        : "#0C1230",
                            }}
                        />
                        <Typography
                            variant={
                                location?.pathname === "/claims"
                                    ? "label"
                                    : "body3"
                            }
                            color={
                                location?.pathname === "/claims"
                                    ? "$primary"
                                    : "#0C1230"
                            }
                        >
                            Claims
                        </Typography>
                    </Link>
                </Box>
            </Box>
        </Box>
    );
};

export default MobileNavbar;
