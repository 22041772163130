import React from "react";
import {
    Box,
    Container,
    Grid,
    Link,
    Typography,
    Divider,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import CookiesComponent from "./cookiesCmponent";

interface RegulatoryInfo {
    text: string;
    licenses: string[];
    membership: string;
    officer: string;
}

interface FooterNewProps {
    officeInfo: {
        address: string[];
        phone: string;
    };
    linkColumns: { title: string; url: string }[][];
    copyright: { text: string };
    regulatoryInfo: RegulatoryInfo;
    contactInfo: {
        address: string;
        phone: string;
        website: string;
    };
    versions: string[];
}

const gallagherFooterConfig = {
    officeInfo: {
        address: [
            "Unit No. 34, Tower III, 4th Floor",
            "Wing B, Kohinoor City Mall, Kohinoor City",
            "Kiroi Road, Kurla West, Mumbai – 400070",
        ],
        phone: "+91 22 4512 0574",
    },
    linkColumns: [
        [
            { title: "About Us", url: "https://www.ajg.com/in/about-us/" },
            {
                title: "Privacy Policy",
                url: "https://www.ajg.com/in/global-privacy-notice/",
            },
            {
                title: "Disclaimer",
                url: "https://www.bluebox.com/Disclaimer/GallagherIndia_Disclaimer.pdf",
            },
        ],
        [
            {
                title: "Code of Conduct",
                url: "https://www.ajg.com/in/-/media/files/gallagher/in/insurance-broker-code-of-conduct-for-india.pdf",
            },
            {
                title: "Management Team",
                url: "https://www.ajg.com/in/about-us/executive-team/",
            },
            { title: "IRDAI Website", url: "https://irdai.gov.in/" },
        ],
        [
            { title: "IBAI Website", url: "https://ibai.org/" },
            {
                title: "CSR Policy",
                url: "https://www.ajg.com/in/corporate-social-responsibility/",
            },
            {
                title: "CSR Committee",
                url: "https://www.ajg.com/in/corporate-social-responsibility/",
            },
        ],
        [
            {
                title: "Cookie Policy",
                url: "https://policy.cookiereports.com/e8d11e26-en-gb.html",
            },
        ],
    ],
    copyright: {
        text: "Copyright © 2025 Gallagher Insurance Brokers Private Limited",
    },
    versions: ["BlueBox v2.4.24"],
    regulatoryInfo: (
        <>
            Gallagher Insurance Brokers Private Limited (Formerly Gallagher
            Insurance Brokers Limited) is authorised and regulated by the
            Insurance Regulatory and Development Authority of India (IRDAI).
            Composite Broker | IRDAI License No.295 | IFSCA Reg. No.
            IFSCA/IIIO/021/2023-24 | Valid Until: 23/03/2026 | CIN:
            U72200MH2000PTC124096. Registered Business Address: Unit No. 34,
            Tower III, 4th Floor, Wing B, Kohinoor City Mall, Kohinoor City,
            Kirol Road, Kurla West, Mumbai - 400070, Tel: +91 22 4512 0574|{" "}
            <Box
                sx={{
                    display: "inline",
                    textDecoration: "underline",
                    color: "#337ab7",
                    "&:hover": {
                        color: "#23527c",
                    },
                }}
            >
                <a href="https://www.ajg.com/in/">www.ajg.com/in/</a>
            </Box>
            <br />
            IBAI Membership No. 35295 | Principal Officer: Ajit Singh Horra*
            (*Subject to regulatory approval) |
        </>
    ),
};

const GallagherFooter = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Box
            component="footer"
            sx={{
                paddingTop: 6,
                backgroundColor: "#dcdddc",
                paddingBottom: {
                    xs: "100px",
                    md: 6,
                },
            }}
        >
            <Container maxWidth="lg">
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "left",
                        flexDirection: { xs: "column", md: "row" },
                    }}
                >
                    <Box mb={0.5}>
                        <Typography
                            variant="label"
                            color={"text.primary"}
                            sx={{
                                fontWeight: "600",
                                fontSize: "13px",
                                color: "#00263e",
                            }}
                        >
                            Registered & Corporate Office:
                        </Typography>
                        <Typography
                            variant="label"
                            component="address"
                            color={"text.primary"}
                            sx={{
                                fontStyle: "normal",
                                fontSize: "13px",
                                textWrap: "nowrap",
                                color: "#00263e",
                            }}
                        >
                            {gallagherFooterConfig.officeInfo.address.map(
                                (line, index) => (
                                    <React.Fragment key={index}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                )
                            )}
                            Phone:{" "}
                            <Link
                                href={`tel:${gallagherFooterConfig.officeInfo.phone}`}
                                color="text.primary"
                                sx={{ textDecoration: "none" }}
                            >
                                {gallagherFooterConfig.officeInfo.phone}
                            </Link>
                        </Typography>
                    </Box>

                    <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{
                            mx: 2,
                            borderColor: "text.primary",
                            display: { xs: "none", md: "block" },
                        }}
                    />

                    <Box mb={0.5} width={"100%"}>
                        <Grid container spacing={4} mb={2}>
                            {gallagherFooterConfig.linkColumns.map(
                                (column, colIndex) => (
                                    <Grid
                                        item
                                        key={colIndex}
                                        xs={
                                            12 /
                                            gallagherFooterConfig.linkColumns
                                                .length
                                        }
                                        md={
                                            12 /
                                            gallagherFooterConfig.linkColumns
                                                .length
                                        }
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            // gap: 0.5,
                                            mt: { xs: 1.5, md: 0 },
                                        }}
                                    >
                                        {column.map((link, linkIndex) => (
                                            <Link
                                                key={linkIndex}
                                                href={link.url}
                                                variant="label"
                                                sx={{
                                                    textDecoration: "underline",
                                                    "&:hover": {
                                                        color: "#23527c",
                                                        textDecoration:
                                                            "underline",
                                                    },
                                                    fontSize: "13px",
                                                    whiteSpace: isMobile
                                                        ? "normal"
                                                        : "nowrap",
                                                    color: "#00263e",
                                                }}
                                            >
                                                {link.title}
                                            </Link>
                                        ))}
                                    </Grid>
                                )
                            )}
                        </Grid>
                        <Box mb={0} textAlign="left">
                            <Typography
                                variant="label"
                                sx={{
                                    textAlign: "left",
                                    fontSize: "13px",
                                    color: "#00263e",
                                }}
                                color="text.primary"
                            >
                                {gallagherFooterConfig.copyright.text}
                                <br />
                                {gallagherFooterConfig.versions.map(
                                    (version, index) => (
                                        <React.Fragment key={index}>
                                            {version}
                                            <br />
                                        </React.Fragment>
                                    )
                                )}
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                <Divider sx={{ my: 1.5, borderColor: "text.primary" }} />

                <Typography
                    variant="label"
                    color="text.primary"
                    sx={{
                        // lineHeight: 1.6,
                        textAlign: "left",
                        fontSize: "13px",
                        color: "#00263e",
                    }}
                >
                    {gallagherFooterConfig.regulatoryInfo}
                </Typography>
            </Container>
            <CookiesComponent />
        </Box>
    );
};

export default GallagherFooter;
