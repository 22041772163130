// following are images for members

import { Gender, Member } from "../../types";
import { getVariantData } from "../functions";
const Parent_Female =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/Portal+assets/Miscellaneous+/Mother+without+BG.png";
const Parent_Male =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/Portal+assets/Miscellaneous+/Father+without+BG.png";
const Child_Female =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/Portal+assets/Miscellaneous+/Daughter+without+BG.png";
const Child_Male =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/Portal+assets/Miscellaneous+/Son+without+BG.png";
const Female =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/Portal+assets/Miscellaneous+/Wife+without+BG.png";
const Male =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/Portal+assets/Miscellaneous+/Husband+without+BG.png";
const Male_Kmd = "https://imtbepublic.s3.ap-south-1.amazonaws.com/Portal+assets/Native+art/KMD+Husband.png"
const Brother = "https://imtbepublic.s3.ap-south-1.amazonaws.com/Portal+assets/Native+art/Brother.png"
const Sister = "https://imtbepublic.s3.ap-south-1.amazonaws.com/Portal+assets/Native+art/Sister.png"

const getMemberAvatar = (member: Member) => {
    switch (member.relationShip) {
        case "Employee":
            if (member.gender === "Male") {
                return getVariantData({
                    kmd: Male_Kmd,
                    imt: Male
                })
                // return Male;
            }
            return Female;

        case "Spouse":
        case "LiveInPartner":
            if (member.gender === "Male") {
                return Male;
            }
            return Female;

        case "Child":
            if (member.gender == "Male") {
                return Child_Male;
            }
            return Child_Female;

        case "Parent":
        case "ParentInLaw":
            if (member.gender === "Male") {
                return Parent_Male;
            }
            return Parent_Female;

        case "Sibling":
            if(member.gender === "Male") {
                return Brother;
            }
            return Sister;

        default:
            return Male;
    }
};

export const getNewMemberBackground = (relationShip?: string) => {
    switch (relationShip) {
        case "Mother":
            return "#FFF3E4";
        case "Father":
            return "#E2F0FF";
        case "Self":
        case "Husband":
        case "Employee":
            return "#ECEFFF";
        case "Daugther":
            return "#FFEAEB";
        case "Son":
            return "#EFEDFF";
        case "Wife":
            return "#FFEAEB";
        default:
            return "#F0ECFF";
    }
};

export const getMemberBackground = (member: Member) => {
    switch (member.relationShip) {
        case "Employee":
            return "#F0ECFF";
        case "Spouse":
        case "LiveInPartner":
            return "#FFF3E4";
        case "Child":
            return "#FFEAEB";
        case "Parent":
        case "ParentInLaw":
            return "#E2F0FF";
        default:
            return "#F0ECFF";
    }
};

export const getAvatar = (gender: "Male" | "Female", age: string) => {
    // age is in yyyy-mm-dd format
    const today = new Date();
    const birthDate = new Date(age);
    const ageNow = today.getFullYear() - birthDate.getFullYear();
    if (ageNow < 18) {
        if (gender === "Male") {
            return Child_Male;
        }
        return Child_Female;
    }
    if (ageNow > 18 && ageNow < 50) {
        if (gender === "Male") {
            return Male;
        }
        return Female;
    }
    if (ageNow > 50) {
        if (gender === "Male") {
            return Parent_Male;
        }
        return Parent_Female;
    }
};

export default getMemberAvatar;
