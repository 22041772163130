import { useField } from "formik";
import { useFormikContext } from "formik";
import React, { useState, useEffect, useRef } from "react";
import "./MyText.scss";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import InputAdornment from "@mui/material/InputAdornment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { getFieldErrorNames } from "../../util/functions";

const MyText = ({ label, type, onChange, ...props }) => {
    const [field, meta, helpers] = useField(props);
    const [showPassword, setShowPassword] = useState(false);
    const [showAdornment, setShowAdornment] = useState(false);
    const { submitCount, isValid, errors } = useFormikContext();
    const inputRef = useRef();

    useEffect(() => {
        if (isValid) return;

        const fieldErrorNames = getFieldErrorNames(errors);
        if (fieldErrorNames.length <= 0) return;

        const element = document.querySelector(
            `input[name='${fieldErrorNames[fieldErrorNames.length - 1]}']`
        );
        if (!element) return;

        // Scroll to first known error into view
        element.scrollIntoView({ behavior: "smooth", block: "center" });
        if (element.type === "text") {
            element.focus();
        }
    }, [submitCount]);

    return (
        <div
            className={`custom-form-group ${
                props.className?.split(" ").includes("fullWidth")
                    ? "fullWidth"
                    : ""
            }`}
        >
            {type === "date" ? (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                        inputFormat="DD/MM/YYYY"
                        //inputFormat="dd/MM/yyyy"
                        id={field.name}
                        {...props}
                        {...field}
                        label={label}
                        type="date"
                        onChange={(e) => {
                            /*let date = addDays(new Date(e), 1);
              date.setUTCHours(0);
              date.setUTCMinutes(0);*/
                            /*helpers.setValue(
                addDays(
                  new Date(
                    e //.add(1, "days") //.subtract({ hours: 18, minutes: 30 })
                  ),
                  1
                )
                  .setUTCHours(0)
                  .setUTCMinutes(0) || "",
                true
              );*/
                            if (
                                e &&
                                e.utc().hour() === 18 &&
                                e.utc().minute() === 30
                            ) {
                                e.add(1, "days").subtract({
                                    hours: 18,
                                    minutes: 30,
                                });
                            }
                            console.log(
                                "sent date testing",
                                e?.toISOString() || "",
                                e?.utcOffset()
                            );
                            if (onChange) {
                                onChange(e?._d);
                            }
                            helpers.setValue(e?._d || "", true);
                        }}
                        renderInput={(params) => {
                            let textLength = params.inputProps.value.length;
                            if (textLength === 3 || textLength === 6) {
                                const inputElement =
                                    inputRef.current.querySelector("input");
                                if (inputElement) {
                                    inputElement.setSelectionRange(
                                        textLength,
                                        textLength
                                    );
                                }
                            }

                            return (
                                <TextField
                                    {...params}
                                    ref={inputRef}
                                    type="date"
                                    className={`custom-form-field  ${
                                        props?.className ? props.className : ""
                                    }`}
                                    variant="outlined"
                                    error={Boolean(meta.touched && meta.error)}
                                    helperText={meta.touched && meta.error}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        readOnly:
                                            props.readOnly || props.disabled
                                                ? true
                                                : false,
                                    }}
                                />
                            );
                        }}
                    />
                </LocalizationProvider> /*(
        <input
          type="date"
          onChange={(e) => helpers.setValue(new Date(e.target.value))}
        />
)*/
            ) : (
                <TextField
                    id={field.name}
                    {...field}
                    {...props}
                    className={`custom-form-field  ${
                        props?.className ? props.className : ""
                    }`}
                    type={
                        type !== "password"
                            ? type
                            : showPassword
                            ? "text"
                            : "password"
                    }
                    onWheel={(e) => e.target.blur()}
                    label={label}
                    variant="outlined"
                    error={Boolean(meta.touched && meta.error)}
                    helperText={meta.touched && meta.error}
                    // size="small"
                    InputProps={
                        showAdornment
                            ? {
                                  startAdornment: (
                                      <InputAdornment position="start">
                                          +91
                                      </InputAdornment>
                                  ),
                                  readOnly: props.readOnly ? true : false,
                              }
                            : { readOnly: props.readOnly ? true : false }
                    }
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={
                        type === "phone"
                            ? { inputMode: "numeric", pattern: "[0-9]{10}" }
                            : {}
                    }
                    onFocus={() =>
                        type === "phone" ? setShowAdornment(true) : null
                    }
                    onBlur={() =>
                        type === "phone" && meta.value !== ""
                            ? setShowAdornment(true)
                            : setShowAdornment(false)
                    }
                    onChange={(event) => {
                        field.onChange(event);
                        if (onChange) onChange(event.target.value);
                    }}
                    onInput={(e) => {
                        if (type === "phone") {
                            e.target.value = e.target.value
                                .replace(/[^0-9]/g, "")
                                .slice(0, 10);
                        }
                    }}
                />
            )}
        </div>
    );
};

export default MyText;
