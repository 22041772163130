import {
    Box,
    ButtonBase,
    Grid,
    IconButton,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import benifitDesktopbg from "../../assets/images/addons/Dekstop.svg";
import benifitMobilebg from "../../assets/images/addons/Mobile.svg";
import Iconify from "../../components/iconify";
import { useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useEffect, useRef, useState } from "react";
import ClaimContactCard from "../PolicyDetails/ClaimContactCard";
import { JsxAttribute } from "typescript";
import { getBarValue } from "../../util/functions";

const ClaimsContainer = ({
    children,
    className,
    title,
    subTitle,
    back,
    noFooter = false,
    noBackGround = false,
    noMinHeight = false,
    onIWantToKnowClicked = () => {},
    topComponent,
    headerRightComponent,
    footer,
    viewTabs,
    flippedHeader,
    headerScrollDynamic = false,
    newFooterContent,
}: {
    children: React.ReactNode;
    className?: string;
    title?: string;
    subTitle?: string;
    back?: () => void;
    noFooter?: boolean;
    noBackGround?: boolean;
    noMinHeight?: boolean;
    onIWantToKnowClicked?: () => void;
    topComponent?: JSX.Element;
    headerRightComponent?: JSX.Element;
    footer?: JSX.Element;
    viewTabs?: boolean;
    flippedHeader?: boolean;
    headerScrollDynamic?: boolean;
    newFooterContent?: JSX.Element | null;
}) => {
    const isMobile = useMediaQuery("(max-width: 768px)");
    const navigate = useNavigate();
    const theme = useTheme();
    const [showHelp, setShowHelp] = useState(false);

    const headercontainerRef = useRef<HTMLDivElement>(null);
    const [headercontainerHeight, setHeaderContainerHeight] = useState(0);

    useEffect(() => {
        if (headercontainerRef.current) {
            const height =
                headercontainerRef.current.getBoundingClientRect().height;
            setHeaderContainerHeight(height);
        }
    }, [title, subTitle]);

    useEffect(() => {
        // Try scrolling the window
        window.scrollTo({ top: 0, left: 0 });

        // If you have a custom scroll container, target it like this:
        const mainContent =
            document.getElementsByClassName("body-container_v2")[0]; // Replace 'scroll-container' with your element ID
        if (mainContent) {
            mainContent.scrollTo({ top: 0, left: 0 });
        }
    }, []);

    const [scrollPositon, setscrollPositon] = useState(0);
    useEffect(() => {
        const mainContent =
            document.getElementsByClassName("body-container_v2")[0];
        const handleScroll = () => {
            setscrollPositon(mainContent.scrollTop);
        };
        mainContent.addEventListener("scroll", handleScroll);
        return () => {
            mainContent.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <Box
            className={className}
            sx={{
                // paddingTop: { sm: "33px", xs: "" },
                ...(!isMobile || !headerScrollDynamic
                    ? {
                          background: `url(${
                              isMobile ? benifitMobilebg : benifitDesktopbg
                          })`,
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          backgroundPositionX: "center",
                      }
                    : {}),
                margin: { xs: "0px -20px", sm: "0px 0px 0px 0px" },
                display: "flex",
                flexDirection: "column",
                gap: { sm: "36px", xs: "0px" },

                ...(isMobile && headerScrollDynamic
                    ? {
                          "&::before": {
                              content: '""',
                              position: "absolute",
                              width: "100%",
                              height: "100%",
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              background: `url(${
                                  isMobile ? benifitMobilebg : benifitDesktopbg
                              })`,
                              // backgroundColor: `rgba(255, 255, 255, ${maxValuereturn(scrollPositon,35)/35})`,
                              backgroundSize: "contain",
                              backgroundRepeat: "no-repeat",
                              backgroundPositionX: "center",
                              opacity:
                                  1 -
                                  (scrollPositon < 35 ? scrollPositon : 35) /
                                      35,
                              zIndex: 1,
                              pointerEvents: "none",
                          },
                      }
                    : {}),
            }}
        >
            {isMobile ? (
                <Box
                    ref={headercontainerRef}
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        width: { xs: "100%", sm: "770px" },
                        margin: "auto",
                        visibility: "hidden",
                        flexDirection: { xs: "column", sm: "row" },
                        padding: {
                            xs: headerScrollDynamic ? "24px 16px" : "0",
                            sm: "0",
                        },
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            flexDirection: { xs: "column", sm: "row" },
                            gap: "10px",
                        }}
                    >
                        {!(
                            getBarValue("navBar") === "false" &&
                            !viewTabs &&
                            window.location.pathname === "/claims"
                        ) && (
                            <KeyboardBackspaceIcon
                                sx={{
                                    color: (theme) => theme.palette.grey[800],
                                    cursor: "pointer",
                                    width: "32px",
                                    height: "32px",
                                }}
                                onClick={back ? back : () => navigate(-1)}
                            />
                        )}

                        {flippedHeader ? (
                            <Stack
                                direction="column"
                                gap="4px"
                                style={{ zIndex: 2 }}
                            >
                                <Typography
                                    variant="body3"
                                    sx={{ color: theme.palette.text.primary }}
                                >
                                    {subTitle || ""}
                                </Typography>
                                <Typography
                                    variant="h4"
                                    sx={{ color: "black" }}
                                >
                                    {title || "My claims"}
                                </Typography>
                            </Stack>
                        ) : (
                            <Stack
                                direction="column"
                                gap="4px"
                                style={{ zIndex: 2 }}
                            >
                                <Typography
                                    variant="h4"
                                    sx={{ color: "black" }}
                                >
                                    {title || "My claims"}
                                </Typography>
                                <Typography
                                    variant="body3"
                                    sx={{ color: theme.palette.text.primary }}
                                >
                                    {subTitle || ""}
                                </Typography>
                            </Stack>
                        )}
                    </Box>
                    {headerRightComponent}
                </Box>
            ) : (
                <Box> </Box>
            )}

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    width: { xs: "100%", sm: "770px" },
                    margin: "auto",
                    flexDirection: { xs: "column", sm: "row" },
                    padding: { xs: "24px 16px", sm: "0" },

                    ...(isMobile && headerScrollDynamic
                        ? {
                              position: "absolute",
                              top: 0,
                              backgroundColor: `rgba(255, 255, 255, ${
                                  scrollPositon >= 35 ? 1 : 0
                              })`,
                              height:
                                  headercontainerHeight -
                                  (scrollPositon < headercontainerHeight - 80
                                      ? scrollPositon
                                      : headercontainerHeight - 80),
                              // transition: 'height 0.5s, background 0.5s',
                              borderBottom: `1px solid rgba(150, 150, 150, ${
                                  (scrollPositon < 35 ? scrollPositon : 35) / 35
                              })`,
                              overflow: "hidden",
                              zIndex: 100,
                          }
                        : {}),
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: { xs: "column", sm: "row" },
                        gap: "10px",
                    }}
                >
                    <Stack direction="row" alignItems={"center"} gap="8px">
                        {!(
                            getBarValue("navBar") === "false" &&
                            !viewTabs &&
                            window.location.pathname === "/claims"
                        ) && (
                            <KeyboardBackspaceIcon
                                sx={{
                                    color: (theme) => theme.palette.grey[800],
                                    cursor: "pointer",
                                    width: "32px",
                                    height: "32px",
                                }}
                                onClick={back ? back : () => navigate(-1)}
                            />
                        )}{" "}
                        {isMobile && headerScrollDynamic && (
                            <Typography
                                variant="h6"
                                sx={{
                                    color: theme.palette.text.dark,
                                    opacity:
                                        (scrollPositon < 70
                                            ? scrollPositon
                                            : 70) / 70,
                                    height: scrollPositon < 70 ? "1em" : "auto",
                                }}
                            >
                                {title || "My claims"}
                            </Typography>
                        )}
                    </Stack>

                    {flippedHeader ? (
                        <Stack
                            direction="column"
                            gap="4px"
                            style={{ zIndex: 2 }}
                        >
                            <Typography
                                variant="body3"
                                sx={{
                                    color: theme.palette.text.primary,
                                    ...(isMobile && headerScrollDynamic
                                        ? {
                                              opacity:
                                                  1 -
                                                  (scrollPositon < 70
                                                      ? scrollPositon
                                                      : 70) /
                                                      70,
                                          }
                                        : {}),
                                }}
                            >
                                {subTitle || ""}
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={
                                    isMobile && headerScrollDynamic
                                        ? {
                                              color: theme.palette.text.dark,
                                              opacity:
                                                  1 -
                                                  (scrollPositon < 70
                                                      ? scrollPositon
                                                      : 70) /
                                                      70,
                                          }
                                        : { color: theme.palette.text.dark }
                                }
                            >
                                {title || "My claims"}
                            </Typography>
                        </Stack>
                    ) : (
                        <Stack
                            direction="column"
                            gap="4px"
                            style={{ zIndex: 2 }}
                        >
                            <Typography
                                variant="h4"
                                sx={
                                    isMobile && headerScrollDynamic
                                        ? {
                                              color: theme.palette.text.dark,
                                              opacity:
                                                  1 -
                                                  (scrollPositon < 70
                                                      ? scrollPositon
                                                      : 70) /
                                                      70,
                                          }
                                        : { color: theme.palette.text.dark }
                                }
                            >
                                {title || "My claims"}
                            </Typography>
                            <Typography
                                variant="body3"
                                sx={{
                                    width: { xs: "auto", sm: "auto" },
                                    color: theme.palette.text.primary,
                                    ...(isMobile && headerScrollDynamic
                                        ? {
                                              opacity:
                                                  1 -
                                                  (scrollPositon < 70
                                                      ? scrollPositon
                                                      : 70) /
                                                      70,
                                          }
                                        : {}),
                                }}
                            >
                                {subTitle || ""}
                            </Typography>
                        </Stack>
                    )}
                </Box>
                {headerRightComponent}
            </Box>
            <Box
                className="healthCheckup_body"
                sx={{
                    display: "flex",
                    width: { xs: "100%", sm: "770px" },
                    // width: "100%",
                    zIndex: 2,
                    padding: isMobile ? "24px 20px" : "24px",
                    flexDirection: { xs: "column", sm: "row" },
                    gap: isMobile ? "20px" : "22px",
                    alignSelf: "stretch",
                    flexWrap: "wrap",
                    borderRadius: { sm: "48px", xs: "24px" },

                    border: "1px solid var(--Components-Outline, #ECEFFF)",
                    background: "#FFF",
                    boxShadow: {
                        sm: "0px 2px 12px 0px rgba(39, 57, 147, 0.08)",
                        xs: "none",
                    },
                    alignContent: "flex-start",
                    margin: "auto",
                }}
            >
                {topComponent}
                <Grid container>
                    <Grid
                        item
                        sm={(noFooter && !Boolean(newFooterContent)) ? 12 : 6}
                        xs={12}
                        sx={
                            isMobile
                                ? {}
                                : {
                                      padding: noBackGround ? "0px" : "16px",
                                      borderRadius: "32px",
                                      backgroundColor: noBackGround
                                          ? "transparent"
                                          : "#F8F7FA",
                                      minHeight: noMinHeight ? 0 : "456px",
                                      ...noMinHeight ? {height: "fit-content"} : {},
                                  }
                        }
                    >
                        {children}
                    </Grid>
                    {!noFooter ? (
                        <Grid item sm={6} xs={12}>
                            <div
                                className="careCardSuper"
                                style={{
                                    width: "100%",
                                    gap: "16px",
                                    paddingLeft: isMobile ? "0px" : "24px",
                                    paddingRight: "0px",
                                    paddingTop: "24px",
                                }}
                            >
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        // color: theme.palette.grey[800],
                                        marginTop: "0px",
                                        marginBottom: "24px",
                                        width: "100%",
                                        // textAlign: "center",
                                    }}
                                    color={theme.palette.text.primary}
                                >
                                    Need help?
                                </Typography>

                                <ButtonBase
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        padding: "16px 16px 16px 16px",
                                        gap: "16px",
                                        height: "68px",
                                        borderRadius: "24px",
                                        marginBottom: "16px",
                                        border: "var(--tag-badge-y-padding, 1px) solid #EBEBEB",
                                        background: "#FFF",
                                    }}
                                    onClick={() => onIWantToKnowClicked()}
                                >
                                    <Typography
                                        variant="subtitle2"
                                        sx={{
                                            color: theme.palette.text.primary,
                                        }}
                                    >
                                        {"Know about claim process"}
                                    </Typography>
                                    <Iconify
                                        icon="material-symbols:arrow-right-alt"
                                        fontSize={"20px"}
                                        color={theme.palette.text.primary}
                                    />
                                </ButtonBase>
                                <ButtonBase
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        padding: "16px 16px 16px 16px",
                                        gap: "16px",
                                        height: "68px",
                                        borderRadius: "24px",
                                        marginBottom: "16px",
                                        border: "var(--tag-badge-y-padding, 1px) solid #EBEBEB",
                                        background: "#FFF",
                                    }}
                                    onClick={() => setShowHelp(true)}
                                >
                                    <Typography
                                        variant="subtitle2"
                                        sx={{
                                            color: theme.palette.text.primary,
                                        }}
                                    >
                                        {"Connect with claim person"}
                                    </Typography>
                                    <Iconify
                                        icon="material-symbols:arrow-right-alt"
                                        fontSize={"20px"}
                                        color={theme.palette.text.primary}
                                    />
                                </ButtonBase>
                            </div>
                        </Grid>
                    ) : newFooterContent ? (
                        newFooterContent
                    ) : (
                        <></>
                    )}
                </Grid>
                {footer}
            </Box>
            <ClaimContactCard
                open={showHelp}
                handleClaimContactOpen={() => setShowHelp(false)}
            />
        </Box>
    );
};

export default ClaimsContainer;
