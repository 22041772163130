import React, { useEffect, useState } from "react";
import Dropdown from "../../../components/Input/Dropdown";
import Members from "../../../components/Members/MembersV2";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import {
    setSumInsured,
    setMembers,
    setPlan,
    setPlanList,
    setDeductible,
    setDeductibleList,
    setSumInsuredList,
    setValidation,
    setMemberInfo,
    setPremium,
    setFamily,
    setEmployeeEdit,
    setClientUuid,
    setKmdComment,
    setKmdCommentList,
    setKmdPlanDb,
    setKmdAdressRequired,
    setKmdIsKyc,
} from "../../../features/kmd/kmdSlice";
import "./kmdStep1.scss";
import advService from "../../../util/advService";
import { AxiosResponse } from "axios";
import { JavaResponse, Member, MemberWithState } from "../../../types";
import {
    KmdMemberDto,
    KmdPlanDto,
    KmdPlanUpdateDto,
} from "../../../types/DTO/kmdDto";
import {
    KMD_MEMBERS_GET,
    KMD_PLANS_GET,
    KMD_SAVE_MEMBERS_POST,
    KMD_UPDATE_MEMBERS_POST,
} from "../../../util/newconfig";
import {
    Box,
    Button,
    CircularProgress,
    Skeleton,
    Stack,
    Typography,
    useTheme,
} from "@mui/material";
import alerts from "../../../util/alerts";
import Iconify from "../../../components/iconify";

interface Props {
    // Define your component's props here
    submit: () => void;
}

const KmdStep1: React.FC<Props> = ({ submit }: Props) => {
    const {
        comment,
        commentList,
        policyType,
        plan,
        planList,
        sumInsured,
        sumInsuredList,
        deductible,
        deductibleList,
        members,
        validation,
        family,
        premium,
        memberInfo,
        employeeEdit,
        clientUuid,
        masterUuid,
    } = useSelector((state: RootState) => state.kmd);
    const reducerDispatch = useDispatch();

    const [errors, setErrors] = useState<string[]>([]);
    const theme = useTheme();

    /**
     * @description This function validates whether the combination of the members is valid or not, only valid combinations are allowed are:
     * 1. Employee
     * 2. Employee + Spouse
     * 3. Employee + Spouse + Child
     * 4. Employee + Spouse + Child + Child
     * @param members The members to be validated
     * @returns true if the combination is valid, false otherwise
     */
    const validateMembers = (members: MemberWithState[]): boolean => {
        let employee = members.find(
            (member) =>
                member.relationShip === "Employee" ||
                member.relationShip === "Self"
        );
        let spouse = members.find((member) => member.relationShip === "Spouse");
        let children = members.filter(
            (member) => member.relationShip === "Child"
        );
        let adultsCount = members.filter(
            (member) =>
                member.relationShip === "Employee" ||
                member.relationShip === "Spouse"
        ).length;
        let childrenCount = members.filter(
            (member) => member.relationShip === "Child"
        ).length;
        if (
            (adultsCount == 1 && childrenCount == 0) ||
            (adultsCount == 2 && childrenCount == 0) ||
            (adultsCount == 2 && childrenCount == 1) ||
            (adultsCount == 2 && childrenCount == 2) || 
            policyType === "TOPUP"
        ) {
            return true;
        }
        alerts(
            "The opted family plan structure isn't allowed. For any queries, kindly get in touch with  the COA(gurgaon@kmdastur.com)",
            "error",
            5000
        );
        return false;
    };

    const validateSIAndDeductible = (
        si: number,
        deductible: number,
        policyType: "GMC" | "GPA" | "GPI" | "TOPUP" | null
    ): boolean => {
        if(policyType === "TOPUP") {
            if(!deductible) {
                alerts(
                    "Please select a deductible",
                    "error",
                    3000
                );
                return false;
            }
        }
        if (si === 0) {
            alerts(
                "Please select a sum insured",
                "error",
                3000
            );
            return false;
        }
        return true;
    }

    const handleMembersSiChange = (
        newMembers: MemberWithState[],
        val: { planName: string } | { sumInsured: number; deductible?: number },
        policyType: "GMC" | "GPA" | "GPI" | "TOPUP" | null
    ) => {
        // if (policyType === "GMC") {
        // saveMembers(members);
        if (policyType) {
            let config = { ...KMD_UPDATE_MEMBERS_POST };
            config.url = config.url.replace("{key}", masterUuid || "");
            advService(
                config,
                {
                    members: newMembers
                        .filter((member) => member.state !== "remove")
                        .filter((member) =>
                            policyType !== "GMC" && policyType !== "TOPUP"
                                ? member.relationShip === "Employee" ||
                                  member.relationShip === "Self"
                                : true
                        ),
                    siPlan: val,
                },
                (res: AxiosResponse<JavaResponse<KmdPlanUpdateDto>>) => {
                    if (res.data.success) {
                        let newMembers: MemberWithState[] = [];
                        newMembers = res.data.response.members.map(
                            (member) => ({
                                ...member,
                                state: "old",
                            })
                        );
                        if (policyType !== "GMC" && policyType !== "TOPUP") {
                            newMembers = newMembers.filter(
                                (member) =>
                                    member.relationShip === "Employee" ||
                                    member.relationShip === "Self"
                            );
                        }

                        reducerDispatch(setMembers(newMembers));
                        reducerDispatch(
                            setPremium(
                                res.data.response.members.reduce(
                                    (acc, member) => acc + member.premium,
                                    0
                                )
                            )
                        );
                    }
                }
            );
        }
    };

    useEffect(() => {
        if (plan) {
            for (let i = 0; i < planList.length; i++) {
                if (plan === planList[i]) {
                    reducerDispatch(setKmdComment(commentList![i]));
                }
            }
        }
    }, [plan, planList]);

    useEffect(() => {
        if (members.length === 0 && policyType && masterUuid) {
            let tempPlanList: string[] = [];
            let tempPlan: string = "";
            let tempSumInsured: number = 0;
            let tempDeductible: number = 0;
            let config = { ...KMD_PLANS_GET };
            config.url =
                policyType === "GMC"
                    ? config.url.replace("{key}", "HDquej1rkfUs")
                    : config.url.replace("{key}", masterUuid || "");
            advService(
                config,
                {},
                (res: AxiosResponse<JavaResponse<KmdPlanDto>>) => {
                    if (res.data.success) {
                        if (
                            res.data.response.d_sum_insured_detail.plan?.length
                        ) {
                            tempPlanList =
                                res.data.response.d_sum_insured_detail.plan;
                            reducerDispatch(setPlanList(tempPlanList));
                            tempPlan =
                                res.data.response.d_sum_insured_detail.plan[0];
                            reducerDispatch(setPlan(tempPlan));
                            reducerDispatch(
                                setKmdCommentList(
                                    res.data.response.d_sum_insured_detail.planDb.map(
                                        (plandb) => plandb.comment
                                    )
                                )
                            );
                            reducerDispatch(
                                setKmdPlanDb(
                                    res.data.response.d_sum_insured_detail
                                        .planDb
                                )
                            );
                        } else {
                            // if (policyType === "GMC") {
                            reducerDispatch(
                                setSumInsuredList(
                                    res.data.response.d_sum_insured_detail
                                        .sumInsured
                                )
                            );
                            tempSumInsured =
                                res.data.response.d_sum_insured_detail
                                    .sumInsured[0];
                            reducerDispatch(setSumInsured(tempSumInsured));

                            reducerDispatch(
                                setDeductibleList(
                                    res.data.response.d_sum_insured_detail
                                        .deductibles
                                )
                            );
                            tempDeductible =
                                res.data.response.d_sum_insured_detail
                                    .deductibles[0];
                            reducerDispatch(setDeductible(tempDeductible));
                            // } else if (policyType === "GPA") {
                            //     reducerDispatch(
                            //         setSumInsuredList([
                            //             500000, 1000000, 2500000,
                            //         ])
                            //     );
                            //     tempSumInsured = 500000;
                            //     reducerDispatch(setSumInsured(tempSumInsured));
                            // }
                        }
                        reducerDispatch(
                            setValidation({
                                ...res.data.response.d_policy_demo_validation
                                    .ageValidation,
                                Child: {
                                    maxAge: res.data.response
                                        .d_policy_demo_validation.ageValidation
                                        .Child.maxAge,
                                    minAge: res.data.response
                                        .d_policy_demo_validation.ageValidation
                                        .Child.minAge,
                                    minDays: 90,
                                },
                            })
                        );
                        reducerDispatch(
                            setFamily(
                                res.data.response.d_policy_demo_validation
                                    .family
                            )
                        );
                        advService(
                            {
                                ...KMD_MEMBERS_GET,
                                url: KMD_MEMBERS_GET.url.replace(
                                    "{masterUuid}",
                                    masterUuid || ""
                                ),
                            },
                            {},
                            (
                                res2: AxiosResponse<JavaResponse<KmdMemberDto>>
                            ) => {
                                if (res2.data.success) {
                                    reducerDispatch(
                                        setKmdIsKyc(
                                            res2.data.response.kyc
                                                ? true
                                                : false
                                        )
                                    );
                                    reducerDispatch(
                                        setKmdAdressRequired(
                                            res2.data.response.addressRequired
                                        )
                                    );
                                    reducerDispatch(
                                        setClientUuid(
                                            res2.data.response.proposerDTO
                                                .clientUuid
                                        )
                                    );
                                    reducerDispatch(
                                        setEmployeeEdit(
                                            res2.data.response.employeeEdit
                                        )
                                    );
                                    let newMembers: MemberWithState[] = [];
                                    newMembers =
                                        res2.data.response.policyEmployeeMemberDTOS.map(
                                            (member) => ({
                                                ...member,
                                                state: "old",
                                            })
                                        );

                                    if (
                                        policyType !== "GMC" &&
                                        policyType !== "TOPUP"
                                    ) {
                                        newMembers = newMembers.filter(
                                            (member) =>
                                                member.relationShip ===
                                                    "Employee" ||
                                                member.relationShip === "Self"
                                        );
                                    }
                                    reducerDispatch(setMembers(newMembers));
                                    reducerDispatch(
                                        setMemberInfo({
                                            name:
                                                res2.data.response.policyEmployeeMemberDTOS.find(
                                                    (member) =>
                                                        member.relationShip ===
                                                            "Employee" ||
                                                        member.relationShip ===
                                                            "Self"
                                                )?.name || "",
                                            dob:
                                                res2.data.response.policyEmployeeMemberDTOS.find(
                                                    (member) =>
                                                        member.relationShip ===
                                                            "Employee" ||
                                                        member.relationShip ===
                                                            "Self"
                                                )?.dob || "",
                                            phone:
                                                JSON.parse(
                                                    sessionStorage.getItem(
                                                        "user"
                                                    ) || "{}"
                                                ).phone || "",
                                            addressLine1:
                                                res2.data.response.proposerDTO
                                                    .addressDTO?.addressLine1,
                                            addressLine2:
                                                res2.data.response.proposerDTO
                                                    .addressDTO?.addressLine2,
                                            pincode:
                                                res2.data.response.proposerDTO
                                                    .addressDTO?.pincode,
                                            nomineeName: "",
                                            nomineeRelation: "",
                                            addressUuid:
                                                res2.data.response.proposerDTO
                                                    .addressDTO?.uuid,
                                            city: res2.data.response.proposerDTO
                                                .addressDTO?.city,
                                            nomineeDob: "",
                                        })
                                    );
                                    handleMembersSiChange(
                                        res2.data.response.policyEmployeeMemberDTOS.map(
                                            (member) => ({
                                                ...member,
                                                state: "old",
                                            })
                                        ),
                                        tempPlanList.length > 0
                                            ? { planName: tempPlan || "" }
                                            : {
                                                  sumInsured:
                                                      tempSumInsured || 0,
                                                  ...(policyType ===
                                                      "TOPUP" && {
                                                      deductible:
                                                          deductible || 0,
                                                  }),
                                              },
                                        policyType
                                    );
                                }
                            }
                        );
                    }
                }
            );
        }
    }, [policyType, masterUuid]);

    const saveMembers = (members: MemberWithState[]) => {
        members = members
            // .filter((member) => {
            //     member.relationShip !== "Employee" &&
            //         member.relationShip !== "Self";
            // })
            .map((member) => {
                return {
                    ...member,
                    active:
                        member.state === "add"
                            ? true
                            : member.state === "remove"
                            ? false
                            : true,
                };
            });
        let config = { ...KMD_SAVE_MEMBERS_POST };
        config.url = config.url.replace("{uuid}", clientUuid || "");
        advService(
            config,
            members,
            (res: AxiosResponse<JavaResponse<MemberWithState[]>>) => {
                if (res.data.success) {
                    let newMembers = res.data.response.filter(
                        (member) => member.active
                    );
                    // // if employee/self is not in the list, add it
                    // if (
                    //     !newMembers.find(
                    //         (member) =>
                    //             member.relationShip === "Employee" ||
                    //             member.relationShip === "Self"
                    //     )
                    // ) {
                    //     let employee = members.find(
                    //         (member) =>
                    //             member.relationShip === "Employee" ||
                    //             member.relationShip === "Self"
                    //     );
                    //     if (employee) {
                    //         newMembers.push(employee);
                    //     }
                    // }

                    console.log(res.data.response);
                    handleMembersSiChange(
                        newMembers,
                        planList.length > 0
                            ? { planName: plan || "" }
                            : {
                                  sumInsured: sumInsured || 0,

                                  ...(policyType === "TOPUP" && {
                                      deductible: deductible || 0,
                                  }),
                              },
                        policyType
                    );
                }
            }
        );
    };

    if (!planList.length && !sumInsuredList.length) {
        return (
            <div className="kmdStep1">
                <CircularProgress />
            </div>
        );
    }

    const getTopuopSumInsuredList = (list: number[]) => {
        let topupSumInsuredList: number[] = [];
        // remove repeated values
        
        topupSumInsuredList = list.filter((item, index) => list.indexOf(item) === index);
        return topupSumInsuredList.map((item) => ({
            id: item,
            name: item,
        }));
    };

    const getDeductibleList = (sumInsured: number, list: number[]) => {
        let deductibleList: number[] = [];
        // remove repeated values
        for (let i = 0; i < list.length; i++) {
            if (sumInsuredList[i] === sumInsured) {
                deductibleList.push(list[i]);
            }
        }
        return deductibleList.map((item) => ({
            id: item,
            name: item,
        }));
    };

    return (
        <Box
            sx={{
                padding: "20px",
            }}
        >
            <Stack spacing={2}>
                <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    <Typography variant="subtitle2">Sum Insured</Typography>
                    <div className="kmdStep1_sumInsured">
                        <Dropdown
                            defaultVal={planList.length > 0 ? plan : sumInsured}
                            items={
                                policyType === "TOPUP"
                                    ? getTopuopSumInsuredList(
                                          sumInsuredList
                                      )
                                    : (planList.length > 0
                                          ? planList
                                          : sumInsuredList
                                      ).map((item) => ({
                                          id: item,
                                          name: item,
                                      }))
                            }
                            change={(value: number) => {
                                handleMembersSiChange(
                                    members,
                                    planList.length > 0
                                        ? { planName: value.toString() || "" }
                                        : {
                                              sumInsured: value || 0,
                                              ...(policyType === "TOPUP" && {
                                                  deductible:  0,
                                              }),
                                          },
                                    policyType
                                );
                                planList.length > 0
                                    ? reducerDispatch(setPlan(value.toString()))
                                    : reducerDispatch(setSumInsured(value));
                                if(policyType === "TOPUP") {
                                    reducerDispatch(setDeductible(0));
                                }
                            }}
                            size="small"
                            style={{
                                width: "150px"
                            }}
                        />
                    </div>
                </Stack>
                {policyType === "TOPUP" && (
                    <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Typography variant="subtitle2">Deductible</Typography>
                        <div className="kmdStep1_sumInsured">
                            <Dropdown
                                defaultVal={deductible}
                                items={getDeductibleList(
                                    sumInsured || 0,
                                    deductibleList
                                )}
                                change={(value: number) => {
                                    handleMembersSiChange(
                                        members,
                                        {
                                            sumInsured: sumInsured || 0,
                                            deductible: value || 0,
                                        },
                                        policyType
                                    );
                                   reducerDispatch(setDeductible(value));
                                }}
                                size="small"
                                key={sumInsured}
                                style={{
                                    width: "150px"
                                }}
                            />
                        </div>
                    </Stack>
                )}
                {comment && (
                    <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Typography variant="subtitle2">Deductible</Typography>
                        <div className="kmdStep1_premium fontColor_primary   fontWeight_500">
                            {comment.split("Worldwide ")![0]} <br /> Worldwide{" "}
                            {comment.split("Worldwide ")![1]}
                        </div>
                    </Stack>
                )}
                {policyType !== "GPI" && (
                    <Members
                        members={members}
                        setMembers={(members) => {
                            saveMembers(members);
                            // handleMembersSiChange(
                            //     members,
                            //     planList.length > 0
                            //         ? { plan: plan || "" }
                            //         : { sumInsured: sumInsured || 0 },
                            //     policyType
                            // );
                        }}
                        setMembersError={setErrors}
                        validation={validation}
                        family={family}
                        editOnly={policyType === "GPA"}
                        employeeReadOnly={!employeeEdit}
                        employeeReadOnlyMessage="If you want to update your personal information, please get in touch with the COA(gurgaon@kmdastur.com)"
                        fullWidth={true}
                    />
                )}
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <Stack>
                        <Typography variant="h5">
                            <span className="rupee">₹</span>{" "}
                            {(premium || 0).toLocaleString()}  /- year
                        </Typography>
                        <Typography variant="body3">
                            Premium Excl. GST
                        </Typography>
                    </Stack>
                    <Button
                        onClick={() => {
                            if(policyType==="TOPUP" && validateSIAndDeductible(sumInsured || 0, deductible || 0, policyType)) {
                                if (validateMembers(members)) {
                                    submit();
                                }
                            } else if (policyType!=="TOPUP"){
                                if (validateMembers(members)) {
                                    submit();
                                }
                            }
                        }}
                        sx={{
                            color: "white",
                            backgroundColor: theme.palette?.brand?.main,
                            "&:hover": {
                                backgroundColor: theme.palette?.grey[800],
                            },
                        }}
                        size="small"
                    >
                        Continue
                        <Iconify icon="material-symbols:arrow-right-alt" />
                    </Button>
                </Stack>
            </Stack>
        </Box>
    );
};

export default KmdStep1;
