import React, { useEffect } from "react";
import "./KmdInfo.scss";
import KmdStep1 from "./steps/kmdStep1";
import { useDispatch, useSelector } from "react-redux";

import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { RootState } from "../../store";
// import { Box, Divider, Modal, Typography, styled } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import GenericModalContainer from "../../components/Modals/GenericModalContainer";
import {
    Box,
    Divider,
    Drawer,
    IconButton,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import Iconify from "../../components/iconify";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";

const HERO =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/graphic/hero.svg";
const BASESUM =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/graphic/Base_Sum.svg";

const GMC_ILLUSTRATION =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/Portal+assets/Art+work/Health+insurance.png";
const GPA_ILLUSTRATION =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/KMDCOA/KMD+assets/KMD+PA.png";
const GPI_ILLUSTRATION =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/Portal+assets/Art+work/Miscellaneous.png";
const TOPUP_ILLUSTRATION =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/KMDCOA/KMD+assets/KMD+STOPUP.png";

const CARELOGO =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/insurer_logos/care.svg";
const ICICILOGO =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/insurer_logos/icici.svg";

interface KmdInfoProps {
    // Add any props you need here
    submit: () => void;
}

const coverageInfo = [
    {
        description:
            "Sum Insured options of Rs. 3 Lacs, 5 Lacs, 7.5 Lacs and 10 Lacs",
        imageUrl: "uil:umbrella",
    },
    {
        description:
            "No Medical Checkup till age of 65 Years. Cover available for persons above 65 Years",
        imageUrl: "material-symbols:calendar-today-rounded",
    },
    {
        description:
            "Room Rent 1.5% of SI limit and ICU Expenses on Actual Basis",
        imageUrl: "icon-park-outline:hospital-bed",
    },
    {
        description: "Pre Existing Diseases covered after 12 months",
        imageUrl: "icon-park-outline:protect",
    },
    {
        description:
            "Road Ambulance charges upto Rs. 2,500/- and Air Ambulance charges upto Rs. 50,000/-",
        imageUrl: "uil:ambulance",
    },
    {
        description: "No Copay",
        imageUrl: "icon-park-outline:zero-key",
    },
];

const gpaCoverageInfo = [
    {
        description: "Sum Insured options of Rs. 10 Lacs, 15 Lacs and 25 Lacs",
        imageUrl: "uil:umbrella",
    },
    {
        description: "For Students, Flat sum insured of Rs. 10 Lacs",
        imageUrl: "icon-park-outline:degree-hat",
    },
    {
        description:
            "Coverage against Death and Disablement due to any Accidental, External and Violent Means",
        imageUrl: "hugeicons:danger",
    },
    {
        description: "Coverage for loss of 1 Limb or 1 Eye - 50% of SI",
        imageUrl: "icon-park-outline:protect",
    },
    {
        description:
            "Coverage for Accidental Death, Loss of 2 Limbs/Eyes, Loss of 1 Limb and 1 Eye - 100% of SI",
        imageUrl: "icon-park-outline:protect",
    },
    {
        description: "Worldwide coverage",
        imageUrl: "gridicons:globe",
    },
    {
        description:
            "Additional coverages for Accidental Burns and Ambulance charges",
        imageUrl: "icon-park-outline:protect",
    },
    {
        description: "Terrorism Cover",
        imageUrl: "icon-park-outline:protect",
    },
];

const gpiCoverageInfo = [
    {
        description:
            "Legal Liability due to breach of professional duty due to negligent act, error or omission",
        imageUrl: "icon-park-outline:protect",
    },
    {
        description: "Legal Expenses",
        imageUrl: "icon-park-outline:protect",
        popup: "Legal costs and expenses reasonably incurred in the investigation, defence and settlement of any Claim.",
    },
    {
        description: "Advancement of Defense Cost ",
        imageUrl: "gridicons:globe",
        popup: "The Company shall, prior to the final disposition of any Claim, advance Defence Costs or Legal Representation Expenses as provided under this policy within Forty-five (45) days of receipt of an invoice for same from defence counsel.",
    },
    {
        description: "Employee Dishonesty Covered",
        imageUrl: "mdi:user",
        popup: "Company will indemnify the Insured against civil liability which the Insured incurs as a result of any Claim arising out of any dishonest, fraudulent, criminal or malicious act or omission of any employee of the Insured but only when providing Professional Services.",
    },

    {
        description: "Defamation Cover",
        imageUrl: "icon-park-outline:protect",
        popup: "Company will indemnify the Insured against civil liability the Insured incurs in respect of a Claim arising from the performance of Professional Services alleging any libel, slander, defamation or injurious falsehood by the Insured. ",
    },
    {
        description: "Servicing Support by KMD Team on 24x7x365 Basis",
        imageUrl: "icon-park-outline:protect",
    },
];

const coverageInfoTopUp = [
    {
        description: "Sum Insured Coverage for upto 1 crore",
        imageUrl: "icon-park-outline:umbrella",
    },
    {
        description: "Day Care Treatments are covered",
        imageUrl: "icon-park-outline:hospital",
    },
    {
        description: "Pre and Post Hospitalisation Expenses are covered",
        imageUrl: "icon-park-outline:medical-box",
    },
    {
        description:
            "Domestic Road Ambulance Coverage for Rs. 5,000 per hospitalisation",
        imageUrl: "icon-park-outline:ambulance",
    },
    {
        description: "Pre Existing Illness are covered after 12 months",
        imageUrl: "icon-park-outline:calendar",
    },
];

const faq = [
    "Cover available for Architect, Spouse and 2 Children",
    "Cover available up to the age of 65 years",
    "Children can be covered up to the age of 25 years",
    "Income Tax Benefit available under Section 80D",
    <>
        Policy Wordings
        <a
            href="https://imtbepublic.s3.ap-south-1.amazonaws.com/KMDCOA/Health/Policy+Wordings+(GMC).pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="pl5 pr5"
        >
            <CloudDownloadIcon />
        </a>{" "}
        & Benefits
        <a
            href="https://imtkmdpublic.s3.ap-south-1.amazonaws.com/KMDCOA/GMC/Benefits%2B(GMC).docx.pdf"
            target="_blank"
            className="pl5"
            rel="noopener noreferrer"
        >
            <CloudDownloadIcon />
        </a>
    </>,
];

const gpaFaq = [
    "Plans available for Architect only",
    "Cover available upto 70yrs",
    <>
        Policy Wordings
        <a
            href="https://imtbepublic.s3.ap-south-1.amazonaws.com/KMDCOA/GPA/Policy+wordings+(GPA).pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="pl5 pr5"
        >
            <CloudDownloadIcon />
        </a>{" "}
        & Benefits
        <a
            href="https://imtkmdpublic.s3.ap-south-1.amazonaws.com/KMDCOA/PA/Benefits%2B(GPA).docx.pdf"
            target="_blank"
            className="pl5"
            rel="noopener noreferrer"
        >
            <CloudDownloadIcon />
        </a>
    </>,
];

const topupFaq = [
    "Super Top Up can offer you an enhanced / Increased coverage to your existing health insurance cover",
    "Offering higher sum insured options till 1 Crore",
    "Cost effective as the premium is very low",
    "Cashless facility is also available even if you have a base policy with some other insurance provider.",
    "Cover your existing diseases only after 12 months",
    <>
        Policy Wordings
        <a
            href="https://imtbepublic.s3.ap-south-1.amazonaws.com/KMDCOA/Super+Topup/Detailed+Policy+Wordings+-+Super+Top+Up+(1).pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="pl5 pr5"
        >
            <CloudDownloadIcon />
        </a>{" "}
        & Benefits
        <a
            href="https://imtbepublic.s3.ap-south-1.amazonaws.com/KMDCOA/Super+Topup/Super+Top+Up+Benefits.docx"
            target="_blank"
            className="pl5"
            rel="noopener noreferrer"
        >
            <CloudDownloadIcon />
        </a>
    </>,
];

const gpiFaq = [
    "Provides financial protection from lawsuits filed against the architects",
    "Fulfills Contractual Obligation, wherever required",
    "PI insurance for architects increases credibility as the client does not have to worry about suffering any unforeseen loss and gets coverage for it",
    <>
        Policy Wordings
        <a
            href="https://imtbepublic.s3.ap-south-1.amazonaws.com/KMDCOA/PI/Policy+wordings+(PI)+.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="pl5 pr5"
        >
            <CloudDownloadIcon />
        </a>{" "}
        & Benefits
        <a
            href="https://imtkmdpublic.s3.ap-south-1.amazonaws.com/KMDCOA/PI/Benefits%2B(PI).docx.pdf"
            target="_blank"
            className="pl5"
            rel="noopener noreferrer"
        >
            <CloudDownloadIcon />
        </a>
    </>,
];

const KmdInfo: React.FC<KmdInfoProps> = ({ submit }: KmdInfoProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const { policyType } = useSelector((state: RootState) => state.kmd);
    const [open, setOpen] = React.useState(false);
    const [modalText, setModalText] = React.useState<{
        header: string;
        content: string;
    }>({
        header: "",
        content: "",
    });
    const handleClose = () => setOpen(false);

    const [openWhatsCovered, setOpenWhatsCovered] = React.useState(false);
    const [openFAQDrawer, setOpenFAQDrawer] = React.useState(false);
    const navigate = useNavigate();

    // Add your component logic here
    useEffect(() => {
        if (window.location.search.includes("purchase=true")) {
            const kmdFormElement = document.getElementById("kmdForm");
            if (kmdFormElement) {
                kmdFormElement.scrollIntoView();
            }
        }
    }, []);

    useEffect(() => {
        document.body.style.backgroundColor = "white";
        return () => {
            document.body.style.backgroundColor = "#f6f6f6";
        };
    }, []);

    const heroText = (policyType: "GMC" | "GPA" | "GPI" | "TOPUP" | null) => {
        if (policyType === "GMC") {
            return (
                <>
                    <Typography
                        variant="h4"
                        sx={{
                            marginBottom: "24px",
                            color: theme.palette.text.primary,
                        }}
                    >
                        Purchase Health Policy upto{" "}
                        <span className="fontColor_primary">
                            <span className="rupee">₹</span>10 Lacs
                        </span>{" "}
                        starting from
                        <span className="fontColor_primary">
                            <span className="rupee"> ₹</span>
                            4685/- year *
                        </span>
                    </Typography>
                    <Typography variant="body2">
                        Specialised Insurance Policies at attractive prices and
                        wider coverage designed specifically for Architects
                        registered with COA & their Family Members
                    </Typography>
                </>
            );
        } else if (policyType === "GPA") {
            return (
                <>
                    <Typography
                        variant="h4"
                        sx={{
                            marginBottom: "24px",
                            color: theme.palette.text.primary,
                        }}
                    >
                        Purchase Personal Accident Cover upto{" "}
                        <span className="fontColor_primary">
                            <span className="rupee">₹</span>25 Lacs
                        </span>
                    </Typography>
                    <Typography variant="body2">
                        Specialised PA Policy at attractive prices and wider
                        coverage designed specifically for Architects registered
                        with COA and Architecture Students
                    </Typography>
                </>
            );
        } else if (policyType === "GPI") {
            return (
                <>
                    <Typography
                        variant="h4"
                        sx={{
                            marginBottom: "24px",
                            color: theme.palette.text.primary,
                        }}
                    >
                        Purchase Professional Indemnity Coverage upto{" "}
                        <span className="fontColor_primary">
                            <span className="rupee">₹</span>25 Crores
                        </span>
                    </Typography>
                    <Typography variant="body2">
                        Specialised Insurance Policies at attractive prices and
                        wider coverage designed specifically for Architects
                        registered with COA
                    </Typography>
                </>
            );
        } else if (policyType === "TOPUP") {
            return (
                <>
                    <Typography
                        variant="h4"
                        sx={{
                            marginBottom: "24px",
                            color: theme.palette.text.primary,
                        }}
                    >
                        Make your family safer with a sum insured of up to
                        <span className="fontColor_primary">
                            <span className="rupee"> ₹</span>1 crore
                        </span>
                        , starting from just
                        <span className="fontColor_primary">
                            <span className="rupee"> ₹</span>2035 annually *
                        </span>{" "}
                        , for any hospitalization
                    </Typography>
                    <Typography variant="body2">
                        Specialised Insurance Policies at attractive prices and
                        wider coverage designed specifically for Architects
                        registered with COA & their Family Members
                    </Typography>
                </>
            );
        }
        return <div>No policy type selected</div>;
    };

    const getIllustration = (
        policyType: "GMC" | "GPA" | "GPI" | "TOPUP" | null
    ) => {
        switch (policyType) {
            case "GMC":
                return GMC_ILLUSTRATION;
            case "GPA":
                return GPA_ILLUSTRATION;
            case "GPI":
                return GPI_ILLUSTRATION;
            case "TOPUP":
                return TOPUP_ILLUSTRATION;
            default:
                return "";
        }
    };

    const policyTypeFullFormMap = {
        GMC: "Group Health Cover",
        GPA: "Group Personal Accident Cover",
        GPI: "Professional Indemnity cover",
        TOPUP: "Group Care 360 – Super Top Up",
    };

    return (
        <div className="kmdInfo">
            <Box
                sx={{
                    // display: "flex",
                    width: { xs: "100%", sm: "770px" },
                    // position: "relative",
                    // justifyContent: "space-between",
                    // alignItems: "flex-start",
                    // alignContent: "flex-start",
                    margin: "auto",
                }}
            >
                <Box
                    sx={{
                        marginTop: "32px",
                        marginBottom: "36px",
                    }}
                >
                    <Stack direction="row" alignItems="center" gap={"16px"}>
                        <KeyboardBackspaceIcon
                            sx={{
                                color: theme.palette.grey[800],
                                cursor: "pointer",
                                width: "32px",
                                height: "32px",
                            }}
                            onClick={() => {
                                navigate(-1);
                            }}
                        />
                        {policyType && (
                            <Typography variant="h4">
                                {policyTypeFullFormMap[policyType]}
                            </Typography>
                        )}
                        <Box
                            sx={{
                                justifySelf: "flex-end",
                                marginLeft: "auto",
                            }}
                        >
                            <Stack
                                direction="row"
                                gap="16px"
                                alignItems="center"
                            >
                                {!isMobile && (
                                    <Typography
                                        variant="body3"
                                        color={theme.palette?.grey[700]}
                                    >
                                        Insurance partner
                                    </Typography>
                                )}
                                <img
                                    src={
                                        policyType === "TOPUP"
                                            ? CARELOGO
                                            : ICICILOGO
                                    }
                                    height={"40px"}
                                />
                            </Stack>
                        </Box>
                    </Stack>
                </Box>
                <Stack
                    direction={{
                        xs: "column",
                        sm: "row",
                    }}
                    spacing="40px"
                >
                    <Box
                        sx={{
                            width: {
                                xs: "100%",
                                sm: "456px",
                            },
                        }}
                    >
                        <Box sx={{ marginY: "48px" }}>
                            {heroText(policyType)}
                        </Box>
                        <Typography variant="h4" sx={{ marginBottom: "24px" }}>
                            Calculate your premium
                        </Typography>
                        <Box
                            sx={{
                                padding: {
                                    xs: "0",
                                    sm: "24px",
                                },
                                borderRadius: "32px",
                                backgroundColor: theme.palette.grey[200],
                            }}
                        >
                            <Box
                                sx={{
                                    backgroundColor: "white",
                                    borderRadius: "24px",
                                    border: {
                                        xs: `1px solid ${theme.palette.grey[300]}`,
                                        sm: "none",
                                    },
                                }}
                            >
                                <KmdStep1 submit={submit} />
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Box
                            sx={{
                                height: "196px",
                                display: {
                                    xs: "none",
                                    sm: "flex",
                                },
                                alignItems: "center",
                                justifyContent: "center",
                                marginBottom: "48px",
                            }}
                        >
                            <img
                                src={getIllustration(policyType)}
                                alt="Illustration"
                                width="160px"
                            />
                        </Box>

                        <Stack spacing="24px">
                            <Typography variant="subtitle1">
                                You may be looking for
                            </Typography>

                            <Box
                                sx={{
                                    padding: "16px",
                                    borderRadius: "12px",
                                    border: "var(--tag-badge-y-padding, 1px) solid #EBEBEB",
                                    cursor: "pointer",
                                }}
                                onClick={() => setOpenWhatsCovered(true)}
                            >
                                <Stack
                                    direction="row"
                                    spacing="24px"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Typography variant="subtitle2">
                                        What's covered in this plan?
                                    </Typography>
                                    <Box
                                        sx={{
                                            width: "40px",
                                            height: "40px",
                                            borderRadius: "50%",
                                            border: "1px solid #EBEBEB",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Iconify icon="material-symbols:chevron-right" />
                                    </Box>
                                </Stack>
                            </Box>

                            <Box
                                sx={{
                                    padding: "16px",
                                    borderRadius: "12px",
                                    border: "var(--tag-badge-y-padding, 1px) solid #EBEBEB",
                                    cursor: "pointer",
                                }}
                                onClick={() => setOpenFAQDrawer(true)}
                            >
                                <Stack
                                    direction="row"
                                    spacing="24px"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Typography variant="subtitle2">
                                        Benefits of getting this plan
                                    </Typography>
                                    <Box
                                        sx={{
                                            width: "40px",
                                            height: "40px",
                                            borderRadius: "50%",
                                            border: "1px solid #EBEBEB",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Iconify icon="material-symbols:chevron-right" />
                                    </Box>
                                </Stack>
                            </Box>

                            {policyType === "TOPUP" && (
                                <Box
                                    sx={{
                                        padding: "16px",
                                        borderRadius: "12px",
                                        border: "var(--tag-badge-y-padding, 1px) solid #EBEBEB",
                                        cursor: "pointer",
                                    }}
                                >
                                    <a
                                        href="https://imtbepublic.s3.ap-south-1.amazonaws.com/KMDCOA/Super+Topup/Know+More+-+Super+Top+Up+Health+Insurance.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Stack
                                            direction="row"
                                            spacing="24px"
                                            alignItems="center"
                                            justifyContent="space-between"
                                        >
                                            <Typography variant="subtitle2">
                                                Know More
                                            </Typography>
                                            <Box
                                                sx={{
                                                    width: "40px",
                                                    height: "40px",
                                                    borderRadius: "50%",
                                                    border: "1px solid #EBEBEB",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <Iconify icon="material-symbols:chevron-right" />
                                            </Box>
                                        </Stack>
                                    </a>
                                </Box>
                            )}
                        </Stack>
                    </Box>
                </Stack>
            </Box>
            {/* <div className="kmdInfo_heroSection">
                <div className="kmdInfo_heroText">{heroText(policyType)}</div>
                <div className="kmdInfo_heroImage">
                    <img src={HERO} alt="Hero Image" />
                </div>
            </div> */}
            {/* <div className="kmdInfo_coverageInfo ">
                <h1 className="fontSize_40 fontWeight_700 mb20 mt50">
                    What's <span className="fontColor_primary">Covered ?</span>
                </h1>
                <div className="kmdInfo_cardsContainer">
                    {(policyType === "GMC"
                        ? coverageInfo
                        : policyType === "GPA"
                        ? gpaCoverageInfo
                        : gpiCoverageInfo
                    ).map(
                        (
                            info: {
                                description: string;
                                imageUrl: string;
                                popup?: string;
                            },
                            index
                        ) => (
                            <div
                                className={`kmdInfo_card ${
                                    info.popup && "pointer"
                                }`}
                                key={index}
                                onClick={() => {
                                    if (info.popup) {
                                        setModalText({
                                            header: info.description,
                                            content: info.popup,
                                        });
                                        setOpen(true);
                                    }
                                }}
                            >
                                <div className="kmdInfo_cardImage mr20">
                                    <img src={info.imageUrl} alt="Card Image" />
                                </div>
                                <div className="kmdInfo_cardText">
                                    <p className="fontSize_16 fontWeight_500 fontColor_dark m0I">
                                        {info.description}
                                    </p>
                                </div>
                                <div className="kmdInfo_cardInfo">
                                    {info.popup && <InfoIcon />}
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div> */}

            {/* <div className="kmdInfo_form " id="kmdForm">
                <h1 className="fontSize_40 fontWeight_700 mb20 mt50">
                    {policyType === "GMC"
                        ? "Buy your Health Cover Today"
                        : policyType === "GPA"
                        ? "Buy your Personal Accident Cover Today"
                        : policyType === "GPI"
                        ? "Buy your Professional Indemnity Policy today"
                        : "Buy your cover today"}
                </h1>
                <div className="kmdInfo_formContainer">
                    <KmdStep1 submit={submit} />
                </div>
            </div> */}
            {/* <div className="kmdInfo_faqSection">
                <h1 className="fontSize_40 fontWeight_700 mb20 mt50">
                    FAQ & <span className="fontColor_primary">Benefits</span>
                </h1>
                <div className="kmdInfo_faqContainer">
                    {(policyType === "GMC"
                        ? faq
                        : policyType === "GPA"
                        ? gpaFaq
                        : gpiFaq
                    ).map((faq, index) => (
                        <div className="kmdInfo_faq" key={index}>
                            <p className="fontSize_16 fontWeight_600 mb0I pr10 pl10">
                                {faq}
                            </p>
                        </div>
                    ))}
                </div>
            </div> */}
            <hr />
            <p>
                {policyType === "GMC" && (
                    <i>
                        *Premium (Excluding GST @18%) for a 25-year old person
                        opting for an annual coverage for Rs. 10 lacs.
                    </i>
                )}
            </p>
            {/* <p>
                {policyType === "TOPUP" && (
                    <i>
                        *Premium (Excluding GST @18%) for a 25-year old person
                        opting for an annual coverage for Rs. 1 crore and dectible 10 lacs.
                    </i>
                )}
            </p> */}

            {open && (
                <GenericModalContainer
                    close={handleClose}
                    // headingText={member ? "Edit Dependents" : "Add Dependents"}
                    headingText={modalText.header}
                    styles={{ outline: "none", border: "none" }}
                >
                    {modalText.content}
                </GenericModalContainer>
            )}

            <Drawer
                anchor={isMobile ? "bottom" : "right"}
                // open={true}
                open={openWhatsCovered}
                onClose={() => setOpenWhatsCovered(false)}
                PaperProps={{
                    style: {
                        width: isMobile ? "100%" : 397,
                        ...(isMobile
                            ? { maxHeight: "90%" }
                            : { height: "100%" }),
                        borderTopLeftRadius: isMobile ? 0 : 24,
                        borderBottomLeftRadius: isMobile ? 0 : 24,
                        ...(isMobile
                            ? { borderRadius: "24px 24px 0px 0px" }
                            : {}),
                        paddingBlock: "24px",
                        paddingInline: "24px",
                    },
                }}
            >
                <Stack
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    width={"100%"}
                >
                    <Typography color={theme.palette.text.primary} variant="h5">
                        What's covered in this plan?
                    </Typography>
                    <Iconify
                        onClick={() => setOpenWhatsCovered(false)}
                        sx={{
                            marginLeft: "8px",
                            cursor: "pointer",
                        }}
                        icon={"material-symbols:close"}
                    />
                </Stack>
                <Divider
                    sx={{
                        my: "24px",

                        border: "1px dashed rgb(217, 215, 224)",
                    }}
                />
                <Stack rowGap="24px">
                    {(policyType === "GMC"
                        ? coverageInfo
                        : policyType === "GPA"
                        ? gpaCoverageInfo
                        : policyType === "TOPUP"
                        ? coverageInfoTopUp
                        : gpiCoverageInfo
                    ).map(
                        (
                            info: {
                                description: string;
                                imageUrl: string;
                                popup?: string;
                            },
                            index
                        ) => (
                            <Stack
                                className={info.popup && "pointer"}
                                key={index}
                                onClick={() => {
                                    if (info.popup) {
                                        setModalText({
                                            header: info.description,
                                            content: info.popup,
                                        });
                                        setOpen(true);
                                    }
                                }}
                                direction="row"
                                gap="16px"
                            >
                                <Box
                                    sx={{
                                        width: "20px",
                                    }}
                                >
                                    <Iconify
                                        icon={info.imageUrl}
                                        color="#05C168"
                                    />
                                </Box>
                                <Typography variant="subtitle2">
                                    {info.description}
                                </Typography>
                                <div className="kmdInfo_cardInfo">
                                    {info.popup && <InfoIcon />}
                                </div>
                            </Stack>
                        )
                    )}
                </Stack>
            </Drawer>

            <Drawer
                anchor={isMobile ? "bottom" : "right"}
                // open={true}
                open={openFAQDrawer}
                onClose={() => setOpenFAQDrawer(false)}
                PaperProps={{
                    style: {
                        width: isMobile ? "100%" : 397,
                        ...(isMobile
                            ? { maxHeight: "90%" }
                            : { height: "100%" }),
                        borderTopLeftRadius: isMobile ? 0 : 24,
                        borderBottomLeftRadius: isMobile ? 0 : 24,
                        ...(isMobile
                            ? { borderRadius: "24px 24px 0px 0px" }
                            : {}),
                        paddingBlock: "24px",
                        paddingInline: "24px",
                    },
                }}
            >
                <Stack
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    width={"100%"}
                >
                    <Typography color={theme.palette.text.primary} variant="h5">
                        Benefits of getting this plan
                    </Typography>
                    <Iconify
                        onClick={() => setOpenFAQDrawer(false)}
                        sx={{
                            marginLeft: "8px",
                            cursor: "pointer",
                        }}
                        icon={"material-symbols:close"}
                    />
                </Stack>
                <Divider
                    sx={{
                        my: "24px",

                        border: "1px dashed rgb(217, 215, 224)",
                    }}
                />
                <Stack rowGap="24px">
                    {(policyType === "GMC"
                        ? faq
                        : policyType === "GPA"
                        ? gpaFaq
                        : policyType === "TOPUP"
                        ? topupFaq
                        : gpiFaq
                    ).map((faq, index) => (
                        <Stack
                            key={index}
                            direction="row"
                            gap="16px"
                            alignItems="center"
                        >
                            <Box
                                sx={{
                                    width: "20px",
                                }}
                            >
                                <Iconify
                                    icon="material-symbols:check"
                                    color="#05C168"
                                />
                            </Box>
                            <Typography variant="subtitle2">{faq}</Typography>
                        </Stack>
                    ))}
                </Stack>
            </Drawer>
        </div>
    );
};

export default KmdInfo;
