import { useEffect } from "react";
import { useStateValue } from "../../../util/stateProvider";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setHomeDto, setPolicyDto } from "../../../features/home/homeSlice";
import { LOGOUT } from "../../../util/newconfig";
import advService from "../../../util/advService";
import { QueryClient, useQueryClient } from "react-query";
import { getVariantData } from "../../../util/functions";

const Logout = () => {
    const navigate = useNavigate();
    const [, dispatch] = useStateValue();
    const reducerDispatch = useDispatch();
    const queryClient = useQueryClient();
    useEffect(() => {
        if (
            JSON.parse(sessionStorage.getItem("subDomainInfo"))
                ?.idealSessionTimeout
        ) {
            advService(LOGOUT, {}, (res) => {
                if (res?.data.success) {
                    console.log("Logout success");
                    dispatch({
                        type: "reset-credentials",
                    });

                    window?.ReactNativeWebView?.postMessage(
                        JSON.stringify({
                            tag: "syncApp",
                            user: null,
                            token: null,
                        })
                    );

                    // if (window.botpressWebChat) {
                    //     window.botpressWebChat.sendEvent({ type: "hide" });
                    // }
                    reducerDispatch(setHomeDto(null));
                    reducerDispatch(setPolicyDto(null));

                    queryClient.invalidateQueries("homeDataV2");
                    getVariantData(
                        {
                            kmd: navigate("/architect"),
                            imt: navigate("/login"),
                        }
                    );
                }
            });
        } else {
            dispatch({
                type: "reset-credentials",
            });

            window?.ReactNativeWebView?.postMessage(
                JSON.stringify({
                    tag: "syncApp",
                    user: null,
                    token: null,
                })
            );

            // if (window.botpressWebChat) {
            //     window.botpressWebChat.sendEvent({ type: "hide" });
            // }
            reducerDispatch(setHomeDto(null));
            reducerDispatch(setPolicyDto(null));

            queryClient.invalidateQueries("homeDataV2");
            
            if (
                window.location.hostname === "kmdastur.imt.care" ||
                window.location.hostname === "insurance.kmdastur.com" ||
                window.location.hostname === "kmddemo.imt.care"
            ) {
                navigate("/architect");
            } else {
                // navigate("/login");
                window.location.href = window.location.origin + "/login";
            }
        }

        // window.location.href = window.location.origin + '/login'
    }, []);

    return null;
};

export default Logout;
