import React, { useEffect } from "react";
import {
    EnrollmentState,
    Family,
    Gender,
    Member,
    MemberWithState,
    MultiStepFormState,
} from "../../types";

// following are images for edit and delete
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import AddMemberForm from "./AddMemberFormV2";
import { NewMember } from "./types";
import { MemberValidation } from "../../types/DTO/enrollmentGetDTO";
import { changeDateFormat } from "../../util/date";
import AddIcon from "@mui/icons-material/Add";

import "./Members.scss";
import getMemberAvatar, {
    getMemberBackground,
} from "../../util/function/getMemberAvatar";
import alert from "../../util/alerts";
import { checkFamilyValidation } from "../../util/function/memberValidation";
import {
    Box,
    Divider,
    Grid,
    IconButton,
    Stack,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Iconify from "../iconify";
import getIcon from "../../util/function/getIcon";
import dayjs from "dayjs";

interface Props {
    members: MemberWithState[];
    family?: Family;
    setMembers: (members: MemberWithState[]) => void;
    setMembersError: (membersError: string[]) => void;
    validation: MemberValidation;
    askRelationShip?: boolean;
    readOnly?: boolean;
    employeeReadOnly?: boolean;
    employeeReadOnlyMessage?: string;
    editOnly?: boolean;
    setShowAddButton?: (show: boolean) => void;
    fullWidth?: boolean;
}

const Members: React.FC<Props> = ({
    members,
    family,
    setMembers,
    setMembersError,
    validation,
    askRelationShip = true,
    readOnly = false,
    employeeReadOnly = false,
    employeeReadOnlyMessage = "",
    editOnly = false,
    setShowAddButton,
    fullWidth,
}: Props) => {
    const [selectedMember, setSelectedMember] =
        React.useState<MemberWithState | null>(null);
    const [showAddMemberForm, setShowAddMemberForm] =
        React.useState<boolean>(false);

    const [familyLimit, setFamilyLimit] = React.useState<{
        numberOfParent: number;
        numberOfChild: number;
        spouse: number;
        sibling: number;
    }>({
        numberOfParent: 0,
        numberOfChild: 0,
        spouse: 0,
        sibling: 0,
    });

    const theme = useTheme();

    useEffect(() => {
        if (family?.employee) {
            setFamilyLimit((prev) => {
                return {
                    ...prev,
                    numberOfParent: family.numberOfParent,
                    numberOfChild: family.numberOfChild,
                    spouse: family.spouse || family.liveInPartner ? 1 : 0,
                    sibling: family.sibling || 0,
                };
            });

            members.forEach((member) => {
                if (
                    member.relationShip === "Spouse" &&
                    member.state !== "remove"
                ) {
                    setFamilyLimit((prev) => ({
                        ...prev,
                        spouse: prev.spouse - 1,
                    }));
                } else if (
                    member.relationShip === "Child" &&
                    member.state !== "remove"
                ) {
                    setFamilyLimit((prev) => ({
                        ...prev,
                        numberOfChild: prev.numberOfChild - 1,
                    }));
                } else if (
                    member.relationShip === "Parent" &&
                    member.state !== "remove"
                ) {
                    setFamilyLimit((prev) => ({
                        ...prev,
                        numberOfParent: prev.numberOfParent - 1,
                    }));
                } else if (
                    member.relationShip === "ParentInLaw" &&
                    member.state !== "remove"
                ) {
                    setFamilyLimit((prev) => ({
                        ...prev,
                        numberOfParent: prev.numberOfParent - 1,
                    }));
                } else if (
                    member.relationShip === "Sibling" &&
                    member.state !== "remove"
                ) {
                    setFamilyLimit((prev) => ({
                        ...prev,
                        sibling: prev.sibling - 1,
                    }));
                }
            });
        }
    }, [family]);

    useEffect(() => {
        if (
            Object.values(familyLimit).every(
                (value: number | undefined) => value !== undefined && value <= 0
            )
        ) {
            if (setShowAddButton) {
                setShowAddButton(false);
            }
        } else {
            if (setShowAddButton) {
                setShowAddButton(true);
            }
        }
    }, [familyLimit]);

    const updateFamilyLimit = (relationShip: string, operation: string) => {
        if (operation === "add") {
            switch (relationShip) {
                case "Spouse":
                    if (familyLimit.spouse > 0) {
                        setFamilyLimit((prev) => ({
                            ...prev,
                            spouse: prev.spouse - 1,
                        }));
                    }
                    break;
                case "Child":
                    if (familyLimit.numberOfChild > 0) {
                        setFamilyLimit((prev) => ({
                            ...prev,
                            numberOfChild: prev.numberOfChild - 1,
                        }));
                    }

                    break;
                case "Parent":
                case "ParentInLaw":
                    if (familyLimit.numberOfParent > 0) {
                        setFamilyLimit((prev) => ({
                            ...prev,
                            numberOfParent: prev.numberOfParent - 1,
                        }));
                    }
                    break;
                case "Sibling":
                    if (familyLimit.sibling > 0) {
                        setFamilyLimit((prev) => ({
                            ...prev,
                            sibling: prev.sibling - 1,
                        }));
                    }
                    break;
                default:
                    break;
            }
        } else {
            switch (relationShip) {
                case "Spouse":
                    setFamilyLimit((prev) => ({
                        ...prev,
                        spouse: prev.spouse + 1,
                    }));
                    break;
                case "Child":
                    setFamilyLimit((prev) => ({
                        ...prev,
                        numberOfChild: prev.numberOfChild + 1,
                    }));
                    break;
                case "Parent":
                case "ParentInLaw":
                    setFamilyLimit((prev) => ({
                        ...prev,
                        numberOfParent: prev.numberOfParent + 1,
                    }));
                    break;
                case "Sibling":
                    setFamilyLimit((prev) => ({
                        ...prev,
                        sibling: prev.sibling + 1,
                    }));
                    break;
                default:
                    break;
            }
        }
    };

    useEffect(() => {
        setMembersError([
            ...(checkFamilyValidation(family || null, members).length > 0
                ? checkFamilyValidation(family || null, members)
                : []),
            ...(validateMembersAge().length > 0 ? validateMembersAge() : []),
            ...(validateSpouseGender() ? [validateSpouseGender() || ""] : []),
            ...(checkDuplicateMembers(members)
                ? ["Duplicate members are not allowed"]
                : []),
        ]);
    }, [members]);

    // find relation ship using relationship and gender
    const getRelationShip = (
        member: MemberWithState | Member,
        family: Family | null
    ) => {
        switch (member.relationShip) {
            case "Spouse":
                return family?.liveInPartner &&
                    member.displayRelationship === "LiveInPartner"
                    ? "LiveInPartner"
                    : member.gender === "Male"
                    ? "Husband"
                    : "Wife";
            case "Child":
                return member.gender === "Male" ? "Son" : "Daughter";
            case "Parent":
                return member.gender === "Male" ? "Father" : "Mother";
            case "ParentInLaw":
                return member.gender === "Male" ? "FatherInLaw" : "MotherInLaw";
            case "Sibling":
                return member.gender === "Male" ? "Brother" : "Sister";
            default:
                return "Self";
        }
    };

    // Add member function
    const addMember = (member: NewMember) => {
        updateFamilyLimit(member.relationShip, "add");
        setMembers([
            ...members,
            {
                ...member,
                state: "add",
                displayRelationship: getRelationShip(member, family || null),
            },
        ]);
    };

    // Edit member function
    const editMember = (member: MemberWithState, index: number) => {
        setMembers(
            members.map((m, i) => {
                if (i === index) {
                    return {
                        ...m,
                        ...member,
                        state: member.state === "add" ? "add" : "update",
                        displayRelationship: getRelationShip(
                            member,
                            family || null
                        ),
                    };
                }
                return m;
            })
        );
    };

    // Delete member function
    const deleteMember = (member: MemberWithState, index: number) => {
        updateFamilyLimit(member.relationShip, "remove");
        setMembers(
            members.map((m, i) => {
                if (i === index) {
                    return {
                        ...m,
                        state: "remove",
                    };
                }
                return m;
            })
        );
    };

    // function for validating members dob
    const validateMembersAge = () => {
        const validations = validation;

        let errors: string[] = [];

        let employeeJsDob = changeDateFormat(
            "yyyy-mm-dd",
            "javascriptDateObject",
            members.find((member) => member.relationShip === "Employee")?.dob ||
                ""
        ) as Date;

        members.forEach((member) => {
            // use valitaion used in AddMemberForm
            let jsDob = changeDateFormat(
                "yyyy-mm-dd",
                "javascriptDateObject",
                member.dob
            ) as Date;

            if (member.state !== "remove") {
                if (jsDob > new Date()) {
                    errors.push("Date of birth can't be in future");
                }
                if (
                    member.relationShip === "Spouse" &&
                    validations.Spouse &&
                    (new Date().getFullYear() - jsDob.getFullYear() <
                        validations.Spouse?.minAge ||
                        new Date().getFullYear() - jsDob.getFullYear() >
                            validations.Spouse?.maxAge)
                ) {
                    errors.push(
                        `Spouse's age must be between ${validations.Spouse?.minAge} and ${validations.Spouse?.maxAge}`
                    );
                }
                if (
                    member.relationShip === "Child" &&
                    validations.Child &&
                    (new Date().getFullYear() - jsDob.getFullYear() <
                        validations.Child?.minAge ||
                        new Date().getFullYear() - jsDob.getFullYear() >
                            validations.Child?.maxAge)
                ) {
                    errors.push(
                        `Child's age must be between ${validations.Child?.minAge} and ${validations.Child?.maxAge}`
                    );
                }
                if (
                    member.relationShip === "Child" &&
                    validations.EmployeeChild &&
                    (jsDob.getFullYear() - employeeJsDob.getFullYear() >
                        validations.EmployeeChild?.maxAge ||
                        jsDob.getFullYear() - employeeJsDob.getFullYear() <=
                            validations.EmployeeChild?.minAge)
                ) {
                    errors.push(
                        `Child's age must be between ${validations.EmployeeChild?.minAge} and ${validations.EmployeeChild?.maxAge} of employee`
                    );
                }
                if (
                    member.relationShip === "Parent" &&
                    validations.Parent &&
                    (new Date().getFullYear() - jsDob.getFullYear() <
                        validations.Parent?.minAge ||
                        new Date().getFullYear() - jsDob.getFullYear() >
                            validations.Parent?.maxAge)
                ) {
                    errors.push(
                        `Parent's age must be between ${validations.Parent?.minAge} and ${validations.Parent?.maxAge}`
                    );
                }
                if (
                    (member.relationShip === "Parent" ||
                        member.relationShip === "ParentInLaw") &&
                    validations.EmployeeParent &&
                    (employeeJsDob.getFullYear() - jsDob.getFullYear() >
                        validations.EmployeeParent?.maxAge ||
                        employeeJsDob.getFullYear() - jsDob.getFullYear() <
                            validations.EmployeeParent?.minAge)
                ) {
                    errors.push(
                        `Parent's age must be between ${validations.EmployeeParent?.minAge} and ${validations.EmployeeParent?.maxAge} of employee`
                    );
                }
                if (
                    member.relationShip === "Sibling" &&
                    validations.Employee &&
                    (dayjs().diff(jsDob, "year") <
                        validations.Employee.minAge ||
                        dayjs().diff(jsDob, "year") >
                            validations.Employee.maxAge)
                ) {
                    errors.push(
                        `Sibling's age must be between ${validations.Employee.minAge} and ${validations.Employee.maxAge}`
                    );
                }
            }
        });
        return errors;
    };

    const validateSpouseGender = () => {
        const employee = members.find(
            (member) => member.relationShip === "Employee"
        );

        const spouse = members.find(
            (member) =>
                member.relationShip === "Spouse" && member.state !== "remove"
        );

        if (employee?.gender === spouse?.gender) {
            return "Employee and spouse can't be of same gender";
        }

        return false;
    };

    /**
     * @description function to check whether two members with same name and dob are present
     * @param {MemberWithState[]} members - array of members
     * @returns {boolean} - returns true if two members with same name and dob are present
     */
    const checkDuplicateMembers = (members: MemberWithState[]) => {
        let duplicateMembers = false;
        members.forEach((member, index) => {
            members.forEach((m, i) => {
                if (
                    member.state !== "remove" &&
                    m.state !== "remove" &&
                    member.name === m.name &&
                    member.dob === m.dob &&
                    index !== i
                ) {
                    duplicateMembers = true;
                }
            });
        });
        return duplicateMembers;
    };

    // Submit function
    const handleSubmit = (
        member: NewMember | MemberWithState,
        index: number
    ) => {
        if (selectedMember) {
            editMember(member as MemberWithState, index);
        } else {
            addMember(member as NewMember);
        }
        setSelectedMember(null);
        setShowAddMemberForm(false);
    };

    // open add member form
    const handleAddMemberClick = () => {
        setSelectedMember(null);
        setShowAddMemberForm(true);
    };

    return (
        <Box
            sx={{
                borderRadius: "24px",
                border: {
                    xs: "1px solid var(--components-paper-outlined, rgba(145, 158, 171, 0.16))",
                    md: "none",
                },
            }}
        >
            <Grid
                container
                columnSpacing="24px"
                sx={{
                    padding: {
                        md: "24px 20px",
                        xs: "20px",
                    },
                }}
            >
                {/* list of members */}
                {members.map(
                    (member, index) =>
                        member.state !== "remove" && (
                            <Grid
                                item
                                xs={12}
                                sm={fullWidth ? 12 : 6}
                                key={index}
                            >
                                <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    columnGap={1}
                                >
                                    <Box
                                        height={40}
                                        width={40}
                                        borderRadius={"37px"}
                                        sx={{
                                            backgroundColor:
                                                getMemberBackground(member),
                                            padding: "4px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <img
                                            src={getMemberAvatar(member)}
                                            alt="member"
                                            // className="memberComponent_memberImage"
                                            style={{
                                                objectFit: "cover",
                                                maxWidth: "100%",
                                                height: "100%",
                                                borderRadius: "50%",
                                            }}
                                        />
                                    </Box>
                                    <Stack flex={1}>
                                        <Typography variant="subtitle2">
                                            {member.name}
                                        </Typography>
                                        <Stack
                                            direction={"row"}
                                            alignItems={"center"}
                                        >
                                            <Typography
                                                variant="body3"
                                                color={
                                                    theme.palette.text.secondary
                                                }
                                            >
                                                {getRelationShip(
                                                    member,
                                                    family || null
                                                )}
                                            </Typography>
                                            {/* create a big dot */}
                                            <span
                                                style={{
                                                    display: "inline-block",
                                                    width: "5px",
                                                    height: "5px",
                                                    borderRadius: "50%",
                                                    backgroundColor: "#D3D3D3",
                                                }}
                                                className="mr5 ml5"
                                            ></span>
                                            <Typography
                                                variant="body3"
                                                color={
                                                    theme.palette.text.secondary
                                                }
                                            >
                                                {member.dobText ||
                                                    (changeDateFormat(
                                                        "yyyy-mm-dd",
                                                        "dd-mmm-yyyy",
                                                        member.dob
                                                    ) as string)}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack
                                        direction={"row"}
                                        gap={2}
                                        height={28}
                                        alignItems={"center"}
                                    >
                                        {!readOnly &&
                                            (member.relationShip !==
                                                "Employee" ||
                                                (member.relationShip ===
                                                    "Employee" &&
                                                    !employeeReadOnly)) && (
                                                <Box
                                                    onClick={() => {
                                                        setSelectedMember(
                                                            member
                                                        );
                                                        setShowAddMemberForm(
                                                            true
                                                        );
                                                    }}
                                                    sx={{
                                                        border: "1px solid var(--Colors-Black-Enabled-Outline, #ECEFFF)",
                                                        borderRadius: "50%",
                                                        padding: "6px",
                                                        height: "36px",
                                                        width: "36px",
                                                        marginTop: "-8px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                            "center",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    <Iconify
                                                        icon="material-symbols:edit-outline"
                                                        color={"#0C1230"}
                                                    />
                                                </Box>
                                            )}
                                        {!readOnly &&
                                            member.relationShip ===
                                                "Employee" &&
                                            employeeReadOnly &&
                                            employeeReadOnlyMessage && (
                                                <Box
                                                    sx={{
                                                        border: "1px solid var(--Colors-Black-Enabled-Outline, #ECEFFF)",
                                                        borderRadius: "50%",
                                                        padding: "6px",
                                                        height: "36px",
                                                        width: "36px",
                                                        marginTop: "-8px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                            "center",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    <Tooltip
                                                        title={
                                                            employeeReadOnlyMessage
                                                        }
                                                    >
                                                        <InfoIcon />
                                                    </Tooltip>
                                                </Box>
                                            )}
                                        {
                                            // not for employee
                                            member.relationShip !==
                                                "Employee" &&
                                                !member.enrolmentLock &&
                                                !readOnly && (
                                                    <Box
                                                        onClick={() =>
                                                            deleteMember(
                                                                member,
                                                                index
                                                            )
                                                        }
                                                        sx={{
                                                            border: "1px solid var(--Colors-Black-Enabled-Outline, #ECEFFF)",
                                                            borderRadius: "50%",
                                                            padding: "6px",
                                                            height: "36px",
                                                            width: "36px",
                                                            marginTop: "-8px",
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "center",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <Iconify
                                                            icon={"mdi:minus"}
                                                            color={"#0C1230"}
                                                        />
                                                    </Box>
                                                )
                                        }
                                    </Stack>
                                </Stack>

                                <Divider
                                    variant="dashed"
                                    sx={{
                                        my: {
                                            md: 3,
                                            xs: 2,
                                        },
                                        borderColor: "grey.400",
                                        opacity: 0.3,
                                    }}
                                />
                            </Grid>
                        )
                )}

                {/* add member button */}
                {!readOnly && !editOnly && (
                    <Grid item xs={12} sm={fullWidth ? 12 : 6}>
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            columnGap={1}
                        >
                            <Box
                                height={40}
                                width={40}
                                borderRadius={"37px"}
                                sx={{
                                    backgroundColor: "#f8f7fa",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    padding: "4px",
                                }}
                            >
                                {getIcon("user" as any)}
                            </Box>
                            <Typography variant="subtitle2" flex={1}>
                                Add dependents
                            </Typography>
                            <Box
                                onClick={handleAddMemberClick}
                                sx={{
                                    border: `1px solid ${theme.palette.brand.main}`,
                                    borderRadius: "50%",
                                    padding: "6px",
                                    height: "36px",
                                    width: "36px",
                                    marginTop: "-8px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                }}
                            >
                                <Iconify
                                    icon={"material-symbols:add"}
                                    color={theme.palette.brand.main}
                                />
                            </Box>
                        </Stack>
                    </Grid>
                )}

                {showAddMemberForm && (
                    <AddMemberForm
                        member={selectedMember}
                        members={members}
                        family={family}
                        index={
                            selectedMember
                                ? members.indexOf(selectedMember)
                                : -1
                        }
                        memberValidation={validation}
                        askRelationShip={askRelationShip}
                        onSubmit={handleSubmit}
                        close={() => {
                            setSelectedMember(null);
                            setShowAddMemberForm(false);
                        }}
                        // onCancel={() => {setSelectedMember(null); setShowAddMemberForm(false)}}
                    />
                )}
            </Grid>
        </Box>
    );
};

export default Members;
