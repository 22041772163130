import React, { useState } from "react";
import Box from "@mui/material/Box";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Drawer,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import Iconify from "../../components/iconify/iconify";
import currencyFormater from "../../util/function/currencyFormater";

const CoverageCard = ({
    coverageName,
    coverageAmount,
    coverageDescription,
    color = "success",
    icon,
    DialogBoxContent,
    richText,
    label,
}: {
    coverageName: string;
    coverageAmount?: number | string;
    coverageDescription: string;
    color?: "success" | "error" | "warning" | "info";
    icon: string;
    DialogBoxContent?: string;
    richText: boolean;
    label: string;
}) => {
    const theme = useTheme();

    const [open, setOpen] = useState(false);

    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Box
            sx={{
                display: "flex",
                width: { sm: "341px", xs: "100%" },
                // minHeight: "110px",
                height: "100%",
                alignItems: "flex-start",
                gap: "8px",
            }}
        >
            <Iconify
                icon={icon}
                width={"24px"}
                height={"24px"}
                color={(theme) => theme.palette[color].main}
            />
            {/* <img
                src={icon}
                alt="icon"
                width={24}
                height={24}
                style={{
                    color: theme.palette[color].main,
                }}
            /> */}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "4px",
                    flex: "1 0 0",
                    alignSelf: "stretch",
                    height: { xs: "fit-content", sm: "100%" },
                }}
            >
                <Stack flexDirection={"row"} alignItems={"center"} gap={"8px"}>
                    <Typography
                        variant="subtitle2"
                        color={(theme) => theme.palette.text.primary}
                    >
                        {coverageName}
                    </Typography>

                    {coverageAmount && (
                        <>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="6"
                                height="6"
                                viewBox="0 0 6 6"
                                fill="none"
                            >
                                <circle cx="3" cy="3" r="3" fill="#ECEFFF" />
                            </svg>
                            <Typography
                                variant="subtitle2"
                                color={(theme) => theme.palette.brand.main}
                            >
                                {label === "sumInsured" ? (
                                    <>
                                        {isNaN(Number(coverageAmount)) ? (
                                            coverageAmount
                                        ) : (
                                            <>
                                                <span className="rupee">₹</span>
                                                {currencyFormater(
                                                    parseInt(
                                                        coverageAmount as string
                                                    )
                                                )}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    coverageAmount
                                )}
                            </Typography>
                        </>
                    )}
                </Stack>

                {richText ? (
                    <Typography
                        variant="body3"
                        sx={{
                            wordBreak: "break-word",
                            "& p": {
                                fontFamily: "Poppins",
                                fontSize: 12,
                                fontStyle: "normal",
                                fontWeight: 400,
                                color: "#464D6A",
                                margin: 0,

                                lineHeight: "18px",
                                letterSpacing: -0.12,
                            },
                            a: {
                                color: theme.palette.brand.main,
                                textDecoration: "underline",
                            },
                        }}
                        dangerouslySetInnerHTML={{
                            __html: coverageDescription,
                        }}
                    />
                ) : (
                    <Typography
                        variant="body3"
                        sx={{ wordBreak: "break-word" }}
                    >
                        {coverageDescription}
                    </Typography>
                )}
                {DialogBoxContent && (
                    <>
                        <Typography
                            variant="overline"
                            color={theme.palette.brand.main}
                            sx={{
                                cursor: "pointer",
                                textDecoration: "underline",
                            }}
                            onClick={handleClickOpen}
                        >
                            Show Details
                        </Typography>

                        <Drawer
                            open={open}
                            onClose={handleClose}
                            anchor={isMobile ? "bottom" : "right"}
                            sx={{
                                "& .MuiDrawer-paper": {
                                    padding: "24px 20px;",
                                    display: "flex",
                                    width: isMobile ? "100%" : "375px",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    flexShrink: 0,
                                    borderRadius: {
                                        xs: "24px 24px 0px 0px",
                                        sm: "24px 0px 0px 24px",
                                    },
                                    backgroundColor: "#FFF",
                                },
                            }}
                        >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Disease Name</TableCell>
                                        <TableCell>Amount</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.entries(DialogBoxContent).map(
                                        ([key, value]) => (
                                            <TableRow key={key}>
                                                <TableCell>{key}</TableCell>
                                                <TableCell>{value}</TableCell>
                                            </TableRow>
                                        )
                                    )}
                                </TableBody>
                            </Table>
                        </Drawer>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default CoverageCard;
