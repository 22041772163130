import React, { useEffect } from "react";
import KmdInvoice from "./kmdInvoice";
import "./kmdStep2.scss";
import "./kmdStep4.scss";
import { Box, Chip, Grid, IconButton, Stack, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { changeDateFormat } from "../../../util/date";
import advService from "../../../util/advService";
import { KMD_CREATE_PROPOSAL_POST } from "../../../util/newconfig";
import { KmdProposalDTO } from "../../../types/DTO/kmdDto";
import { AxiosResponse } from "axios";
import { JavaResponse } from "../../../types";
import KmdPiComplete from "../kmdPiComplete";
import { useQueryClient } from "react-query";
import V2Container from "../../../components/Layout/V2Container";

const bannerImage =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/Super_TopUp/superIllustration.png";

interface Props {
    setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
    logId: string;
}

const KmdGpiStep4: React.FC<Props> = ({ setCurrentStep, logId }) => {
    const {
        members,
        memberInfo,
        sumInsured,
        plan,
        sumInsuredList,
        planList,
        policyType,
        clientUuid,
        premium,
        ckyc,
        gpi,
        masterUuid,
        comment,
        limitOfliability,
        planDb,
        adressRequired,
        isKyc,
    } = useSelector((state: RootState) => state.kmd);
    const reducerDispatch = useDispatch();
    const queryClient = useQueryClient();

    const [status, setStatus] = React.useState(false);
    const [loader, setLoader] = React.useState(false);

    useEffect(() => {
        document.body.style.backgroundColor = "white";
        return () => {
            document.body.style.backgroundColor = "#f6f6f6";
        };
    }, []);

    const submitForm = () => {
        setLoader(true);
        advService(
            { ...KMD_CREATE_PROPOSAL_POST },
            {
                policyEmployeeMemberDTOS: members.map((member) => {
                    return {
                        ...(member.relationShip === "Employee"
                            ? {}
                            : {
                                  id: member.id,
                              }),
                        relationShip: member.relationShip,
                        gender: member.gender,
                        name: member.name,
                        dob: changeDateFormat(
                            "javascriptDateObject",
                            "yyyy-mm-dd",
                            new Date(member.dob)
                        ),
                        // sumInsured: sumInsured,
                        // deductible: 0,
                        uuid: member.uuid,
                        active: member.active,
                        memberId: member.memberId,
                    };
                }),
                nominations: [
                    {
                        name: memberInfo.nomineeName,
                        relationship: memberInfo.nomineeRelation,
                        active: true,
                        ...(policyType === "GMC" && {
                            dob: changeDateFormat(
                                "javascriptDateObject",
                                "yyyy-mm-dd",
                                new Date(memberInfo.nomineeDob)
                            ),
                        }),
                    },
                ],
                proposerDTO: {
                    addressDTO: {
                        ...(adressRequired && {
                            addressLine1: memberInfo.addressLine1,
                            addressLine2: memberInfo.addressLine2,

                            pincode: memberInfo.pincode,
                            city: memberInfo.city,
                            state: null,
                        }),
                        uuid: memberInfo.addressUuid,
                        active: true,
                    },
                    name: memberInfo.name,
                    dob: changeDateFormat(
                        "javascriptDateObject",
                        "yyyy-mm-dd",
                        new Date(
                            members.find(
                                (member) =>
                                    member.relationShip === "Employee" ||
                                    member.relationShip === "Self"
                            )?.dob || ""
                        )
                    ),
                    gender: members.find(
                        (member) =>
                            member.relationShip === "Employee" ||
                            member.relationShip === "Self"
                    )?.gender,
                    relationship: members.find(
                        (member) =>
                            member.relationShip === "Employee" ||
                            member.relationShip === "Self"
                    )?.relationShip,
                    clientUuid: clientUuid,
                    phone: memberInfo.phone,
                    email: "",
                },
                masterPolicyUuid: masterUuid,
                masterPolicyNodeApiDTO: {
                    sumInsured: sumInsured,
                    premium: Math.ceil(premium * 1.18),
                    deductible: null,
                    planName: plan,
                    // planCode: "",
                    salary: 0,
                    // startDate: "",
                    // endDate: "",
                    // policyUuid: "",
                },
                nomination: 1,
                isAddressReadOnly: false,
                // insurer: "FUTURE",
                // transactionId: "cd18ad2f-9325-4c45-bccc-d5db4085903f",
                // logId: "someuuidId",
                // paymentReturnUrl: "https://app.imt.care/kmd/status/{policyUuid}",
                params: {
                    ckyc: ckyc,
                },
                logId: logId,
                piDTO: {
                    isClaim: gpi.isClaim,
                    natureOfClaim: gpi.natureOfClaim,
                    loss: gpi.loss,
                    currentStatus: gpi.currentStatus,
                    remedialMeasures: gpi.remedialMeasures,
                    saleInIndia: gpi.saleInIndia,
                    saleInUsCa: gpi.saleInUsCa,
                    saleInEuAu: gpi.saleInEuAu,
                    salesInOthers: gpi.salesInOthers,
                    totalSales:
                        gpi.saleInIndia +
                        gpi.saleInUsCa +
                        gpi.saleInEuAu +
                        gpi.salesInOthers,
                    // premium: premium, // for node
                    // limitOfLiability: planDb.find((pdb) => pdb.name === plan)
                    //     ?.sumInsured, // for node
                    // deductibleIndia: parseInt(
                    //     comment?.split(" ")![3].replace(/,/g, "") || "0"
                    // ), // for node
                    // deductibleWorldWide: parseInt(
                    //     comment?.split(" ")![7].replace(/,/g, "") || "0"
                    // ), //for node
                },
            } as KmdProposalDTO,
            (res: AxiosResponse<JavaResponse<{ link: string }>>) => {
                setLoader(false);
                if (res.data.success) {
                    if (res.data.response.link) {
                        window.location.href = res.data.response.link;
                    } else {
                        queryClient.invalidateQueries("homeData");
                        setStatus(true);
                    }
                }
            }
        );
    };

    if (status) {
        return <KmdPiComplete />;
    }

    return (
        <V2Container
            heading="Review & Pay"
            onBack={() => {
                if (isKyc || policyType === "TOPUP") {
                    setCurrentStep(3);
                } else {
                    setCurrentStep(2);
                }
            }}
            sx={{
                padding: {
                    xs: "0px",
                    sm: "24px",
                },
            }}
        >
            <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing="24px"
                justifyContent="space-between"
            >
                <Box
                    sx={{
                        padding: "20px",
                    }}
                >
                    <Box
                        sx={{
                            width: {
                                xs: "100%",
                                sm: "347px",
                            },
                        }}
                    >
                        <div className="kmdStep3_review">
                            <Box 
                            >
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Typography variant="subtitle1">
                                        Details
                                    </Typography>
                                    <Chip
                                        label="Change"
                                        variant="outlined"
                                        onClick={() => {
                                            setCurrentStep(2);
                                        }}
                                    />
                                </Stack>
                                <Box
                                    sx={{
                                        marginTop: "20px",
                                    }}
                                >

                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="subtitle2">
                                            Sum Insured
                                        </Typography>
                                        <Typography variant="body1">
                                            {plan || sumInsured}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="subtitle2">
                                            Are there any Claims
                                        </Typography>
                                        <Typography variant="body1">
                                            {gpi.isClaim ? "Yes" : "No"}
                                        </Typography>
                                    </Grid>
                                    {gpi.isClaim && (
                                        <>
                                            <Grid item xs={12} md={6}>
                                                <Typography variant="subtitle2">
                                                    Nature of Claims
                                                </Typography>
                                                <Typography variant="body1">
                                                    {gpi.natureOfClaim}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Typography variant="subtitle2">
                                                    Amount of Loss
                                                </Typography>
                                                <Typography variant="body1">
                                                    <span className="rupee">
                                                        ₹
                                                    </span>{" "}
                                                    {gpi.loss.toLocaleString(
                                                        "en-IN"
                                                    )}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Typography variant="subtitle2">
                                                    Current Status
                                                </Typography>
                                                <Typography variant="body1">
                                                    {gpi.currentStatus}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Typography variant="subtitle2">
                                                    Remedial Measures
                                                </Typography>
                                                <Typography variant="body1">
                                                    {gpi.remedialMeasures}
                                                </Typography>
                                            </Grid>
                                        </>
                                    )}

                                    <Grid item xs={12} md={6}>
                                        <Typography variant="subtitle2">
                                            Domestic Turnover
                                        </Typography>
                                        <Typography variant="body1">
                                            <span className="rupee">₹</span>{" "}
                                            {gpi.saleInIndia.toLocaleString(
                                                "en-IN"
                                            )}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="subtitle2">
                                            US/CA Turnover
                                        </Typography>
                                        <Typography variant="body1">
                                            <span className="rupee">₹</span>{" "}
                                            {gpi.saleInUsCa.toLocaleString(
                                                "en-IN"
                                            )}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="subtitle2">
                                            EU/AU Turnover
                                        </Typography>
                                        <Typography variant="body1">
                                            <span className="rupee">₹</span>{" "}
                                            {gpi.saleInEuAu.toLocaleString(
                                                "en-IN"
                                            )}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="subtitle2">
                                            Rest Turnover
                                        </Typography>
                                        <Typography variant="body1">
                                            <span className="rupee">₹</span>{" "}
                                            {gpi.salesInOthers.toLocaleString(
                                                "en-IN"
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                </Box>
                            </Box>
                        </div>
                        {/* <button
                        className="buttonPrimary mobileOnly mt15 W100"
                        onClick={submitForm}
                    >
                        Next
                    </button> */}
                    </Box>
                </Box>
                <Box
                    sx={{
                        flex: 1,
                    }}
                >
                    <KmdInvoice
                        submitForm={submitForm}
                        key="121321"
                        loader={loader}
                    />
                </Box>
            </Stack>
        </V2Container>
    );
};

export default KmdGpiStep4;
