import React, { useEffect } from "react";
import KmdInvoice from "./kmdInvoice";
import "./kmdStep2.scss";
import { Form, Formik, FormikProps, useFormikContext } from "formik";
import * as Yup from "yup";
import MyText from "../../../components/Input/MyText";
import Dropdown from "../../../components/Input/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
    Box,
    Button,
    Divider,
    IconButton,
    Stack,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { changeDateFormat } from "../../../util/date";
import alert from "../../../util/alerts";
import Iconify from "../../../components/iconify";
import V2Container from "../../../components/Layout/V2Container";

const bannerImage =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/Super_TopUp/superIllustration.png";

const relationShipList = [
    "SPOUSE",
    "SON",
    "DAUGHTER",
    "FATHER",
    "MOTHER",
    "BROTHER",
    "SISTER",
    "GRAND FATHER",
    "GRAND MOTHER",
    "GRAND SON",
    "GRAND DAUGHTER",
    "FATHER IN LAW",
    "MOTHER IN LAW",
    "SISTER IN LAW",
    "BROTHER IN LAW",
    "SON IN LAW",
    "DAUGHTER IN LAW",
];

interface Props {
    submit: (val: {
        // email: string;
        name: string;
        dob: string;
        phone: string;
        addressLine1: string;
        addressLine2: string;
        pincode: string;
        nomineeName: string;
        nomineeRelation: string;
        addressUuid: string;
        city: string;
        nomineeDob: string;
    }) => void;
    setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}

const KmdStep2: React.FC<Props> = ({ submit, setCurrentStep }: Props) => {
    const theme = useTheme();
    const { memberInfo, members, adressRequired, policyType } = useSelector(
        (state: RootState) => state.kmd
    );
    const reducerDispatch = useDispatch();
    const formikRef = React.useRef<
        FormikProps<{
            email: string;
            phone: string;
            nomineeName: string;
            address: string;
            pincode: string;
        }>
    >(null);

    const [relationShip, setRelationShip] = React.useState<string>();

    useEffect(() => {
        document.body.style.backgroundColor = "white";
        return () => {
            document.body.style.backgroundColor = "#f6f6f6";
        };
    }, []);

    useEffect(() => {
        setRelationShip(memberInfo.nomineeRelation);
    }, []);

    const handleSubmit = (values: {
        phone: string;
        nomineeName: string;
        pincode: string;
        nomineeDob: string;
    }) => {
        console.log("submitting");
        if (!relationShip) {
            alert("Select nominee relationship", "error");
            return;
        }
        submit({
            name: memberInfo.name,
            dob: memberInfo.dob,
            phone: values.phone,
            addressLine1: memberInfo.addressLine1,
            addressLine2: memberInfo.addressLine2,
            pincode: values.pincode,
            nomineeName: values.nomineeName,
            nomineeRelation: relationShip,
            addressUuid: memberInfo.addressUuid,
            city: memberInfo.city,
            nomineeDob: values.nomineeDob,
        });
    };
    return (

            <V2Container heading="Personal Information" onBack={() => {
                setCurrentStep(1);
            }}
            sx={{
                padding: {
                    xs: "0px",
                    sm: "24px",
                },
            }}
            >
                
                    <Formik
                        ref={formikRef}
                        initialValues={{
                            name: members.find(
                                (member) =>
                                    member.relationShip === "Employee" ||
                                    member.relationShip === "Self"
                            )?.name,
                            dob: changeDateFormat(
                                "yyyy-mm-dd",
                                "dd-mmm-yyyy",
                                members.find(
                                    (member) =>
                                        member.relationShip === "Employee" ||
                                        member.relationShip === "Self"
                                )?.dob
                            ),
                            // email: memberInfo.email,
                            phone: memberInfo.phone,
                            nomineeName: memberInfo.nomineeName,
                            address:
                                memberInfo.addressLine1 +
                                " " +
                                memberInfo.addressLine2,
                            pincode: memberInfo.pincode,
                            nomineeDob: memberInfo.nomineeDob,
                        }}
                        validationSchema={Yup.object({
                            // phone: Yup.string()
                            //     .required("This field can't be empty")
                            //     .min(10)
                            //     .max(10)
                            //     .matches(
                            //         /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                            //         "Phone number is not valid"
                            //     )
                            //     .test(
                            //         "phone",
                            //         "Phonenumber is not valid",
                            //         (val) => {
                            //             return (val?.[0] ?? "") > "5";
                            //         }
                            //     ),
                            // email: Yup.string()
                            //     .email("Invalid email address")
                            //     .required("This field can't be empty"),
                            nomineeName: Yup.string()
                                .required("This field can't be empty")
                                .matches(/^[a-zA-Z_ ]*$/, "Invalid Name"),
                            // address: Yup.string()
                            //     .required("Address is required is required")
                            //     .test(
                            //         "len",
                            //         "Address too large",
                            //         (val) => (val?.length || 0) < 100
                            //     ),
                            // pincode: Yup.string()
                            //     .required("This field can't be empty")
                            //     .min(6)
                            //     .max(6),
                            ...(policyType === "GMC" && {
                                nomineeDob: Yup.string()
                                    .required("This field can't be empty")
                                    .test(
                                        "dob",
                                        "Nominee should be atleast 18 years old",
                                        (val) => {
                                            // check whether the age is above 18 or not
                                            let dob = new Date(val as string);
                                            let today = new Date();
                                            let age =
                                                today.getFullYear() -
                                                dob.getFullYear();
                                            let m =
                                                today.getMonth() -
                                                dob.getMonth();
                                            if (
                                                m < 0 ||
                                                (m === 0 &&
                                                    today.getDate() <
                                                        dob.getDate())
                                            ) {
                                                age--;
                                            }

                                            return age >= 18;
                                        }
                                    ),
                            }),
                        })}
                        onSubmit={handleSubmit}
                    >
                        <Box sx={{ width: "100%" }}>
                            <Stack
                                direction={{ xs: "column", sm: "row" }}
                                spacing="24px"
                                justifyContent="space-between"
                            >
                                <Box
                                    sx={{
                                        padding: "20px",
                                    }}
                                >
                                    <Form>
                                        <Box
                                            sx={{
                                                width: {
                                                    xs: "100%",
                                                    sm: "347px",
                                                },
                                            }}
                                        >
                                            <Stack spacing="10px">
                                                <Stack
                                                    direction={"row"}
                                                    justifyContent={
                                                        "space-between"
                                                    }
                                                >
                                                    <Typography variant="subtitle1">
                                                        Contact Information
                                                    </Typography>
                                                    <Tooltip
                                                        title="If you want to update your personal
                                            information, please get in touch
                                            with the Council of Architecture"
                                                    >
                                                        <Box
                                                            sx={{
                                                                background:
                                                                    theme
                                                                        .palette
                                                                        .grey[800],
                                                                borderRadius:
                                                                    "50%",
                                                                height: "28px",
                                                                width: "28px",
                                                                display: "flex",
                                                                justifyContent:
                                                                    "center",
                                                                alignItems:
                                                                    "center",
                                                            }}
                                                        >
                                                            <Iconify
                                                                icon="material-symbols:help-outline"
                                                                sx={{
                                                                    color: "white",
                                                                }}
                                                                height={20}
                                                                width={20}
                                                            />
                                                        </Box>
                                                    </Tooltip>
                                                </Stack>

                                                <MyText
                                                    label="Name"
                                                    name="name"
                                                    type="text"
                                                    placeholder="name"
                                                    className=""
                                                    readOnly={true}
                                                    onChange={() => {}}
                                                />
                                                <MyText
                                                    label="Date of Birth"
                                                    name="dob"
                                                    type="text"
                                                    placeholder="dob"
                                                    className=""
                                                    readOnly={true}
                                                    onChange={() => {}}
                                                />
                                                <MyText
                                                    label="Phone Number"
                                                    name="phone"
                                                    type="text"
                                                    className=""
                                                    readOnly={true}
                                                    placeholder="Phone Number"
                                                    onChange={() => {}}
                                                />
                                                {/* <h2 className="subHeading__Super ml-0">Your Address</h2> */}

                                                {adressRequired && (
                                                    <>
                                                        <Tooltip
                                                            title={
                                                                memberInfo.addressLine1 +
                                                                " " +
                                                                memberInfo.addressLine2
                                                            }
                                                        >
                                                            {/* <Button sx={{
                                                                padding: "0",
                                                                width: "100%",
                                                            }}> */}
                                                                <MyText
                                                                    label="Address"
                                                                    name="address"
                                                                    type="text"
                                                                    placeholder="Address"
                                                                    className=""
                                                                    readOnly={
                                                                        true
                                                                    }
                                                                    onChange={() => {}}
                                                                />
                                                            {/* </Button> */}
                                                        </Tooltip>

                                                        <MyText
                                                            label="Pincode"
                                                            name="pincode"
                                                            type="number"
                                                            placeholder="Pincode"
                                                            className=""
                                                            readOnly={true}
                                                            onChange={() => {}}
                                                        />
                                                    </>
                                                )}

                                                <Box
                                                    sx={{
                                                        padding: "22px 0",
                                                    }}
                                                >
                                                    <Divider
                                                        sx={{
                                                            border: "1px dashed rgb(217, 215, 224)",
                                                        }}
                                                    />
                                                </Box>

                                                <Typography variant="subtitle1">
                                                    Nominee Details
                                                </Typography>

                                                <MyText
                                                    label="Nominee Name"
                                                    name="nomineeName"
                                                    type="text"
                                                    placeholder="Name"
                                                    className=""
                                                    onChange={() => {}}
                                                />
                                                {policyType === "GMC" && (
                                                    <MyText
                                                        label="Nominee Dob"
                                                        name="nomineeDob"
                                                        type="date"
                                                        placeholder="Name"
                                                        className=""
                                                        onChange={() => {}}
                                                    />
                                                )}
                                                <div className="custom-form-group">
                                                    <Dropdown
                                                        title="Relationship"
                                                        name="nomineeRelationship"
                                                        className=" dropDownSuper colorUpdate"
                                                        defaultVal={
                                                            relationShip
                                                        }
                                                        items={relationShipList.map(
                                                            (relation) => ({
                                                                id: relation,
                                                                name: relation,
                                                            })
                                                        )}
                                                        change={(val: any) => {
                                                            setRelationShip(
                                                                val
                                                            );
                                                        }}
                                                    />
                                                </div>
                                                {/* <button
                                                    className="buttonPrimary mobileOnly mt15 W100"
                                                    type="submit"
                                                >
                                                    Next
                                                </button> */}
                                            </Stack>
                                        </Box>
                                    </Form>
                                </Box>
                                <Box
                                    sx={{
                                        flex: 1,
                                    }}
                                >
                                    <FormContainer
                                        handleSubmit={handleSubmit}
                                    />
                                    {/* <KmdInvoice /> */}
                                </Box>
                            </Stack>
                            <Box
                                sx={{
                                    padding: "22px 0",
                                }}
                            >
                                <Divider
                                    sx={{
                                        border: "1px dashed rgb(217, 215, 224)",
                                    }}
                                />
                            </Box>
                        </Box>
                    </Formik>
            </V2Container>

    );
};

export default KmdStep2;

const FormContainer = ({ handleSubmit }: any) => {
    let { submitForm } = useFormikContext();
    return <KmdInvoice submitForm={submitForm} />;
};
