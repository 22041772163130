function removeSalutation(name: string): string {
    if (!name || name.trim().length === 0) {
        return "";
    }
    
    const salutations: string[] = ["mister", "mr", "mr.", "mrs.", "dr"];

    const names: string[] = name.trim().split(/\s+/);

    let startIndex: number = 0;

    if (names.length > 1) {
        const firstWord: string = names[0].toLowerCase();
        for (const salutation of salutations) {
            if (firstWord === salutation.toLowerCase()) {
                console.log("In salutation");
                startIndex = 1;
                break;
            }
        }
    }

    if (startIndex >= names.length) {
        return "";
    }

    return names.slice(startIndex).join(" ");
}
export default removeSalutation;
