import React, { useEffect, useState } from "react";
import V2Container from "../../../components/Layout/V2Container";
import {
    Box,
    Button,
    Checkbox,
    Divider,
    Grid,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
// import { CheckBox } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
    setQuestionnare,
    setParentCheckboxes,
} from "../../../features/kmd/kmdSlice";
import dayjs from "dayjs";
import alert from "../../../util/alerts";

type questionnare = {
    pedYesNo: string;
    "205": string;
    diabetesExistingSince: string;
    "207": string;
    hyperTensionExistingSince: string;
    "232": string;
    liverExistingSince: string;
    "114": string;
    cancerExistingSince: string;
    "143": string;
    cardiacExistingSince: string;
    "105": string;
    jointpainExistingSince: string;
    "129": string;
    kidneyExistingSince: string;
    "164": string;
    paralysisExistingSince: string;
    "122": string;
    congenitalExistingSince: string;
    "147": string;
    hivaidsExistingSince: string;
    "210": string;
    otherExistingSince: string;
    otherDiseasesDescription: string;
    "250": string;
    respiratoryExistingSince: string;
    "222": string;
    EndocriExistingSince: string;
    "502": string;
    illnessExistingSince: string;
    "503": string;
    SurgeryExistingSince: string;
    H001: string;
    H002: string;
    H003: string;
    H004: string;
    "504": string;
    OtherSmokeDetails: string;
    SmokeExistingSince: string;
    dummy_smoking: string;
    dummy_otherThanSmoking: string;
};

const getQuestionnare = (
    id: string,
    questionnares: { id: string; questionnare: questionnare }[]
) => {
    return questionnares.find((quesst) => {
        if (quesst.id === id) {
            return true;
        }
    });
};

const KmdQuestionnaireStatic: React.FC<{
    setCurrentStep: (step: number) => void;
}> = ({ setCurrentStep }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const reducerDispatch = useDispatch();
    const [errors, setErrors] = useState<
        { id: string; errors: { [key: string]: string } }[]
    >([]);

    const { members, parentCheckboxes, questionnares } = useSelector(
        (state: RootState) => state.kmd
    );

    useEffect(() => {
        debugger;
        if (questionnares.length === 0) {
            let tempQuestionnares = [];
            for (let i = 0; i < members.length; i++) {
                tempQuestionnares.push({
                    id: members[i].uid || "",
                    questionnare: {
                        pedYesNo: "",
                        "205": "",
                        diabetesExistingSince: "",
                        "207": "",
                        hyperTensionExistingSince: "",
                        "232": "",
                        liverExistingSince: "",
                        "114": "",
                        cancerExistingSince: "",
                        "143": "",
                        cardiacExistingSince: "",
                        "105": "",
                        jointpainExistingSince: "",
                        "129": "",
                        kidneyExistingSince: "",
                        "164": "",
                        paralysisExistingSince: "",
                        "122": "",
                        congenitalExistingSince: "",
                        "147": "",
                        hivaidsExistingSince: "",
                        "210": "",
                        otherExistingSince: "",
                        otherDiseasesDescription: "",
                        "250": "",
                        respiratoryExistingSince: "",
                        "222": "",
                        EndocriExistingSince: "",
                        "502": "",
                        illnessExistingSince: "",
                        "503": "",
                        SurgeryExistingSince: "",
                        H001: "",
                        H002: "",
                        H003: "",
                        H004: "",
                        "504": "",
                        OtherSmokeDetails: "",
                        SmokeExistingSince: "",
                        dummy_smoking: "",
                        dummy_otherThanSmoking: "",
                    },
                });
            }
            reducerDispatch(setQuestionnare(tempQuestionnares));
        }
    }, [members]);

    const handleQuestionnaresChange = (
        value: string,
        id: string,
        code: string
    ) => {
        let copy = [...questionnares];
        let questionnareVal = { ...getQuestionnare(id, copy)?.questionnare };
        if (questionnareVal) {
            // @ts-ignore
            questionnareVal[code] = value;
            // @ts-ignore
            copy = copy.map((c) => {
                if (c.id === id) {
                    return {
                        id: id,
                        questionnare: questionnareVal,
                    };
                }
                return c;
            });
        }
        reducerDispatch(setQuestionnare(copy));
    };

    const validate = (
        questionnares: { id: string; questionnare: questionnare }[]
    ) => {
        debugger;
        let errors = [];
        for (let i = 0; i < questionnares.length; i++) {
            let q = questionnares[i].questionnare;
            let errorsInMember: {
                id: string;
                errors: { [key: string]: string };
            } = { id: questionnares[i].id, errors: {} };
            let a = dayjs(q.diabetesExistingSince).isValid()
            if (q.pedYesNo === "yes") {
                if (q["205"] === "yes") {
                    debugger
                    if (!q.diabetesExistingSince || !dayjs(q.diabetesExistingSince).isValid() ) {
                        errorsInMember.errors["diabetesExistingSince"] =
                            "Invalid Diabetes Existing Since";
                    }
                }
                if (q["207"] === "yes") {
                    if (!q.hyperTensionExistingSince || !dayjs(q.hyperTensionExistingSince).isValid() ) {
                        errorsInMember.errors["hyperTensionExistingSince"] =
                            "Invalid Hyper Tension Existing Since ";
                    }
                }
                if (q["232"] === "yes") {
                    if (!q.liverExistingSince || !dayjs(q.liverExistingSince).isValid() ) {
                        errorsInMember.errors["liverExistingSince"] =
                            "Invalid Liver Existing Since ";
                    }
                }
                if (q["114"] === "yes") {
                    if (!q.cancerExistingSince || !dayjs(q.cancerExistingSince).isValid() ) {
                        errorsInMember.errors["cancerExistingSince"] =
                            "Invalid Cancer Existing Since ";
                    }
                }
                if (q["143"] === "yes") {
                    if (!q.cardiacExistingSince || !dayjs(q.cardiacExistingSince).isValid() ) {
                        errorsInMember.errors["cardiacExistingSince"] =
                            "Invalid Cardiac Existing Since ";
                    }
                }
                if (q["105"] === "yes") {
                    if (!q.jointpainExistingSince || !dayjs(q.jointpainExistingSince).isValid() ) {
                        errorsInMember.errors["jointpainExistingSince"] =
                            "Invalid Joint Pain Existing Since ";
                    }
                }
                if (q["129"] === "yes") {
                    if (!q.kidneyExistingSince || !dayjs(q.kidneyExistingSince).isValid() ) {
                        errorsInMember.errors["kidneyExistingSince"] =
                            "Invalid Kidney Existing Since ";
                    }
                }
                if (q["164"] === "yes") {
                    if (!q.paralysisExistingSince || !dayjs(q.paralysisExistingSince).isValid() ) {
                        errorsInMember.errors["paralysisExistingSince"] =
                            "Invalid Paralysis Existing Since ";
                    }
                }
                if (q["122"] === "yes") {
                    if (!q.congenitalExistingSince || !dayjs(q.congenitalExistingSince).isValid() ) {
                        errorsInMember.errors["congenitalExistingSince"] =
                            "Invalid Congenital Existing Since ";
                    }
                }
                if (q["147"] === "yes") {
                    if (!q.hivaidsExistingSince || !dayjs(q.hivaidsExistingSince).isValid() ) {
                        errorsInMember.errors["hivaidsExistingSince"] =
                            "Invalid HIV/AIDS Existing Since ";
                    }
                }
                if (q["250"] === "yes") {
                    if (!q.respiratoryExistingSince || !dayjs(q.respiratoryExistingSince).isValid() ) {
                        errorsInMember.errors["respiratoryExistingSince"] =
                            "Invalid Respiratory Existing Since ";
                    }
                }
                if (q["222"] === "yes") {
                    if (!q.EndocriExistingSince || !dayjs(q.EndocriExistingSince).isValid() ) {
                        errorsInMember.errors["EndocriExistingSince"] =
                            "Invalid Endocrine Existing Since ";
                    }
                }
                if (q["502"] === "yes") {
                    if (!q.illnessExistingSince || !dayjs(q.illnessExistingSince).isValid() ) {
                        errorsInMember.errors["illnessExistingSince"] =
                            "Invalid Illness Existing Since ";
                    }
                }
                if (q["503"] === "yes") {
                    if (!q.SurgeryExistingSince || !dayjs(q.SurgeryExistingSince).isValid() ) {
                        errorsInMember.errors["SurgeryExistingSince"] =
                            "Invalid Surgery Existing Since ";
                    }
                }
                if (q["210"] === "yes") {
                    if (!q.otherExistingSince || !dayjs(q.otherExistingSince).isValid() ) {
                        errorsInMember.errors["otherExistingSince"] =
                            "Invalid Other Existing Since ";
                    }
                    if (!q.otherDiseasesDescription) {
                        errorsInMember.errors["otherDiseasesDescription"] =
                            "Other Diseases Description is required";
                    }
                }
            }
            if (q["504"] === "yes") {
                if(q.dummy_smoking === "yes" && !q.SmokeExistingSince) {
                        errorsInMember.errors["SmokeExistingSince"] =
                            "Smoke Existing Since is required";
                }
                if(q.dummy_otherThanSmoking === "yes" && !q.OtherSmokeDetails) {
                    errorsInMember.errors["OtherSmokeDetails"] =
                        "Other Smoke Details is required";
                }
            }
            if (Object.keys(errorsInMember.errors).length > 0) {
                errors.push(errorsInMember);
            }
        }
        setErrors(errors);
        return errors;
    };

    const removeChildQuestionnares = () => {
        let copy = [...questionnares];
        copy = copy.map((c) => {
            let q = { ...c.questionnare };
            if(q["205"] !== "yes") {
                q.diabetesExistingSince = "";
            }
            if(q["207"] !== "yes") {
                q.hyperTensionExistingSince = "";
            }
            if(q["232"] !== "yes") {
                q.liverExistingSince = "";
            }
            if(q["114"] !== "yes") {
                q.cancerExistingSince = "";
            }
            if(q["143"] !== "yes") {
                q.cardiacExistingSince = "";
            }
            if(q["105"] !== "yes") {
                q.jointpainExistingSince = "";
            }
            if(q["129"] !== "yes") {
                q.kidneyExistingSince = "";
            }
            if(q["164"] !== "yes") {
                q.paralysisExistingSince = "";
            }
            if(q["122"] !== "yes") {
                q.congenitalExistingSince = "";
            }
            if(q["147"] !== "yes") {
                q.hivaidsExistingSince = "";
            }
            if(q["250"] !== "yes") {
                q.respiratoryExistingSince = "";
            }
            if(q["222"] !== "yes") {
                q.EndocriExistingSince = "";
            }
            if(q["502"] !== "yes") {
                q.illnessExistingSince = "";
            }
            if(q["503"] !== "yes") {
                q.SurgeryExistingSince = "";
            }
            if(q["504"] !== "yes") {
                if(q.dummy_smoking !== "yes") {
                    q.SmokeExistingSince = "";
                }
                if(q.dummy_otherThanSmoking !== "yes") {
                    q.OtherSmokeDetails = "";
                }
            }
            return {
                id: c.id,
                questionnare: q
            }

        });
        return copy;
    };

    return (
        <V2Container
            heading="Help us know about your medical conditions"
            onBack={() => {
                setCurrentStep(2);
            }}
            sx={{
                padding: "24px",
            }}
        >
            <Box sx={{ width: "100%" }}>
                <Stack spacing={"24px"}>
                    <Typography variant="subtitle2">
                        Has any Proposed to be Insured been diagnosed with or
                        suffered from / is suffering from or is currently under
                        medication for the following. If Your response is yes to
                        any of the following questions, please specify details
                        of the same in the additional information section :
                    </Typography>

                    <Box>
                        <Grid container spacing={"24px"}>
                            {members.map((member) => (
                                <Grid item xs={isMobile ? 12 : 6}>
                                    <MemberCheckBox
                                        member={member}
                                        date={false}
                                        text={false}
                                        value={{
                                            checkbox: "pedYesNo",
                                            date: "",
                                            text: "",
                                        }}
                                        onChange={{
                                            checkbox: (val: string) =>
                                                handleQuestionnaresChange(
                                                    val,
                                                    member.uid || "",
                                                    "pedYesNo"
                                                ),
                                            date: (val: string) => {},
                                            text: (val: string) => {},
                                        }}
                                        questionnares={questionnares}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>

                    {questionnares.some(
                        (q) => q.questionnare.pedYesNo === "yes"
                    ) && (
                        <Box
                            sx={{
                                padding: "16px",
                                backgroundColor: theme.palette.grey[200],
                                borderRadius: "16px",
                            }}
                        >
                            <Stack spacing={"24px"}>
                                <Typography variant="subtitle2">
                                    Please select any one of the conditions for
                                    the member(s) selected above
                                </Typography>
                                {[
                                    {
                                        text: "Diabetes Mellitus type 1 or Diabetes on insulin or Diabetes associated with blindness or chronic foot ulcer",
                                        cCode: "205",
                                        dCode: "diabetesExistingSince",
                                    },
                                    {
                                        text: "Hypertension / High Blood Pressure",
                                        cCode: "207",
                                        dCode: "hyperTensionExistingSince",
                                    },
                                    {
                                        text: "Pancreatitis or Liver disease (including but not limited to Cirrhosis / Hepatitis B or C / Willson’s disease) or any other digestive track disorder (disorders of esophagus or stomach or intestine or any other)",
                                        cCode: "232",
                                        dCode: "liverExistingSince",
                                    },
                                    {
                                        text: "Cancer",
                                        cCode: "114",
                                        dCode: "cancerExistingSince",
                                    },
                                    {
                                        text: "Any cardiovascular/Heart Disease (including but not limited to Coronary artery disease / Rheumatic heart disease / Heart Attack or Myocardial infarction / Heart failure / Bypass Grafting or CABG / Angioplasty or PTCA / Heart valve diseases / Pacemaker implantation)",
                                        cCode: "143",
                                        dCode: "cardiacExistingSince",
                                    },
                                    {
                                        text: "Any disorders of Blood and / or Immunity (including but not limited to bleeding or clotting disorders, Systemic Lupus Erythematosus, Rheumatoid Arthritis, Crohn’s disease, Ulcerative Colitis).",
                                        cCode: "105",
                                        dCode: "jointpainExistingSince",
                                    },
                                    {
                                        text: "Any Kidney / urinary track / reproductive organ Disease",
                                        cCode: "129",
                                        dCode: "kidneyExistingSince",
                                    },
                                    {
                                        text: "Any Neuromuscular (muscles or nervous system) disorder or  Psychiatric disorders (including but not limited to Motor Neuron Disease, Muscular dystrophies, Epilepsy, Paralysis, Parkinsonism, multiple sclerosis, stroke, mental illness)",
                                        cCode: "164",
                                        dCode: "paralysisExistingSince",
                                    },
                                    {
                                        text: "Congenital Disorder?",
                                        cCode: "122",
                                        dCode: "congenitalExistingSince",
                                    },
                                    {
                                        text: "HIV/ AIDS/ STD? * ",
                                        cCode: "147",
                                        dCode: "hivaidsExistingSince",
                                    },
                                    {
                                        text: "Any Respiratory disease / Disease of Lungs, Pleura and airway (including but not limited to Asthma / Tuberculosis / Pleural effusion / Bronchitis / Emphysema)",
                                        cCode: "250",
                                        dCode: "respiratoryExistingSince",
                                    },
                                    {
                                        text: "Any disorders of the endocrine system (including but not limited to Pituitary / Parathyroid / adrenal gland disorders)",
                                        cCode: "222",
                                        dCode: "EndocriExistingSince",
                                    },
                                    {
                                        text: "Has any of the Proposed to be Insured consulted/taken treatment or recommended to take investigations/medication/surgery other than for childbirth/minor injuries? *",
                                        cCode: "502",
                                        dCode: "illnessExistingSince",
                                    },
                                    {
                                        text: "Has any of the Proposed to be Insured been hospitalized or has been under any prolonged treatment for any illness/injury or has undergone surgery other than for childbirth/minor injuries? *",
                                        cCode: "503",
                                        dCode: "SurgeryExistingSince",
                                    },
                                    {
                                        text: "Any other disease not mentioned above",
                                        cCode: "210",
                                        dCode: "otherExistingSince",
                                        tCode: "otherDiseasesDescription",
                                    }
                                ].map((condition) => (
                                    <Box
                                        sx={
                                            // @ts-ignore
                                            parentCheckboxes[
                                                condition.cCode
                                            ] === "yes"
                                                ? {
                                                      backgroundColor: "#fff",
                                                      padding: "16px",
                                                      borderRadius: "16px",
                                                  }
                                                : {}
                                        }
                                    >
                                        <Stack
                                            direction={"row"}
                                            gap={"8px"}
                                            alignItems={"flex-start"}
                                        >
                                            <Checkbox
                                                checked={
                                                    // @ts-ignore
                                                    parentCheckboxes[
                                                        condition.cCode
                                                    ] === "yes"
                                                        ? true
                                                        : false
                                                }
                                                sx={{
                                                    padding: "0px",
                                                    "&.Mui-checked": {
                                                        color: theme.palette
                                                            .brand?.main,
                                                    },
                                                }}
                                                onClick={() => {
                                                    reducerDispatch(
                                                        setParentCheckboxes({
                                                            ...parentCheckboxes,
                                                            [condition.cCode]:
                                                                // @ts-ignore
                                                                parentCheckboxes[
                                                                    condition
                                                                        .cCode
                                                                ] === "yes"
                                                                    ? "no"
                                                                    : "yes",
                                                        })
                                                    );
                                                }}
                                            />
                                            <Typography
                                                variant="navbutton"
                                                color={theme.palette.grey[800]}
                                                sx={{
                                                    marginBottom: "24px",
                                                }}
                                            >
                                                {condition.text}
                                            </Typography>
                                        </Stack>
                                        {
                                            // @ts-ignore
                                            parentCheckboxes[
                                                condition.cCode
                                            ] === "yes" && (
                                                <Grid
                                                    container
                                                    spacing={"24px"}
                                                >
                                                    {members
                                                        .filter((member) =>
                                                            questionnares.some(
                                                                (q) =>
                                                                    q.id ===
                                                                        member.uid &&
                                                                    q
                                                                        .questionnare
                                                                        .pedYesNo ===
                                                                        "yes"
                                                            )
                                                        )
                                                        .map((member) => (
                                                            <Grid item xs={12}>
                                                                <Box>
                                                                    <MemberCheckBox
                                                                        member={
                                                                            member
                                                                        }
                                                                        date={
                                                                            true
                                                                        }
                                                                        text={
                                                                            !!condition.tCode ||
                                                                            false
                                                                        }
                                                                        value={{
                                                                            checkbox:
                                                                                condition.cCode,
                                                                            text: condition.tCode ? condition.tCode : "",
                                                                            date:
                                                                                condition.dCode ||
                                                                                "",
                                                                        }}
                                                                        onChange={{
                                                                            checkbox:
                                                                                (
                                                                                    val: string
                                                                                ) =>
                                                                                    handleQuestionnaresChange(
                                                                                        val,
                                                                                        member.uid ||
                                                                                            "",
                                                                                        condition.cCode
                                                                                    ),
                                                                            date: (
                                                                                val: string
                                                                            ) =>
                                                                                handleQuestionnaresChange(
                                                                                    val,
                                                                                    member.uid ||
                                                                                        "",
                                                                                    condition.dCode
                                                                                ),
                                                                            text: (
                                                                                val: string
                                                                            ) => {
                                                                                handleQuestionnaresChange(
                                                                                    val,
                                                                                    member.uid ||
                                                                                        "",
                                                                                    condition?.tCode || "test"
                                                                                );
                                                                            },
                                                                        }}
                                                                        questionnares={
                                                                            questionnares
                                                                        }
                                                                        error={
                                                                            errors.find(
                                                                                (
                                                                                    e
                                                                                ) =>
                                                                                    e.id ===
                                                                                    member.uid
                                                                            )
                                                                                ?.errors?.[
                                                                                condition
                                                                                    .dCode
                                                                            ]
                                                                        }
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                        ))}
                                                </Grid>
                                            )
                                        }
                                    </Box>
                                ))}
                                
                            </Stack>
                        </Box>
                    )}
                    <Box
                        sx={{
                            padding: "22px 0",
                        }}
                    >
                        <Divider
                            sx={{
                                border: "1px dashed rgb(217, 215, 224)",
                            }}
                        />
                    </Box>
                    {[
                        {
                            text: "Have any of the above mentioned person(s) to be insured been diagnosed / hospitalized for any illness / injury during the last 48 months?",
                            cCode: "H001",
                        },
                        {
                            text: "Have any of the person(s) to be insured ever filed a claim with their current / previous insurer? ",
                            cCode: "H002",
                        },
                        {
                            text: "Has any proposal for Health insurance been declined, cancelled or charged a higher premium? ",
                            cCode: "H003",
                        },
                        {
                            text: "Is any of the person(s) to be insured, already covered under any other health insurance policy of Religare Health Insurance?",
                            cCode: "H004",
                        },
                        {
                            text: "Do You smoke, consume alcohol, or chew tobacco, ghutka or paan or use any recreational drugs? If ‘Yes’ then please provide the frequency & amount consumed. *",
                            cCode: "504",
                        },
                    ].map((question) => (
                        <>
                            <Box>
                                <Typography
                                    variant="subtitle2"
                                    color={theme.palette.grey[800]}
                                    sx={{
                                        marginBottom: "24px",
                                    }}
                                >
                                    {question.text}
                                </Typography>
                                <Grid container spacing={"24px"}>
                                    {members.map((member) => (
                                        <Grid item xs={isMobile ? 12 : 6}>
                                            <MemberCheckBox
                                                member={member}
                                                date={false}
                                                text={false}
                                                value={{
                                                    checkbox: question.cCode,
                                                    date: "",
                                                    text: "",
                                                }}
                                                onChange={{
                                                    checkbox: (val: string) =>
                                                        handleQuestionnaresChange(
                                                            val,
                                                            member.uid || "",
                                                            question.cCode
                                                        ),
                                                    date: (val: string) => {},
                                                    text: (val: string) => {},
                                                }}
                                                questionnares={questionnares}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                            <Box
                                sx={
                                    {
                                        // padding: "22px 0",
                                    }
                                }
                            >
                                <Divider
                                    sx={{
                                        border: "1px dashed rgb(217, 215, 224)",
                                    }}
                                />
                            </Box>
                        </>
                    ))}

                    {questionnares.some(
                        (q) => q.questionnare["504"] === "yes"
                    ) && (
                        <Box
                            sx={{
                                padding: "16px",
                                backgroundColor: theme.palette.grey[200],
                                borderRadius: "16px",
                            }}
                        >
                            <Typography
                                variant="navbutton"
                                color={theme.palette.grey[800]}
                                sx={{
                                    marginBottom: "24px",
                                }}
                            >
                                Please select any one of the conditions for the
                                member(s) selected above
                            </Typography>

                            <Stack gap={"24px"}>
                                {[
                                    {
                                        text: "Smoking",
                                        cCode: "dummy_smoking",
                                        dCode: "SmokeExistingSince",
                                        tCode: "",
                                    },
                                    {
                                        text: "Other than smoking",
                                        cCode: "dummy_otherThanSmoking",
                                        dCode: "",
                                        tCode: "OtherSmokeDetails",
                                    },
                                ].map((condition, index) => (
                                    <Box
                                        sx={
                                            // @ts-ignore
                                            parentCheckboxes[
                                                condition.dCode
                                            ] === "yes" ||
                                            // @ts-ignore
                                            parentCheckboxes[
                                                condition.tCode
                                            ] === "yes"
                                                ? {
                                                      padding: "16px",
                                                      backgroundColor: "#fff",
                                                      borderRadius: "16px",
                                                  }
                                                : {}
                                        }
                                    >
                                        <Stack
                                            direction={"row"}
                                            gap={"8px"}
                                            alignItems={"flex-start"}
                                        >
                                            <Checkbox
                                                checked={
                                                    // @ts-ignore
                                                    parentCheckboxes[
                                                        condition.dCode
                                                    ] === "yes" ||
                                                    // @ts-ignore
                                                    parentCheckboxes[
                                                        condition.tCode
                                                    ] === "yes"
                                                        ? true
                                                        : false
                                                }
                                                sx={{
                                                    padding: "0px",
                                                    "&.Mui-checked": {
                                                        color: theme.palette
                                                            .brand?.main,
                                                    },
                                                }}
                                                onChange={() => {
                                                    reducerDispatch(
                                                        setParentCheckboxes({
                                                            ...parentCheckboxes,
                                                            [condition.dCode +
                                                            condition.tCode]:
                                                                // @ts-ignore
                                                                parentCheckboxes[
                                                                    condition
                                                                        .tCode
                                                                ] === "yes" ||
                                                                // @ts-ignore
                                                                parentCheckboxes[
                                                                    condition
                                                                        .dCode
                                                                ] === "yes"
                                                                    ? "no"
                                                                    : "yes",
                                                        })
                                                    );
                                                }}
                                            />
                                            <Typography
                                                variant="navbutton"
                                                color={theme.palette.grey[800]}
                                            >
                                                {condition.text}
                                            </Typography>
                                        </Stack>
                                        {
                                            // @ts-ignore
                                            parentCheckboxes[
                                                condition.tCode +
                                                    condition.dCode
                                            ] === "yes" && (
                                                <Stack spacing={"12px"}>
                                                    {members
                                                        .filter((member) =>
                                                            questionnares.some(
                                                                (q) =>
                                                                    q.id ===
                                                                        member.uid &&
                                                                    q
                                                                        .questionnare[
                                                                        "504"
                                                                    ] === "yes"
                                                            )
                                                        )
                                                        .map((member) => (
                                                            <MemberCheckBox
                                                                member={member}
                                                                date={
                                                                    index === 0
                                                                }
                                                                text={
                                                                    index === 1
                                                                }
                                                                value={{
                                                                    checkbox:
                                                                        // @ts-ignore
                                                                        condition.cCode,
                                                                    // @ts-ignore
                                                                    date: condition.dCode,
                                                                    // @ts-ignore
                                                                    text: condition.tCode,
                                                                }}
                                                                error={
                                                                    errors.find(
                                                                        (e) =>
                                                                            e.id ===
                                                                            member.uid
                                                                    )?.errors?.[
                                                                        condition
                                                                            .dCode + condition.tCode
                                                                    ]
                                                                }
                                                                onChange={{
                                                                    checkbox: (
                                                                        val: string
                                                                    ) =>
                                                                        handleQuestionnaresChange(
                                                                            val,
                                                                            member.uid ||
                                                                                "",
                                                                            condition.cCode
                                                                        ),
                                                                    date: (
                                                                        val: string
                                                                    ) =>
                                                                        handleQuestionnaresChange(
                                                                            val,
                                                                            member.uid ||
                                                                                "",
                                                                            condition.dCode
                                                                        ),
                                                                    text: (
                                                                        val: string
                                                                    ) =>
                                                                        handleQuestionnaresChange(
                                                                            val,
                                                                            member.uid ||
                                                                                "",
                                                                            condition.tCode
                                                                        ),
                                                                }}
                                                                questionnares={
                                                                    questionnares
                                                                }
                                                            />
                                                        ))}
                                                </Stack>
                                            )
                                        }
                                    </Box>
                                ))}
                            </Stack>
                        </Box>
                    )}
                    <Button
                        size="small"
                        sx={{
                            backgroundColor: theme.palette.brand?.main,
                            color: "#fff",
                            marginLeft: "auto !important",
                            marginTop: "32px",
                            width: "100px",
                        }}
                        onClick={() => {
                            debugger;
                            let reducedQuestionnares = removeChildQuestionnares();
                            reducerDispatch(setQuestionnare(reducedQuestionnares));
                            let errors = validate(reducedQuestionnares);
                            if (errors.length === 0) {
                                setCurrentStep(4);
                            } else {
                                alert("Please provide valid values in fields above ", "error");
                            }
                            // setCurrentStep(4);
                        }}
                    >
                        Next
                    </Button>
                </Stack>
            </Box>
        </V2Container>
    );
};

export default KmdQuestionnaireStatic;

const MemberCheckBox = ({
    member,
    date = false,
    text = false,
    value,
    onChange,
    questionnares,
    error,
}: {
    member: any;
    date: boolean;
    text: boolean;
    value: {
        checkbox: string;
        date: string;
        text: string;
    };
    onChange: {
        checkbox: (val: string) => void;
        date: (val: string) => void;
        text: (val: string) => void;
    };
    questionnares: { id: string; questionnare: questionnare }[];
    error?: string;
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [checkboxVal, setCheckboxVal] = useState(value.checkbox);
    const [dateVal, setDateVal] = useState(value.date);
    const [textVal, setTextVal] = useState(value.text);
    useEffect(() => {
        setCheckboxVal(
            // @ts-ignore
            getQuestionnare(member.uid || 0, questionnares)?.questionnare[
                value.checkbox
            ] || "no"
        );
        setDateVal(
            // @ts-ignore
            getQuestionnare(member.uid || 0, questionnares)?.questionnare[
                value.date
            ] || ""
        );
        setTextVal(
            // @ts-ignore
            getQuestionnare(member.uid || 0, questionnares)?.questionnare[
                value.text
            ] || ""
        );
    }, [JSON.stringify(questionnares)]);

    if (date || text) {
        return (
            <Grid container spacing={2}>
                <Grid item xs={isMobile ? 12 : 6}>
                    <Box
                        sx={{
                            padding: "12px 0px",
                            borderRadius: "12px",
                            border:
                                checkboxVal === "yes"
                                    ? `2px solid ${theme.palette.brand?.main}`
                                    : "2px solid var(--Components-Outline, #EAEAEF)",
                        }}
                    >
                        <Stack
                            spacing={"8px"}
                            direction={"row"}
                            alignItems={"center"}
                        >
                            <Checkbox
                                sx={{
                                    "&.Mui-checked": {
                                        color: theme.palette.brand?.main,
                                    },
                                }}
                                checked={checkboxVal === "yes" ? true : false}
                                onChange={() =>
                                    onChange.checkbox(
                                        checkboxVal === "yes" ? "no" : "yes"
                                    )
                                }
                            />
                            <Typography
                                variant="navbutton"
                                color={theme.palette.grey[800]}
                            >
                                {member.name}
                            </Typography>
                            <Typography
                                variant="body3"
                                color={theme.palette.grey[700]}
                            >
                                {member.relationShip === "Employee" ? "Self" : member.relationShip}
                            </Typography>
                        </Stack>
                    </Box>
                </Grid>
                {(date && checkboxVal === "yes" ? true : false) && (
                    <Grid item xs={isMobile ? 12 : 6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label={"Existing since(MM/YYYY)"}
                                views={["month", "year"]}
                                value={dateVal || null}
                                onChange={(date) => {
                                    onChange.date(date || "");
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        sx={{
                                            width: "100%",
                                        }}
                                        helperText={
                                            (!dateVal || !dayjs(dateVal).isValid() ) && error ? error : ""
                                        }
                                        error={(!dateVal || !dayjs(dateVal).isValid()) && !!error}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                )}
                {(text && checkboxVal === "yes" ? true : false) && (
                    <Grid item xs={isMobile ? 12 : 6}>
                        <TextField
                            label="Describe"
                            sx={{
                                width: "100%",
                            }}
                            value={textVal}
                            onChange={(e) => onChange.text(e.target.value)}
                            helperText={!textVal && error ? error : ""}
                            error={!textVal && !!error}
                        />
                    </Grid>
                )}
            </Grid>
        );
    }
    return (
        <Box
            sx={{
                padding: "12px 0px",
                borderRadius: "12px",
                border:
                    checkboxVal === "yes"
                        ? `2px solid ${theme.palette.brand?.main}`
                        : "2px solid var(--Components-Outline, #EAEAEF)",
            }}
        >
            <Stack spacing={"8px"} direction={"row"} alignItems={"center"}>
                <Checkbox
                    checked={checkboxVal === "yes" ? true : false}
                    onChange={() =>
                        onChange.checkbox(checkboxVal === "yes" ? "no" : "yes")
                    }
                    sx={{
                        "&.Mui-checked": {
                            color: theme.palette.brand?.main,
                        },
                    }}
                />
                <Typography variant="navbutton" color={theme.palette.grey[800]}>
                    {member.name}
                </Typography>
                <Typography variant="body3" color={theme.palette.grey[700]}>
                    {member.relationShip === "Employee" ? "Self" : member.relationShip}
                </Typography>
            </Stack>
        </Box>
    );
};
