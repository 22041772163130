import React, { useEffect } from "react";
import KmdInvoice from "./kmdInvoice";
import "./kmdStep2.scss";
import "./kmdStep4.scss";
import {
    Box,
    Chip,
    Divider,
    IconButton,
    Stack,
    Typography,
    useTheme,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { changeDateFormat } from "../../../util/date";
import advService from "../../../util/advService";
import { KMD_CREATE_PROPOSAL_POST } from "../../../util/newconfig";
import { KmdProposalDTO } from "../../../types/DTO/kmdDto";
import { AxiosResponse } from "axios";
import { JavaResponse } from "../../../types";
import V2Container from "../../../components/Layout/V2Container";
import getMemberAvatar, {
    getNewMemberBackground,
} from "../../../util/function/getMemberAvatar";
import Iconify from "../../../components/iconify";
import dayjs from "dayjs";

const bannerImage =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/Super_TopUp/superIllustration.png";

const parseQuestionnare = (obj: Record<string, string>) => {
    let copyObject = { ...obj };
    Object.keys(copyObject).forEach((key) => {
        if (copyObject[key] === "" || copyObject[key] === null || copyObject[key] === 'no') {
            delete copyObject[key];
        }
        if (key === "dummy_smoking" || key === "dummy_otherThanSmoking") {
            delete copyObject[key];
        }
        if (key.includes("Since") && copyObject[key]) {
            copyObject[key] = dayjs(copyObject[key]).format("MM-YYYY");
        }
    });
    return copyObject;
}

interface Props {
    setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
    logId: string;
}

const KmdStep4: React.FC<Props> = ({ setCurrentStep, logId }) => {
    const theme = useTheme();
    const [loader, setLoader] = React.useState(false);
    const {
        members,
        memberInfo,
        sumInsured,
        deductible,
        plan,
        policyType,
        clientUuid,
        premium,
        ckyc,
        masterUuid,
        adressRequired,
        isKyc,
        questionnares
    } = useSelector((state: RootState) => state.kmd);
    const reducerDispatch = useDispatch();

    useEffect(() => {
        document.body.style.backgroundColor = "white";
        return () => {
            document.body.style.backgroundColor = "#f6f6f6";
        };
    }, []);

    const submitForm = () => {
        setLoader(true);
        advService(
            { ...KMD_CREATE_PROPOSAL_POST },
            {
                policyEmployeeMemberDTOS: members.map((member) => {
                    return {
                        ...(member.relationShip === "Employee"
                            ? {}
                            : {
                                  id: member.id,
                              }),
                        relationShip: member.relationShip,
                        gender: member.gender,
                        name: member.name,
                        dob: changeDateFormat(
                            "javascriptDateObject",
                            "yyyy-mm-dd",
                            new Date(member.dob)
                        ),
                        // sumInsured: sumInsured,
                        // deductible: 0,
                        uuid: member.uuid,
                        active: member.active,
                        memberId: member.memberId,
                        ...(policyType === "TOPUP" ? {
                            questionnare: parseQuestionnare(questionnares.find(
                                (question) => question.id === member.uid
                            )?.questionnare || {})
                        }:{})
                    };
                }),
                nominations: [
                    {
                        name: memberInfo.nomineeName,
                        relationship: memberInfo.nomineeRelation,
                        active: true,
                        ...(policyType === "GMC" && {
                            dob: changeDateFormat(
                                "javascriptDateObject",
                                "yyyy-mm-dd",
                                new Date(memberInfo.nomineeDob)
                            ),
                        }),
                    },
                ],
                proposerDTO: {
                    addressDTO: {
                        ...(adressRequired && {
                            addressLine1: memberInfo.addressLine1,
                            addressLine2: memberInfo.addressLine2,
                            city: memberInfo.city,
                            state: null,
                            pincode: memberInfo.pincode,
                        }),
                        uuid: memberInfo.addressUuid,
                        active: true,
                    },
                    name: memberInfo.name,
                    dob: changeDateFormat(
                        "javascriptDateObject",
                        "yyyy-mm-dd",
                        new Date(
                            members.find(
                                (member) =>
                                    member.relationShip === "Employee" ||
                                    member.relationShip === "Self"
                            )?.dob || ""
                        )
                    ),
                    gender: members.find(
                        (member) =>
                            member.relationShip === "Employee" ||
                            member.relationShip === "Self"
                    )?.gender,
                    relationship: members.find(
                        (member) =>
                            member.relationShip === "Employee" ||
                            member.relationShip === "Self"
                    )?.relationShip,
                    clientUuid: clientUuid,
                    phone: memberInfo.phone,
                    email: "",
                },
                masterPolicyUuid: masterUuid,
                masterPolicyNodeApiDTO: {
                    sumInsured: sumInsured,
                    premium: Math.ceil(premium * 1.18),
                    deductible: policyType === "TOPUP" ? deductible: null,
                    planName: plan,
                    // planCode: "",
                    salary: 0,
                    // startDate: "",
                    // endDate: "",
                    // policyUuid: "",
                },
                nomination: 1,
                isAddressReadOnly: false,
                // insurer: "FUTURE",
                // transactionId: "cd18ad2f-9325-4c45-bccc-d5db4085903f",
                // logId: "someuuidId",
                // paymentReturnUrl: "https://app.imt.care/kmd/status/{policyUuid}",
                params: {
                    ckyc: ckyc,
                },
                logId: logId,
            } as KmdProposalDTO,
            (res: AxiosResponse<JavaResponse<{ link: string }>>) => {
                setLoader(false);
                if (res.data.success) {
                    // window.open(res.data.response.link, "_blank");
                    window.location.href = res.data.response.link;
                }
            }
        );
    };

    return (
        <V2Container
            heading="Review & Pay"
            onBack={() => {
                if (isKyc || policyType === "TOPUP") {
                    setCurrentStep(3);
                } else {
                    setCurrentStep(2);
                }
            }}
            sx={{
                padding: {
                    xs: "0px",
                    sm: "24px",
                },
            }}
        >
            <Box sx={{ width: "100%" }}>
                <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing="24px"
                    justifyContent="space-between"
                >
                    <Box
                        sx={{
                            padding: "20px",
                        }}
                    >
                        <Box
                            sx={{
                                width: {
                                    xs: "100%",
                                    sm: "347px",
                                },
                            }}
                        >
                            <div className="kmdStep3_review">
                                <div className="kmdStep3_memberDetails mb40">
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Typography variant="subtitle1">
                                            Member Details
                                        </Typography>
                                        <Chip
                                            label="Change"
                                            variant="outlined"
                                            onClick={() => {
                                                setCurrentStep(1);
                                            }}
                                        />
                                    </Stack>
                                    <Box
                                        sx={{
                                            marginTop: "24px",
                                        }}
                                    >
                                        <Stack gap={1}>

                                        {members.map((member, index) => (
                                            <Box
                                                key={index}
                                                sx={{
                                                    border: "1px solid var(--components-paper-outlined, rgba(145, 158, 171, 0.16))",
                                                    padding: "16px 20px",
                                                    borderRadius: "24px",
                                                }}
                                            >
                                                <Stack
                                                    direction="row"
                                                    spacing="16px"
                                                    alignItems="center"
                                                >
                                                    <Box>
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent:
                                                                    "center",
                                                                alignItems:
                                                                    "center",
                                                                height: "40px",
                                                                width: "40px",
                                                                borderRadius:
                                                                    "50%",
                                                                background:
                                                                    getNewMemberBackground(
                                                                        member.relationShip
                                                                    ),
                                                                overflow:
                                                                    "hidden",
                                                            }}
                                                        >
                                                            <img
                                                                src={getMemberAvatar(
                                                                    member
                                                                )}
                                                                height={40}
                                                            />
                                                        </Box>
                                                    </Box>
                                                    <Box>
                                                        <Typography variant="subtitle2">
                                                            {member.name}
                                                        </Typography>
                                                        <Typography variant="body3">
                                                            {member.relationShip ===
                                                            "Employee"
                                                                ? "Self"
                                                                : member.relationShip}
                                                        </Typography>
                                                    </Box>
                                                </Stack>
                                            </Box>
                                        ))}
                                        </Stack>
                                    </Box>
                                </div>

                                <Box
                                    sx={{
                                        padding: "22px 0",
                                    }}
                                >
                                    <Divider
                                        sx={{
                                            border: "1px dashed rgb(217, 215, 224)",
                                        }}
                                    />
                                </Box>

                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent={"space-between"}
                                >
                                    <Typography variant="subtitle1">
                                        Personal Details
                                    </Typography>
                                    <Chip
                                        label="Change"
                                        variant="outlined"
                                        onClick={() => {
                                            setCurrentStep(2);
                                        }}
                                    />
                                </Stack>
                                <Box
                                    sx={{
                                        padding: "20px",
                                        border: "1px solid var(--components-paper-outlined, rgba(145, 158, 171, 0.16))",
                                        borderRadius: "24px",
                                        marginTop: "24px",
                                    }}
                                >
                                    <Stack gap={"32px"}>
                                        <Stack
                                            direction={"row"}
                                            gap={"8px"}
                                            alignItems={"center"}
                                        >
                                            <Box
                                                sx={{
                                                    backgroundColor:
                                                        theme.palette.grey[200],
                                                    borderRadius: "50%",
                                                    padding: "8px",
                                                }}
                                            >
                                                <Iconify
                                                    icon="mdi:phone-outline"
                                                    sx={{
                                                        height: "24px",
                                                        width: "24px",
                                                        color: theme.palette
                                                            .grey[600],
                                                    }}
                                                />
                                            </Box>
                                            <Typography variant="subtitle2">
                                                {memberInfo.phone}
                                            </Typography>
                                        </Stack>
                                        <Stack
                                            direction={"row"}
                                            gap={"8px"}
                                            alignItems={"center"}
                                        >
                                            <Box
                                                sx={{
                                                    backgroundColor:
                                                        theme.palette.grey[200],
                                                    borderRadius: "50%",
                                                    padding: "8px",
                                                }}
                                            >
                                                <Iconify
                                                    icon="ic:outline-cake"
                                                    sx={{
                                                        height: "24px",
                                                        width: "24px",
                                                        color: theme.palette
                                                            .grey[600],
                                                    }}
                                                />
                                            </Box>
                                            <Typography variant="subtitle2">
                                                {changeDateFormat(
                                                    "yyyy-mm-dd",
                                                    "dd-mmm-yyyy",
                                                    memberInfo.dob
                                                ).toString()}
                                            </Typography>
                                        </Stack>
                                        <Stack
                                            direction={"row"}
                                            gap={"8px"}
                                            alignItems={"center"}
                                        >
                                            <Box
                                                sx={{
                                                    backgroundColor:
                                                        theme.palette.grey[200],
                                                    borderRadius: "50%",
                                                    padding: "8px",
                                                }}
                                            >
                                                <Iconify
                                                    icon="material-symbols:location-on-outline-rounded"
                                                    sx={{
                                                        height: "24px",
                                                        width: "24px",
                                                        color: theme.palette
                                                            .grey[600],
                                                    }}
                                                />
                                            </Box>
                                            <Typography variant="body3">
                                                {memberInfo.addressLine1 +
                                                    " " +
                                                    memberInfo.addressLine2}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Box>
                                {/* <button
                                    className="buttonPrimary mobileOnly mt15 W100"
                                    onClick={submitForm}
                                >
                                    Next
                                </button> */}
                            </div>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            flex: 1,
                        }}
                    >
                        <KmdInvoice
                            submitForm={submitForm}
                            key="121321"
                            loader={loader}
                        />
                    </Box>
                </Stack>
            </Box>
        </V2Container>
    );
};

export default KmdStep4;
