import React, { useEffect } from "react";
import KmdInvoice from "./kmdInvoice";
import "./kmdStep2.scss";
import { Form, Formik, FormikProps, useFormikContext } from "formik";
import * as Yup from "yup";
import MyText from "../../../components/Input/MyText";
import Dropdown from "../../../components/Input/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
    Box,
    Button,
    Divider,
    IconButton,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { changeDateFormat } from "../../../util/date";
import { setGpi } from "../../../features/kmd/kmdSlice";
import alert from "../../../util/alerts";
import V2Container from "../../../components/Layout/V2Container";

const bannerImage =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/Super_TopUp/superIllustration.png";

interface Props {
    submit: (val: {
        // e
    }) => void;
    setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}

const KmdGpiStep2: React.FC<Props> = ({ submit, setCurrentStep }: Props) => {
    const { memberInfo, members, gpi, isKyc } = useSelector(
        (state: RootState) => state.kmd
    );
    const reducerDispatch = useDispatch();
    const formikRef = React.useRef<
        FormikProps<{
            claims: boolean;
            natureOfClaims: string;
            amountOfLoss: number;
            currentStatus: string;
            remidalMeasures: string;

            domesticTurnover: number;
            UsCaTurnover: number;
            EuAuTurnover: number;
            restTurnover: number;
        }>
    >(null);

    const [claims, setClaims] = React.useState<"Yes" | "No">(
        gpi ? "Yes" : "No"
    );

    useEffect(() => {
        document.body.style.backgroundColor = "white";
        return () => {
            document.body.style.backgroundColor = "#f6f6f6";
        };
    }, []);

    useEffect(() => {
        debugger;
        setClaims(gpi.isClaim ? "Yes" : "No");
    }, []);

    useEffect(() => {
        console.log("gpi", gpi, claims);
    }, [claims]);

    const handleSubmit = (values: {
        // claims: boolean;
        natureOfClaim: string;
        loss: number;
        currentStatus: string;
        remedialMeasures: string;

        saleInIndia: number;
        saleInUsCa: number;
        saleInEuAu: number;
        salesInOthers: number;
    }) => {
        console.log("submitting");

        if (
            values.saleInIndia +
                values.saleInUsCa +
                values.saleInEuAu +
                values.salesInOthers <=
            0
        ) {
            alert(
                "Total Turnover should be more than 0, For any queries please get in touch with us at gurgaon@kmdastur.com",
                "error"
            );
            return;
        }

        if (
            values.saleInIndia +
                values.saleInUsCa +
                values.saleInEuAu +
                values.salesInOthers >
            150000000
        ) {
            alert(
                "Total Turnover should not exceed 15 Crores, For any queries please get in touch with us at gurgaon@kmdastur.com",
                "error"
            );
            return;
        }

        reducerDispatch(
            setGpi({
                ...values,
                isClaim: claims === "Yes",
            })
        );
        if (isKyc) {
            setCurrentStep(3);
        } else {
            setCurrentStep(4);
        }
    };
    return (
        <V2Container
            heading="Business Information"
            onBack={() => {
                setCurrentStep(1);
            }}
            sx={{
                padding: {
                    xs: "0px",
                    sm: "24px",
                },
            }}
        >
            <Box
                sx={{
                    width: "100%",
                }}
            >
                <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing="24px"
                    justifyContent="space-between"
                >
                    <Formik
                        ref={formikRef}
                        initialValues={{
                            claims: gpi.isClaim,
                            natureOfClaim: gpi.natureOfClaim,
                            loss: gpi.loss,
                            currentStatus: gpi.currentStatus,
                            remedialMeasures: gpi.remedialMeasures,
                            saleInIndia: gpi.saleInIndia,
                            saleInUsCa: gpi.saleInUsCa,
                            saleInEuAu: gpi.saleInEuAu,
                            salesInOthers: gpi.salesInOthers,
                        }}
                        validationSchema={Yup.object({
                            saleInIndia: Yup.number().required("Required"),
                            saleInUsCa: Yup.number().required("Required"),
                            saleInEuAu: Yup.number().required("Required"),
                            salesInOthers: Yup.number().required("Required"),
                            ...(claims === "Yes" && {
                                natureOfClaim:
                                    Yup.string().required("Required"),
                                loss: Yup.number().required("Required"),
                                currentStatus:
                                    Yup.string().required("Required"),
                                remedialMeasures:
                                    Yup.string().required("Required"),
                            }),
                        })}
                        onSubmit={handleSubmit}
                    >
                        <>
                            <Box
                                sx={{
                                    padding: "20px",
                                }}
                            >
                                <Typography variant="subtitle1">
                                    Are there any Claims ?
                                    <div className="custom-form-group">
                                        <Dropdown
                                            title=""
                                            name="nomineeRelationship"
                                            className=" dropDownSuper colorUpdate"
                                            defaultVal={claims}
                                            items={["Yes", "No"].map(
                                                (relation) => ({
                                                    id: relation,
                                                    name: relation,
                                                })
                                            )}
                                            change={(val: any) => {
                                                setClaims(val);
                                            }}
                                        />
                                    </div>
                                </Typography>
                                <Form>
                                    <Box
                                        sx={{
                                            width: {
                                                xs: "100%",
                                                sm: "347px",
                                            },
                                        }}
                                    >
                                        {claims === "Yes" && (
                                            <>
                                                <MyText
                                                    label="Nature of Claims"
                                                    name="natureOfClaim"
                                                    type="text"
                                                    placeholder=""
                                                    className=""
                                                    onChange={() => {}}
                                                />
                                                <MyText
                                                    label="Amount of Loss (INR)"
                                                    name="loss"
                                                    type="number"
                                                    placeholder=""
                                                    className=""
                                                    onChange={() => {}}
                                                />
                                                <MyText
                                                    label="Current Status"
                                                    name="currentStatus"
                                                    type="text"
                                                    className=""
                                                    placeholder=""
                                                    onChange={() => {}}
                                                />

                                                <MyText
                                                    label="Remedial measures"
                                                    name="remedialMeasures"
                                                    type="text"
                                                    placeholder=""
                                                    className=""
                                                    onChange={() => {}}
                                                />
                                            </>
                                        )}
                                        <MyText
                                            label="Turnover Domestic Sales in India (INR)"
                                            name="saleInIndia"
                                            type="number"
                                            placeholder=""
                                            className=""
                                            onChange={() => {}}
                                        />
                                        <MyText
                                            label="Turnover Exports to USA Canada (INR)"
                                            name="saleInUsCa"
                                            type="number"
                                            placeholder=""
                                            className=""
                                            onChange={() => {}}
                                        />
                                        <MyText
                                            label="Turnover Exports to Europe Australia (INR)"
                                            name="saleInEuAu"
                                            type="number"
                                            placeholder=""
                                            className=""
                                            onChange={() => {}}
                                        />
                                        <MyText
                                            label="Turnover Exports to Rest of the world (INR)"
                                            name="salesInOthers"
                                            type="number"
                                            placeholder=""
                                            className=""
                                            onChange={() => {}}
                                        />
                                        {/* <button
                                            className="buttonPrimary mobileOnly mt15 W100"
                                            type="submit"
                                        >
                                            Next
                                        </button> */}
                                    </Box>
                                </Form>
                            </Box>
                            <Box
                                sx={{
                                    flex: 1,
                                }}
                            >
                                <FormContainer handleSubmit={handleSubmit} />
                                {/* <KmdInvoice /> */}
                            </Box>
                        </>
                    </Formik>
                </Stack>
            </Box>
        </V2Container>
    );
};

export default KmdGpiStep2;

const FormContainer = ({ handleSubmit }: any) => {
    let { submitForm } = useFormikContext();
    return <KmdInvoice submitForm={submitForm} />;
};
