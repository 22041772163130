import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
    Box,
    Button,
    Divider,
    Drawer,
    Popover,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Iconify from "../../components/iconify";
import InfoComponent from "../../components/InfoComponent/infoComponent";
import { useNavigate, useParams } from "react-router-dom";
import service from "../../util/axiosConfig";

import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { styled } from "@mui/system";
import { Close } from "@mui/icons-material";
import {
    CARD_POST_DOWNLOAD,
    CARD_POST_DOWNLOAD_TRAVEL,
    SHARE_PDF,
} from "../../util/config";
import { handleResponseAlert, URLtoBlobDownload } from "../../util/functions";
import advService from "../../util/advService";
import { GET_HEALTH_CARD } from "../../util/newconfig";
import { AxiosResponse } from "axios";
import { JavaResponse } from "../../types";
import { PolicyMembers } from "../../features/policy/policySlice";

const StyledBox = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    minHeight: "46px",
}));

const Container = styled(Box)(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "16px",
}));
const NewMembers = () => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const isMobile = useMediaQuery("(max-width: 768px)");
    const { policyDetailDto } = useSelector((state: RootState) => state.policy);

    const { pid, uuid } = useParams();

    type Member = {
        sumInsured?: string;
        dob?: string;
        name: string;
        claimable?: number;
        relation: string;
        relationDp: string;
        healthCardId?: string;
        uid?: string;
        relation_ship?: string;
        gender?: string;
        employeeId?: string;
        dom?: string;
        policyType?: string;
        deductible?: string;
    };

    const [currentMember, setCurrentMember] = useState<PolicyMembers>(
        policyDetailDto?.detailedInfo?.members?.[0] || {
            name: "",
            relation: "",
            relationDp: "",
        }
    );

    useEffect(() => {
        setCurrentMember(
            policyDetailDto?.detailedInfo?.members?.[0] || {
                name: "",
                relation: "",
                relationDp: "",
            }
        );
    }, [policyDetailDto]);

    const navigate = useNavigate();

    const getRelationshipImage = (relation: string): string => {
        switch (relation) {
            case "Wife":
                return "https://imtbepublic.s3.ap-south-1.amazonaws.com/Portal+assets/Native+art/Wife.png";
            case "Mother":
                return "https://imtbepublic.s3.ap-south-1.amazonaws.com/Portal+assets/Native+art/Mother.png";
            case "Son":
                return "https://imtbepublic.s3.ap-south-1.amazonaws.com/Portal+assets/Native+art/Son.png";
            case "Daughter":
                return "https://imtbepublic.s3.ap-south-1.amazonaws.com/Portal+assets/Native+art/Daughter.png";
            case "Father":
                return "https://imtbepublic.s3.ap-south-1.amazonaws.com/Portal+assets/Native+art/Father.png";
            case "Husband":
                return "https://imtbepublic.s3.ap-south-1.amazonaws.com/Portal+assets/Native+art/Husband.png";
            default:
                return "https://imtbepublic.s3.ap-south-1.amazonaws.com/Portal+assets/Native+art/Husband.png";
        }
    };

    const scrollRef = useRef<HTMLDivElement>(null);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const [open, setOpen] = useState(false);

    const [expandRiskLocation, setExpandRiskLocation] = useState(false);

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const openMenu = Boolean(anchorEl);

    const id = openMenu ? "simple-popover" : undefined;

    const popoverRef = useRef<HTMLDivElement>(null);

    const [isScrollable, setIsScrollable] = useState(false);

    // useLayoutEffect(() => {
    const checkScrollable = () => {
        if (scrollRef.current) {
            return (
                scrollRef.current.scrollWidth > scrollRef.current.clientWidth
            );
        }
    };

    //     checkScrollable();
    //     window.addEventListener("resize", checkScrollable);

    //     return () => {
    //         window.removeEventListener("resize", checkScrollable);
    //     };
    // }, [pid, uuid, scrollRef]);

    const scrollLeft = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({ left: -100, behavior: "smooth" });
        }
    };

    const scrollRight = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({ left: 100, behavior: "smooth" });
        }
    };

    const theme = useTheme();

    const getPopoverPosition = (): {
        vertical: "top" | "bottom";
        horizontal: "center";
    } => {
        if (!anchorEl) return { vertical: "bottom", horizontal: "center" };

        const anchorRect = anchorEl.getBoundingClientRect();
        const popoverHeight = popoverRef.current
            ? popoverRef.current.clientHeight
            : 0;
        const spaceBelow = window.innerHeight - anchorRect.bottom;
        const spaceAbove = anchorRect.top;

        if (spaceBelow >= popoverHeight) {
            return { vertical: "bottom", horizontal: "center" };
        } else if (spaceAbove >= popoverHeight) {
            return { vertical: "top", horizontal: "center" };
        } else {
            return { vertical: "bottom", horizontal: "center" };
        }
    };

    const { vertical, horizontal } = getPopoverPosition();

    const handleShareClick = () => {
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

        if (
            currentMember?.healthCardId &&
            currentMember?.healthCardId !== "-"
        ) {
            service
                .post(SHARE_PDF.replace("{pId}", pid as string), {
                    id: currentMember?.uid,
                })
                .then((response) => {
                    const pdfUrl = response.data.response.link;
                    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(
                        `Here is your health card: ${pdfUrl}`
                    )}`;
                    if (isIOS) {
                        window.location.href = whatsappUrl;
                    } else {
                        window.open(whatsappUrl, "_blank");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching the PDF URL:", error);
                });
        }
    };

    const renderCategoryBasedContent = () => {
        switch (policyDetailDto?.insuranceCategory) {
            case "Motor":
                return (
                    <>
                        <Stack
                            gap={{ xs: "20px", sm: "16px" }}
                            direction={"column"}
                            width={"100%"}
                            sx={{
                                padding: { xs: "20px 16px", sm: "0" },
                                position: "relative",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    padding: "4px",
                                    justifyContent: "center",
                                    height: "48px",
                                    alignItems: "center",
                                    borderRadius: "16px",
                                    width: "48px",
                                    cursor: "pointer",
                                    border: `2px solid ${theme.palette.brand.main}`,
                                    transition: "border-color 0.3s ease",
                                }}
                            >
                                <img
                                    src={
                                        policyDetailDto?.detailedInfo
                                            ?.policyTypeDp
                                    }
                                    alt="motor"
                                    style={{
                                        width: "40px",
                                        height: "40px",
                                        padding: "4px 4.695px 0px 4px",
                                        borderRadius: "13px",
                                    }}
                                />
                            </Box>
                            <Divider variant="dashed" />
                            <Container>
                                {policyDetailDto?.detailedInfo?.make && (
                                    <StyledBox>
                                        <Typography
                                            variant="body3"
                                            color={theme.palette.text.secondary}
                                        >
                                            Make
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            color={theme.palette.text.primary}
                                        >
                                            {
                                                policyDetailDto?.detailedInfo
                                                    ?.make
                                            }
                                        </Typography>
                                    </StyledBox>
                                )}
                                {policyDetailDto?.detailedInfo?.model && (
                                    <StyledBox>
                                        <Typography
                                            variant="body3"
                                            color={theme.palette.text.secondary}
                                        >
                                            Model
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            color={theme.palette.text.primary}
                                        >
                                            {
                                                policyDetailDto?.detailedInfo
                                                    ?.model
                                            }
                                        </Typography>
                                    </StyledBox>
                                )}
                                {policyDetailDto?.detailedInfo?.ownerName && (
                                    <StyledBox>
                                        <Typography
                                            variant="body3"
                                            color={theme.palette.text.secondary}
                                        >
                                            Owner
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            color={theme.palette.text.primary}
                                        >
                                            {
                                                policyDetailDto?.detailedInfo
                                                    ?.ownerName
                                            }
                                        </Typography>
                                    </StyledBox>
                                )}
                                {policyDetailDto?.detailedInfo
                                    ?.registrationNumber && (
                                    <StyledBox>
                                        <Typography
                                            variant="body3"
                                            color={theme.palette.text.secondary}
                                        >
                                            Number
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            color={theme.palette.text.primary}
                                        >
                                            {
                                                policyDetailDto?.detailedInfo
                                                    ?.registrationNumber
                                            }
                                        </Typography>
                                    </StyledBox>
                                )}
                                {/* {policyDetailDto?.detailedInfo?.idv && (
                                    <StyledBox>
                                        <Typography
                                            variant="body3"
                                            color={theme.palette.text.secondary}
                                        >
                                            Idv
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            color={theme.palette.text.primary}
                                        >
                                            {policyDetailDto?.detailedInfo?.idv}
                                        </Typography>
                                    </StyledBox>
                                )} */}
                            </Container>
                        </Stack>
                    </>
                );
            case "Property":
                return (
                    <Stack
                        gap={{ xs: "20px", sm: "16px" }}
                        direction={"column"}
                        width={"100%"}
                        sx={{
                            padding: { xs: "20px 16px", sm: "0" },
                            position: "relative",
                        }}
                    >
                        <Stack
                            flexDirection={"row"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            width={"100%"}
                        >
                            <Typography variant="subtitle1">
                                {`Risk location${
                                    policyDetailDto?.detailedInfo?.location
                                        ?.length === 1
                                        ? ""
                                        : "s"
                                }`}
                            </Typography>
                            {(policyDetailDto?.detailedInfo?.location?.length ??
                                0) > 1 && (
                                <Typography
                                    sx={{
                                        fontSize: "12px",
                                        color: theme.palette.brand.main,
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        setOpen(true);
                                    }}
                                >
                                    {`View all locations(${policyDetailDto?.detailedInfo?.location?.length})`}{" "}
                                    <Iconify
                                        icon={
                                            "material-symbols:arrow-right-alt"
                                        }
                                    />
                                </Typography>
                            )}
                        </Stack>

                        <Box
                            sx={{
                                display: "flex",
                                padding: "4px",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "48px",
                                height: "48px",
                                borderRadius: "16px",
                                cursor: "pointer",
                                border: `2px solid ${theme.palette.brand.main}`,
                            }}
                        >
                            <img
                                src={
                                    policyDetailDto?.detailedInfo?.policyTypeDp
                                }
                                alt="property"
                                style={{
                                    width: "40px",
                                    height: "40px",
                                    padding: "4px",
                                    borderRadius: "13px",
                                }}
                            />
                        </Box>
                        <Divider
                            variant="dashed"
                            sx={{
                                marginBottom: "8px",
                            }}
                        />
                        <Container>
                            {policyDetailDto?.detailedInfo?.location?.length ===
                                1 && (
                                <>
                                    {policyDetailDto?.detailedInfo?.location[0]
                                        ?.address && (
                                        <StyledBox
                                            sx={{
                                                gridColumn: "1 / -1",
                                            }}
                                        >
                                            <Typography
                                                variant="body3"
                                                color={
                                                    theme.palette.text.secondary
                                                }
                                            >
                                                Address
                                            </Typography>
                                            <Typography
                                                variant="subtitle2"
                                                sx={{
                                                    wordBreak: "break-word",
                                                }}
                                                color={
                                                    theme.palette.text.primary
                                                }
                                            >
                                                {`${
                                                    policyDetailDto
                                                        ?.detailedInfo
                                                        ?.location[0]?.address
                                                }${
                                                    policyDetailDto
                                                        ?.detailedInfo
                                                        ?.location[0]?.pincode
                                                        ? `, ${policyDetailDto?.detailedInfo?.location[0]?.pincode}`
                                                        : ""
                                                }`}
                                            </Typography>
                                        </StyledBox>
                                    )}

                                    {/* {policyDetailDto?.detailedInfo?.location[0]
                                        ?.pincode && (
                                        <StyledBox>
                                            <Typography
                                                variant="body3"
                                                color={
                                                    theme.palette.text.secondary
                                                }
                                            >
                                                Pincode
                                            </Typography>
                                            <Typography
                                                variant="subtitle2"
                                                sx={{
                                                    wordBreak: "break-word",
                                                }}
                                                color={
                                                    theme.palette.text.primary
                                                }
                                            >
                                                {
                                                    policyDetailDto
                                                        ?.detailedInfo
                                                        ?.location[0]?.pincode
                                                }
                                            </Typography>
                                        </StyledBox>
                                    )} */}
                                </>
                            )}
                            {policyDetailDto?.detailedInfo?.locationTotal
                                ?.stockSiTotal && (
                                <StyledBox>
                                    <Typography
                                        variant="body3"
                                        color={theme.palette.text.secondary}
                                    >
                                        {`${
                                            (policyDetailDto?.detailedInfo
                                                ?.location?.length ?? 0) > 1
                                                ? "Total "
                                                : ""
                                        }Stock SI`}
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        color={theme.palette.text.primary}
                                    >
                                        {
                                            policyDetailDto?.detailedInfo
                                                ?.locationTotal?.stockSiTotal
                                        }
                                    </Typography>
                                </StyledBox>
                            )}
                            {policyDetailDto?.detailedInfo?.locationTotal
                                ?.otherSiTotal && (
                                <StyledBox>
                                    <Typography
                                        variant="body3"
                                        color={theme.palette.text.secondary}
                                    >
                                        {`${
                                            (policyDetailDto?.detailedInfo
                                                ?.location?.length ?? 0) > 1
                                                ? "Total "
                                                : ""
                                        }Additional SI`}
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        color={theme.palette.text.primary}
                                    >
                                        {
                                            policyDetailDto?.detailedInfo
                                                ?.locationTotal?.otherSiTotal
                                        }
                                    </Typography>
                                </StyledBox>
                            )}
                            {policyDetailDto?.detailedInfo?.locationTotal
                                ?.buildingSiTotal && (
                                <StyledBox>
                                    <Typography
                                        variant="body3"
                                        color={theme.palette.text.secondary}
                                    >
                                        {`${
                                            (policyDetailDto?.detailedInfo
                                                ?.location?.length ?? 0) > 1
                                                ? "Total "
                                                : ""
                                        }Building SI`}
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        color={theme.palette.text.primary}
                                    >
                                        {
                                            policyDetailDto?.detailedInfo
                                                ?.locationTotal?.buildingSiTotal
                                        }
                                    </Typography>
                                </StyledBox>
                            )}
                            {policyDetailDto?.detailedInfo?.locationTotal
                                ?.fffSiTotal && (
                                <StyledBox>
                                    <Typography
                                        variant="body3"
                                        color={theme.palette.text.secondary}
                                    >
                                        {`${
                                            (policyDetailDto?.detailedInfo
                                                ?.location?.length ?? 0) > 1
                                                ? "Total "
                                                : ""
                                        }FFF SI`}
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        color={theme.palette.text.primary}
                                    >
                                        {
                                            policyDetailDto?.detailedInfo
                                                ?.locationTotal?.fffSiTotal
                                        }
                                    </Typography>
                                </StyledBox>
                            )}
                            {policyDetailDto?.detailedInfo?.locationTotal
                                ?.pmSiTotal && (
                                <StyledBox>
                                    <Typography
                                        variant="body3"
                                        color={theme.palette.text.secondary}
                                    >
                                        {`${
                                            (policyDetailDto?.detailedInfo
                                                ?.location?.length ?? 0) > 1
                                                ? "Total "
                                                : ""
                                        }Plant & Machinary SI`}
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        color={theme.palette.text.primary}
                                    >
                                        {
                                            policyDetailDto?.detailedInfo
                                                ?.locationTotal?.pmSiTotal
                                        }
                                    </Typography>
                                </StyledBox>
                            )}
                        </Container>
                    </Stack>
                );
            default:
                const shouldShowStack =
                    (currentMember?.healthCardId &&
                        currentMember?.healthCardId !== "-" &&
                        policyDetailDto?.detailedInfo?.whatsappDownload) ||
                    (policyDetailDto?.insuranceCategory === "Group" &&
                        policyDetailDto?.detailedInfo?.isEdit);
                const actions = [
                    policyDetailDto?.insuranceCategory === "Group" &&
                        policyDetailDto?.detailedInfo?.isEdit && (
                            <Button
                                variant="text"
                                size="small"
                                sx={{
                                    flex: 1,
                                    backgroundColor: "#FFF",
                                    color: theme.palette.text.primary,
                                    width: '100%',
                                }}
                                startIcon={
                                    <Iconify
                                        icon="material-symbols:edit-outline"
                                        width={"16px"}
                                        height={"16px"}
                                        color={theme.palette.text.primary}
                                        className="iconify-icon"
                                    />
                                }
                                onClick={() => {
                                    const queryParams = new URLSearchParams({
                                        member: encodeURIComponent(
                                            JSON.stringify(currentMember)
                                        ),
                                        pid: encodeURIComponent(pid as string),
                                        uuid: encodeURIComponent(
                                            uuid as string
                                        ),
                                    }).toString();

                                    navigate(
                                        `/plans/newEditMember?${queryParams}`
                                    );
                                }}
                            >
                                <Typography
                                    sx={{
                                        textTransform: "none",
                                        color: theme.palette.text.primary,
                                    }}
                                    variant="overline"
                                >
                                    Edit member
                                </Typography>
                            </Button>
                        ),
                    currentMember?.healthCardId &&
                        currentMember?.healthCardId !== "-" &&
                        policyDetailDto?.detailedInfo?.whatsappDownload && (
                            <Button
                                variant="text"
                                size="small"
                                sx={{
                                    flex: 1,
                                    backgroundColor: "#FFF",
                                    color: theme.palette.text.primary,
                                    width: '100%',
                                }}
                                startIcon={
                                    <Iconify
                                        icon="mdi:whatsapp"
                                        width={"16px"}
                                        height={"16px"}
                                        color={theme.palette.text.primary}
                                        className="iconify-icon"
                                    />
                                }
                                onClick={handleShareClick}
                            >
                                <Typography
                                    sx={{
                                        textTransform: "none",
                                        color: theme.palette.text.primary,
                                    }}
                                    variant="overline"
                                >
                                    {policyDetailDto?.detailedInfo
                                        ?.policyType === "GTRAVEL"
                                        ? "Policy"
                                        : "Health Card"}
                                </Typography>
                            </Button>
                        ),
                ];
                const singleAction =
                    actions.filter(Boolean).length === 1
                        ? actions.filter(Boolean)[0]
                        : null;

                return (
                    <>
                        <Stack
                            gap={{ xs: "20px", sm: "16px" }}
                            direction={"column"}
                            width={"100%"}
                            sx={{
                                padding: { xs: "20px 16px 20px 16px", sm: "0" },
                                position: "relative",
                            }}
                        >
                            {policyDetailDto?.insuranceCategory === "Group" && (
                                <>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            gap: "4px",
                                            alignSelf: "stretch",
                                        }}
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            color={theme.palette.text.primary}
                                        >
                                            Your policy can cover
                                        </Typography>
                                        <Typography
                                            variant="label"
                                            color={theme.palette.text.primary}
                                        >
                                            {
                                                policyDetailDto?.detailedInfo
                                                    ?.covers
                                            }
                                        </Typography>
                                    </Box>
                                    <Divider variant="dashed" />
                                </>
                            )}
                            {!isMobile && checkScrollable() && (
                                <div
                                    style={{
                                        position: "absolute",
                                        left: "-10px",
                                        top:
                                            policyDetailDto?.insuranceCategory ===
                                            "Group"
                                                ? "100px"
                                                : "20px",
                                        display: "flex",
                                        width: "28px",
                                        height: "28px",
                                        padding: "6px",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: "500px",
                                        border: "1px solid rgba(255, 255, 255, 0.00)",
                                        background: "#FFF",
                                        boxShadow:
                                            "0px 4px 10px 0px rgba(20, 20, 43, 0.04)",
                                        cursor: "pointer",
                                    }}
                                    onClick={scrollLeft}
                                >
                                    <Iconify
                                        icon="fluent:ios-arrow-left-24-filled"
                                        width={"16px"}
                                        height={"16px"}
                                    />
                                </div>
                            )}
                            <Box
                                sx={{
                                    display: "flex",
                                    overflowX: "auto",
                                    whiteSpace: "nowrap",
                                    gap: "16px",
                                    height: "71px",
                                    alignItems: "center",
                                    alignSelf: "stretch",
                                    flexDirection: "row",
                                    width: "100%",
                                    "&::-webkit-scrollbar": { display: "none" },
                                }}
                                ref={scrollRef}
                            >
                                {policyDetailDto?.detailedInfo?.members?.map(
                                    (data, index: number) => (
                                        <Stack
                                            direction={"column"}
                                            alignItems={"center"}
                                            minWidth={"52px"}
                                        >
                                            <Box
                                                key={index}
                                                sx={{
                                                    display: "flex",
                                                    padding: "4px",
                                                    width: "48px",
                                                    height: "48px",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    borderRadius: "16px",
                                                    cursor: "pointer",
                                                    border:
                                                        selectedIndex === index
                                                            ? `2px solid ${theme.palette.brand.main}`
                                                            : "2px solid transparent",
                                                    transition:
                                                        "border-color 0.3s ease",
                                                }}
                                                onClick={() => {
                                                    setSelectedIndex(index);
                                                    setCurrentMember(data);
                                                }}
                                            >
                                                <img
                                                    src={data?.relationDp}
                                                    alt={`image-${index}`}
                                                    style={{
                                                        borderRadius: "13px",
                                                        width: "40px",
                                                        height: "40px",
                                                    }}
                                                />
                                            </Box>
                                            <Typography
                                                variant={
                                                    selectedIndex === index
                                                        ? "label"
                                                        : "mobileLabel"
                                                }
                                                color={
                                                    selectedIndex === index
                                                        ? theme.palette.text
                                                              .primary
                                                        : theme.palette.text
                                                              .secondary
                                                }
                                            >
                                                {data?.relation}
                                            </Typography>
                                        </Stack>
                                    )
                                )}
                                {currentMember?.policyType === "GMC" &&
                                    policyDetailDto?.detailedInfo?.isAdd &&
                                    (policyDetailDto?.detailedInfo
                                        ?.d_demography ?? 0) >= 2 && (
                                        <Stack
                                            direction={"column"}
                                            alignItems={"center"}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    padding: "4px",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    borderRadius: "16px",
                                                    cursor: "pointer",
                                                    background: "white",
                                                    width: "48px",
                                                    height: "48px",
                                                    border: `2px dashed ${theme.palette.grey[100]}`,
                                                }}
                                                onClick={() => {
                                                    const queryParams =
                                                        new URLSearchParams({
                                                            pid: encodeURIComponent(
                                                                pid as string
                                                            ),
                                                            uuid: encodeURIComponent(
                                                                uuid as string
                                                            ),
                                                            member: encodeURIComponent(
                                                                JSON.stringify({
                                                                    employeeId:
                                                                        currentMember.employeeId,
                                                                })
                                                            ),
                                                        }).toString();

                                                    navigate(
                                                        `/plans/newEditMember?${queryParams}`
                                                    );
                                                }}
                                            >
                                                <Iconify
                                                    icon="material-symbols:add"
                                                    width={"24px"}
                                                    height={"24px"}
                                                    color={
                                                        theme.palette.brand.main
                                                    }
                                                />
                                            </Box>
                                            <Typography
                                                variant="mobileLabel"
                                                color={
                                                    theme.palette.text.secondary
                                                }
                                            >
                                                Member
                                            </Typography>
                                        </Stack>
                                    )}
                            </Box>
                            {!isMobile && checkScrollable() && (
                                <div
                                    style={{
                                        position: "absolute",
                                        right: "-10px",
                                        top:
                                            policyDetailDto?.insuranceCategory ===
                                            "Group"
                                                ? "100px"
                                                : "20px",
                                        display: "flex",
                                        width: "28px",
                                        height: "28px",
                                        padding: "6px",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: "500px",
                                        border: "1px solid rgba(255, 255, 255, 0.00)",
                                        background: "#FFF",
                                        boxShadow:
                                            "0px 4px 10px 0px rgba(20, 20, 43, 0.04)",
                                    }}
                                    onClick={scrollRight}
                                >
                                    <Iconify
                                        icon="fluent:ios-arrow-right-24-filled"
                                        sx={{
                                            width: "16px",
                                            height: "16px",
                                            cursor: "pointer",
                                        }}
                                    />
                                </div>
                            )}
                            {currentMember?.info && (
                                <Box
                                    sx={{
                                        display: "flex",
                                        padding: "4px 4px 4px 4px",
                                        alignItems: "center",
                                        gap: "12px",
                                        alignSelf: "stretch",
                                        borderRadius: "8px",
                                        background: (theme) =>
                                            theme.palette.info.main,
                                    }}
                                >
                                    <Iconify
                                        icon="material-symbols:info"
                                        width={"16px"}
                                        height={"16px"}
                                        color={"white"}
                                    />
                                    <Typography
                                        variant="mobileLabel"
                                        color="white"
                                    >
                                        {currentMember.info}
                                    </Typography>
                                </Box>
                            )}

                            <Container>
                                <StyledBox>
                                    <Typography
                                        variant="body3"
                                        color={theme.palette.text.secondary}
                                    >
                                        Name
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        color={theme.palette.text.primary}
                                    >
                                        {currentMember.name}
                                    </Typography>
                                </StyledBox>
                                <StyledBox>
                                    <Typography
                                        variant="body3"
                                        color={theme.palette.text.secondary}
                                    >
                                        Relationship
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        color={theme.palette.text.primary}
                                    >
                                        {currentMember.relation}
                                    </Typography>
                                </StyledBox>
                                {currentMember?.sumInsured && (
                                    <StyledBox>
                                        <Typography
                                            variant="body3"
                                            color={theme.palette.text.secondary}
                                        >
                                            Sum Insured
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            color={theme.palette.text.primary}
                                        >
                                            {currentMember.sumInsured}
                                        </Typography>
                                    </StyledBox>
                                )}
                                {currentMember?.deductible && (
                                    <StyledBox>
                                        <Typography
                                            variant="body3"
                                            color={theme.palette.text.secondary}
                                        >
                                            Deductible
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            color={theme.palette.text.primary}
                                        >
                                            {currentMember.deductible}
                                        </Typography>
                                    </StyledBox>
                                )}
                                {currentMember?.coverageStartDate && (
                                    <StyledBox>
                                        <Typography
                                            variant="body3"
                                            color={theme.palette.text.secondary}
                                        >
                                            Coverage Start Date
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            color={theme.palette.text.primary}
                                        >
                                            {currentMember.coverageStartDate}
                                        </Typography>
                                    </StyledBox>
                                )}
                                {currentMember?.healthCardId && (
                                    <StyledBox>
                                        <Typography
                                            variant="body3"
                                            color={theme.palette.text.secondary}
                                        >
                                            {policyDetailDto?.detailedInfo
                                                ?.policyType === "GTRAVEL"
                                                ? "Certificate Number"
                                                : "Health ID"}
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            color={theme.palette.text.primary}
                                            sx={{
                                                wordBreak: "break-word",
                                            }}
                                        >
                                            {currentMember.healthCardId === "-"
                                                ? "Processing..."
                                                : currentMember.healthCardId}
                                        </Typography>
                                    </StyledBox>
                                )}

                                {/* {currentMember?.claimable && (
                                    <StyledBox>
                                        <Typography
                                            variant="body3"
                                            color={theme.palette.text.secondary}
                                        >
                                            Claimable
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            color={theme.palette.text.primary}
                                        >
                                            {currentMember.claimable}
                                        </Typography>
                                    </StyledBox>
                                )} */}
                                {currentMember?.dob && (
                                    <StyledBox>
                                        <Typography
                                            variant="body3"
                                            color={theme.palette.text.secondary}
                                        >
                                            Date of Birth
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            color={theme.palette.text.primary}
                                        >
                                            {currentMember.dob}
                                        </Typography>
                                    </StyledBox>
                                )}
                            </Container>
                        </Stack>
                        {shouldShowStack && <Divider variant="dashed" />}
                        {shouldShowStack && (
                            <Stack
                                direction="row"
                                gap={"16px"}
                                width={"100%"}
                                sx={{
                                    padding: { xs: "0px 16px", sm: "0" },
                                    paddingBottom: { xs: "20px", sm: "0" },
                                }}
                            >
                                {singleAction ? (
                                    singleAction
                                ) : (
                                    <>
                                        <Button
                                            variant="text"
                                            size="small"
                                            sx={{
                                                flex: 1,
                                                backgroundColor: "#FFF",
                                            }}
                                            endIcon={
                                                <Iconify
                                                    icon="material-symbols:pending-outline"
                                                    color={(theme) =>
                                                        theme.palette.text
                                                            .primary
                                                    }
                                                    width={"16px"}
                                                    height={"16px"}
                                                />
                                            }
                                            onClick={handleOpenMenu}
                                        >
                                            <Typography
                                                sx={{
                                                    textTransform: "none",
                                                }}
                                                variant="overline"
                                                color={(theme) =>
                                                    theme.palette.text.primary
                                                }
                                            >
                                                More actions
                                            </Typography>
                                        </Button>
                                        <Popover
                                            id={id}
                                            open={openMenu}
                                            anchorEl={anchorEl}
                                            onClose={handleOpenMenu}
                                            ref={popoverRef}
                                            anchorOrigin={{
                                                vertical,
                                                horizontal,
                                            }}
                                            transformOrigin={{
                                                vertical:
                                                    vertical === "bottom"
                                                        ? "top"
                                                        : "bottom",
                                                horizontal: "center",
                                            }}
                                            sx={{
                                                "& .MuiPaper-root": {
                                                    display: "flex",
                                                    width: "234px",
                                                    padding: "16px",
                                                    flexDirection: "column",
                                                    alignItems: "flex-start",
                                                    cursor: "pointer",
                                                    gap: "8px",
                                                    borderRadius: "12px",
                                                    border: "1px solid #ECEFFF",
                                                    background: "#FFF",
                                                    boxShadow:
                                                        "0px 0px 22px 0px rgba(0, 0, 0, 0.07)",
                                                },
                                                "& .button": {
                                                    minHeight: "32px",
                                                },
                                                "& .MuiTypography-root": {
                                                    fontFamily: "Poppins",
                                                    fontSize: 14,
                                                    fontStyle: "normal",
                                                    fontWeight: 500,
                                                    margin: 0,
                                                    letterSpacing: 0,
                                                    lineHeight: "22px",
                                                },
                                                "& .iconify-icon": {
                                                    width: "22px",
                                                    height: "22px",
                                                },
                                            }}
                                        >
                                            {actions}
                                        </Popover>
                                    </>
                                )}
                                {currentMember?.healthCardId &&
                                    currentMember?.healthCardId !== "-" && (
                                        <Button
                                            variant="contained"
                                            size="small"
                                            sx={{
                                                flex: 1,
                                                backgroundColor:
                                                    theme.palette.brand.main,
                                            }}
                                            endIcon={
                                                <Iconify
                                                    icon="material-symbols:download"
                                                    width={"16px"}
                                                    height={"16px"}
                                                />
                                            }
                                            onClick={() => {
                                                if (
                                                    policyDetailDto
                                                        ?.detailedInfo
                                                        ?.policyType ===
                                                    "GTRAVEL"
                                                ) {
                                                    service
                                                        .post(
                                                            CARD_POST_DOWNLOAD_TRAVEL.replace(
                                                                "{pid}",
                                                                pid as string
                                                            ),
                                                            {
                                                                id: currentMember?.uid,
                                                            }
                                                        )
                                                        .then((response) => {
                                                            const pdfUrl =
                                                                response.data
                                                                    .response
                                                                    .link;
                                                            URLtoBlobDownload(
                                                                pdfUrl,
                                                                `${currentMember?.healthCardId}`
                                                            );
                                                        });
                                                } else {
                                                    service.getPostDownload(
                                                        CARD_POST_DOWNLOAD.replace(
                                                            "{pid}",
                                                            pid as string
                                                        ),
                                                        {
                                                            id: currentMember?.uid,
                                                        },

                                                        currentMember.healthCardId +
                                                            ".pdf",
                                                        handleResponseAlert
                                                    );
                                                }
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    textTransform: "none",
                                                    // fontSize: {
                                                    //     xs: "11px",
                                                    //     sm: "",
                                                    // },
                                                }}
                                                variant="overline"
                                                color={"#fff"}
                                            >
                                                {policyDetailDto?.detailedInfo
                                                    ?.policyType === "GTRAVEL"
                                                    ? "Policy"
                                                    : "Health Card"}
                                            </Typography>
                                        </Button>
                                    )}
                            </Stack>
                        )}
                    </>
                );
        }
    };

    return (
        <Stack
            direction={"column"}
            width={"100%"}
            sx={{
                gap: { xs: "20px", sm: "40px" },
            }}
            // justifyContent={"space-between"}
            height={"100%"}
        >
            <Stack
                width={"100%"}
                sx={{
                    backgroundColor: {
                        xs: theme.palette.grey[200],
                        sm: "transparent",
                    },
                    gap: { xs: "20px", sm: "40px" },
                    borderBottomRightRadius: { xs: "24px", sm: "0px" },
                    borderBottomLeftRadius: { xs: "24px", sm: "0px" },
                }}
            >
                {renderCategoryBasedContent()}
            </Stack>

            {["GMC", "GTOPUP", "PARENTAL", "PARENTALGTOPUP"].includes(
                policyDetailDto?.detailedInfo?.policyType as string
            ) && (
                <Button
                    variant="outlined"
                    size="small"
                    fullWidth
                    sx={{
                        backgroundColor: "#fff",
                        color: "#0C1230",
                    }}
                    endIcon={
                        <Iconify
                            icon="material-symbols:arrow-right-alt"
                            width={"16px"}
                            height={"16px"}
                        />
                    }
                    onClick={() => {
                        navigate("/claims");
                    }}
                >
                    <Typography
                        variant="overline"
                        sx={{
                            textTransform: "none",
                        }}
                    >
                        Claim Insurance
                    </Typography>
                </Button>
            )}
            <Drawer
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                anchor={isMobile ? "bottom" : "right"}
                sx={{
                    "& .MuiDrawer-paper": {
                        padding: "24px 20px;",
                        display: "flex",
                        width: isMobile ? "100%" : "375px",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        flexShrink: 0,
                        borderRadius: {
                            xs: "24px 24px 0px 0px",
                            sm: "24px 0px 0px 24px",
                        },
                        backgroundColor: "#FFF",
                    },
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "16px",
                        alignSelf: "stretch",
                    }}
                >
                    <Stack
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        width={"100%"}
                    >
                        <Typography
                            variant="h5"
                            sx={{
                                color: (theme) => theme.palette.text.primary,
                            }}
                            margin={0}
                        >
                            {`Risk locations (${policyDetailDto?.detailedInfo?.location?.length}) `}
                        </Typography>
                        <Close
                            onClick={() => {
                                setOpen(false);
                            }}
                            style={{ cursor: "pointer" }}
                            fontSize="small"
                        />
                    </Stack>
                    <Divider
                        variant="dashed"
                        sx={{
                            borderColor: "grey.400",
                            opacity: 0.3,
                            marginBottom: "8px",
                        }}
                    />
                    {policyDetailDto?.detailedInfo?.location?.map(
                        (location, index) => (
                            <DrawerContent
                                key={index}
                                address={location?.address}
                                pincode={location?.pincode}
                                stockSi={location?.stockSi}
                                otherSi={location?.otherSi}
                                buildingSi={location?.buildingSi}
                                fffSi={location?.fffSi}
                                pmSi={location?.pmSi}
                            />
                        )
                    )}
                </Box>
            </Drawer>
        </Stack>
    );
};

export default NewMembers;

function DrawerContent(location: {
    address?: string;
    pincode?: string;
    stockSi?: string;
    otherSi?: string;
    buildingSi?: string;
    fffSi?: string;
    pmSi?: string;
}) {
    const [expandRiskLocation, setExpandRiskLocation] = useState(false);
    const { policyDetailDto } = useSelector((state: RootState) => state.policy);
    return (
        <Stack
            gap={"16px"}
            direction={"column"}
            width={"100%"}
            onClick={() => {
                if (!policyDetailDto?.topInfo?.isFloater) {
                    setExpandRiskLocation(!expandRiskLocation);
                }
            }}
            sx={{
                padding: "16px",
                position: "relative",

                borderRadius: "20px",
                border: "1px solid #ECEFFF",
                cursor: "pointer",
                background: "#FFF",
                "@media (min-width: 768px)": {
                    "&:hover": {
                        backgroundColor: "#F4F7FF",
                    },
                },
            }}
        >
            <Stack
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                gap={"16px"}
                width={"100%"}
            >
                <Box
                    sx={{
                        display: "flex",
                        padding: "4px",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "16px",
                        width: "48px",
                        height: "48px",
                        cursor: "pointer",
                        transition: "border-color 0.3s ease",
                    }}
                >
                    <img
                        src={policyDetailDto?.detailedInfo?.policyTypeDp}
                        alt="property"
                        style={{
                            width: "40px",
                            height: "40px",
                            padding: "4px",
                            borderRadius: "13px",
                        }}
                    />
                </Box>
                <Stack flexDirection={"column"} gap={"4px"} flex={1}>
                    <Typography variant="subtitle2">
                        {location?.address}
                    </Typography>
                    <Typography variant="body3">{location?.pincode}</Typography>
                </Stack>
                {!policyDetailDto?.topInfo?.isFloater && (
                    <Iconify
                        icon={
                            expandRiskLocation
                                ? "material-symbols:keyboard-arrow-up"
                                : "material-symbols:keyboard-arrow-down"
                        }
                        width={"20px"}
                        height={"20px"}
                        onClick={() => {
                            setExpandRiskLocation(!expandRiskLocation);
                        }}
                    />
                )}
            </Stack>
            {expandRiskLocation && (
                <>
                    <Divider
                        variant="dashed"
                        sx={{
                            borderColor: "grey.400",
                            opacity: 0.3,
                            marginBottom: "8px",
                        }}
                    />
                    <Box
                        sx={{
                            display: "grid",
                            gap: "16px",
                            width: "100%",
                            gridTemplateColumns: "1fr 1fr",
                        }}
                    >
                        {location?.stockSi && (
                            <StyledBox>
                                <Typography variant="body3">
                                    Stock SI
                                </Typography>
                                <Typography variant="subtitle2">
                                    {location?.stockSi}
                                </Typography>
                            </StyledBox>
                        )}
                        {location?.otherSi && (
                            <StyledBox>
                                <Typography variant="body3">
                                    Additional SI
                                </Typography>
                                <Typography variant="subtitle2">
                                    {location?.otherSi}
                                </Typography>
                            </StyledBox>
                        )}
                        {location?.buildingSi && (
                            <StyledBox>
                                <Typography variant="body3">
                                    Building SI
                                </Typography>
                                <Typography variant="subtitle2">
                                    {location?.buildingSi}
                                </Typography>
                            </StyledBox>
                        )}
                        {location?.fffSi && (
                            <StyledBox>
                                <Typography variant="body3">FFF SI</Typography>
                                <Typography variant="subtitle2">
                                    {location?.fffSi}
                                </Typography>
                            </StyledBox>
                        )}
                        {location?.pmSi && (
                            <StyledBox>
                                <Typography variant="body3">
                                    Plant & Machinary SI
                                </Typography>
                                <Typography variant="subtitle2">
                                    {location?.pmSi}
                                </Typography>
                            </StyledBox>
                        )}
                    </Box>
                </>
            )}
        </Stack>
    );
}
