import { useState, useEffect } from "react";
import { useStateValue } from "../../util/stateProvider";
import { useParams } from "react-router-dom";

const useFlow = () => {
    const [{ user }] = useStateValue();
    const searchParams = new URLSearchParams(window.location.search);
    const clientType =
        searchParams.get("clientType") || sessionStorage.getItem("clientType");
    const { provider, uuid } = useParams();
    const [flowData, setFlowData] = useState({
        flow: "",
        uuid,
        clientType,
        provider,
    });

    useEffect(() => {
        let flow = "User";

        if (clientType) {
            sessionStorage.setItem("clientType", clientType);
        }

        if (
            ["admin", "agent", "broker"].includes(
                user?.actualUserType.toLowerCase()
            ) &&
            uuid &&
            uuid !== "guest"
        ) {
            flow = "Client";
        } else if (uuid === "guest") {
            flow = "Guest";
        }

        setFlowData({ flow, uuid, clientType, provider });
    }, [user, uuid, clientType, provider]);

    return flowData;
};

export default useFlow;
