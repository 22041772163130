import {
    Box,
    BoxProps,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import benifitDesktopbg from "../../assets/images/addons/Dekstop.svg";
import benifitMobilebg from "../../assets/images/addons/Mobile.svg";
import { useNavigate } from "react-router-dom";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import useFlow from "../../services/hooks/useFlow";

interface V2ContainerProps {
    children: React.ReactNode;
    heading?: string;
    sx?: BoxProps["sx"];
    onBack?: () => void;
}

const V2Container: React.FC<V2ContainerProps> = ({
    children,
    heading,
    onBack,
    sx,
}) => {
    const isMobile = useMediaQuery("(max-width: 768px)");
    const navigate = useNavigate();

    const theme = useTheme();
    const { flow } = useFlow();

    return (
        <Box
            sx={{
                paddingTop: { sm: "33px", xs: "" },
                background: `url(${
                    isMobile ? benifitMobilebg : benifitDesktopbg
                })`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPositionX: "center",

                margin: { xs: "0px -20px", sm: "0px 0px 0px 0px" },
                display: "flex",
                flexDirection: "column",
                gap: { sm: "36px", xs: "0px" },
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    width: { xs: "100%", sm: "770px" },
                    margin: "auto",
                    flexDirection: { xs: "column", sm: "row" },
                    padding: { xs: "24px 16px", sm: "0" },
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: { xs: "column", sm: "row" },
                        gap: "10px",
                    }}
                >
                    {flow === "User" && (
                        <KeyboardBackspaceIcon
                            sx={{
                                color: theme.palette.grey[800],
                                cursor: "pointer",
                                width: "32px",
                                height: "32px",
                            }}
                            onClick={() => {
                                if (onBack) {
                                    onBack();
                                } else {
                                    navigate(-1);
                                }
                            }}
                        />
                    )}
                    {heading && (
                        <Typography
                            variant="h4"
                            sx={{ color: theme.palette.text.dark }}
                        >
                            {heading}
                        </Typography>
                    )}
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    width: { xs: "100%", sm: "770px" },
                    padding: { sm: "40px 32px", xs: "32px 20px" },
                    justifyContent: "center",
                    alignItems: "flex-start",
                    alignContent: "flex-start",
                    gap: { sm: "48px", xs: "40px" },
                    flexWrap: "wrap",
                    borderRadius: { sm: "40px", xs: "24px 24px 0 0" },
                    border: "1px solid var(--Components-Outline, #ECEFFF)",
                    background: "#FFF",
                    minHeight: { xs: "100%", sm: "456px" },
                    boxShadow: {
                        sm: "0px 2px 12px 0px rgba(39, 57, 147, 0.08)",
                        xs: "",
                    },
                    margin: "auto",
                    ...sx,
                }}
            >
                {children}
            </Box>
        </Box>
    );
};

export default V2Container;
